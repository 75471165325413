import styled from 'styled-components';

import sound from 'assets/icons/sound.gif';
import soundFreeze from 'assets/icons/sound-freeze.png';

export const Container = styled.div`
    position: relative;
`;

export const PickerWrapper = styled.div<{ showPicker: boolean }>`
    position: absolute;
    display: ${({ showPicker }) => (showPicker ? 'initial' : 'none')};
    z-index: 9;
    width: 100%;
    top: -24rem;
    left: 0;
`;

export const TextArea = styled.textarea`
    width: 100%;
    background: #fbfbfb;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px 13px;
`;

export const TextAreaWrapper = styled.div`
    position: relative;
`;

export const Actions = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    width: 100%;
    gap: 4px;
`;

export const FileDecideWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 8rem;
    padding: 10px;
    border-radius: 8px;
    top: -8.5rem;
    left: 0;
    z-index: 9;
    gap: 10px;
    background: ${({ theme }) => theme.colors.high.pure};
    border: ${({ theme }) => `1px solid ${theme.colors.brand.pure}`};

    p {
        color: ${({ theme }) => theme.colors.low.pure};
        font-size: 14px;
        margin-right: 20px;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    button {
        display: flex;
        align-items: center;
        gap: 4px;
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.low.pure};
        font-size: 14px;
    }
`;

export const ButtonEmoji = styled.button`
    background: none;
    border: none;
    padding: 5px;
`;

export const ButtonAttachment = styled.label`
    cursor: pointer;
    input {
        display: none;
    }
`;

export const ButtonRecordAudio = styled.button`
    background: none;
    border: none;
    padding: 5px;
`;

export const RecordingWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 8rem;
    padding: 10px 20px;
    border-radius: 8px;
    top: -8.5rem;
    left: 0;
    z-index: 9;
    gap: 20px;
    background: ${({ theme }) => theme.colors.high.pure};
    border: ${({ theme }) => `1px solid ${theme.colors.brand.pure}`};

    span {
        color: ${({ theme }) => theme.colors.low.pure};
        font-size: 14px;
        margin-right: 20px;
        white-space: nowrap;
    }

    svg {
        cursor: pointer;
    }
`;

export const SoundIcon = styled.div<{ stopped: boolean }>`
    width: 100%;
    height: 40px;
    background-image: url(${({ stopped }) => (stopped ? soundFreeze : sound)});
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
`;
