/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
import UnloggedLayout from 'Components/Templates/UnloggedLayout';
import React, { useState } from 'react';
import { content } from './content';
import SectionTitle from './components/SectionTitle';
import Input from 'Components/Atoms/Input';
import ForgotPasswordLink from './components/ForgotPasswordLink';
import Button from 'Components/Atoms/Button';
import Divider from 'Components/Atoms/Divider';
import GoogleButton from 'Components/Atoms/GoogleButton';
import { useAuthStore } from 'store';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TextLink from 'Components/Atoms/TextLink';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Container } from './styles';
import MobileTitle from 'Components/Atoms/MobileTitle';
import { useGoogleLogin } from '@react-oauth/google';

export const Login: React.FC = () => {
    const { signInAction } = useAuthStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const schema = z.object({
        email: z.string().email('Email inválido!').min(1, 'Campo obrigatório'),
        password: z.string().min(7, 'Senha inválida')
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<{
        email: string;
        password: string;
    }>({
        resolver: zodResolver(schema)
    });

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const result = await signInAction(data);
        if (result) {
            navigate('/chat');
        }
        setLoading(false);
    });

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async ({ access_token }) => {
            setLoading(true);
            const result = await signInAction({ token: access_token });
            if (result) {
                navigate('/home');
            }
            setLoading(false);
        }
    });

    return (
        <UnloggedLayout backgroundImage={content.image}>
            <Container>
                <MobileTitle />

                <TextLink
                    text={content.register.text}
                    linkText={content.register.link.text}
                    linkUrl={content.register.link.url}
                />

                <SectionTitle
                    title={content.title}
                    subtitle={content.subtitle}
                />
                <form onSubmit={onSubmit} style={{ width: '100%' }}>
                    <Input
                        name="email"
                        id="email"
                        marginVertical="24px"
                        width="100%"
                        error={errors.email?.message}
                        label={content.fields.email.label}
                        placeholder={content.fields.email.placeholder}
                        register={register('email')}
                    />

                    <Input
                        name="password"
                        id="password"
                        width="100%"
                        error={errors.password?.message}
                        label={content.fields.password.label}
                        placeholder={content.fields.password.placeholder}
                        showPassword
                        register={register('password')}
                    />
                    <ForgotPasswordLink
                        text={content.forgotPassword.text}
                        url={content.forgotPassword.url}
                    />

                    <Button
                        type="submit"
                        marginVertical="34px"
                        size="SMALL"
                        rounded
                        loading={loading}
                        disabled={loading || !isValid}
                    >
                        {content.submit}
                    </Button>
                </form>

                <Divider text={content.social.title} />

                <GoogleButton
                    text={content.social.button}
                    onClick={loginWithGoogle}
                />
            </Container>
        </UnloggedLayout>
    );
};
