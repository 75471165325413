import { toast } from 'react-toastify';
import SettingsService from 'services/settings';
import {
    Group,
    MessageModel,
    MessageModelRequest,
    MessageModelResponse,
    PermissionsResponse,
    RolesResponse,
    TeamsDetailsResponse,
    TeamsResponse
} from 'services/settings/types';
import { create } from 'zustand';

export interface createSettingsSliceProps {
    teams: TeamsResponse[];
    roles: RolesResponse[];
    groups: Group[];
    permissions: PermissionsResponse[];
    messagesModels: MessageModel[];
    getGroups: () => void;
    updateGroup: (groupId: number, data: Group) => void;
    createGroup: (data: Group) => void;
    deleteGroup: (groupId: number) => void;
    getTeams: () => void;
    getRoles: () => void;
    getPermissions: () => void;
    getTeamsDetails: (
        teamMemberId: number
    ) => Promise<TeamsDetailsResponse | undefined>;
    getMessageModelDetails: (
        messageId: number
    ) => Promise<MessageModelResponse | undefined>;
    getMessageModels: (params?: {
        filter?: string;
        page?: number;
        rowsByPage?: number;
    }) => Promise<MessageModel[]>;
    deleteMessageModels: (messageId: number) => void;
    updateMessageModels: (
        id: number,
        data: MessageModelRequest
    ) => Promise<boolean>;
    postMessageModels: (data: MessageModelRequest) => Promise<boolean>;
}

export const useSettingsStore = create<createSettingsSliceProps>()(
    (set, get) => ({
        teams: [],
        roles: [],
        permissions: [],
        messagesModels: [],
        groups: [],
        getGroups: async () => {
            try {
                const result = await SettingsService.getGroups();
                set({ groups: result });
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        createGroup: async (data) => {
            try {
                await SettingsService.createGroup(data);
                get().getGroups();
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        updateGroup: async (groupId, data) => {
            try {
                await SettingsService.updateGroup(groupId, data);
                get().getGroups();
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        deleteGroup: async (groupId) => {
            try {
                await SettingsService.deleteGroup(groupId);
                get().getGroups();
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getTeams: async () => {
            try {
                const result = await SettingsService.getTeams();
                set({ teams: result });
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getTeamsDetails: async (teamMemberId) => {
            try {
                const result = await SettingsService.getTeamsDetails(
                    teamMemberId
                );
                return result;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getRoles: async () => {
            try {
                const result = await SettingsService.getRoles();
                set({ roles: result });
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getPermissions: async () => {
            try {
                const result = await SettingsService.getPermissions();
                set({ permissions: result });
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getMessageModelDetails: async (messageId) => {
            try {
                const result = await SettingsService.getMessageModelDetail(
                    messageId
                );

                return result;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
                return undefined;
            }
        },
        getMessageModels: async (params) => {
            try {
                const result = await SettingsService.getMessageModels(params);
                set({ messagesModels: result });
                return result || [];
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
                return [];
            }
        },

        deleteMessageModels: async (messageId) => {
            try {
                await SettingsService.deleteMessageModels(messageId);
                get().getMessageModels();
                return true;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
                return false;
            }
        },
        postMessageModels: async (data) => {
            try {
                await SettingsService.postMessageModels(data);
                get().getMessageModels();
                return true;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
                return false;
            }
        },
        updateMessageModels: async (id, data) => {
            try {
                await SettingsService.updateMessageModels(id, data);
                get().getMessageModels();
                return true;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
                return false;
            }
        }
    })
);
