import styled, { css, keyframes } from 'styled-components';
import { shade, transparentize } from 'polished';
import { Sizes } from './types';
import type { ContainerProps } from './types';

const rotateInfinite = keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const SolidButton = css<ContainerProps>`
    background: ${({ theme, color }) => color ?? theme.colors.brand.pure};
    color: ${({ theme }) => theme.colors.high.light};
    border: none;

    svg {
        color: ${({ theme }) => theme.colors.high.light};
    }

    &:hover {
        background: ${({ theme, color }) =>
            color ? shade(0.2, color) : shade(0.2, theme.colors.brand.pure)};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background: #f9f9f9;
            color: ${({ theme }) => theme.colors.low.light};
            cursor: not-allowed;

            &:hover {
                background: ${shade(0.1, '#f9f9f9')};
            }
        `}
`;

const OutlineButton = css<ContainerProps>`
    border: ${({ theme, color }) =>
        color ? `2px solid ${color}` : `2px solid ${theme.colors.brand.pure}`};
    color: ${({ theme, color }) => color ?? theme.colors.brand.pure};
    background: transparent;

    svg {
        color: ${({ theme, color }) => color ?? theme.colors.brand.pure};
    }

    &:hover {
        background: ${({ theme, color }) =>
            color
                ? transparentize(0.9, color)
                : transparentize(0.9, theme.colors.brand.pure)};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background: #f9f9f9;
            color: ${({ theme }) => theme.colors.low.light};
            border: ${({ theme }) => `1px solid ${theme.colors.low.light}`};
            cursor: not-allowed;

            &:hover {
                background: ${shade(0.1, '#f9f9f9')};
            }
            svg {
                color: ${({ theme }) => theme.colors.low.light};
            }
        `}
`;

export const WrapperLoadgin = styled.div`
    svg {
        animation: 1s ${rotateInfinite} ease-out infinite;
    }
`;

export const Container = styled.button<ContainerProps>`
    ${({ outline }) => (outline ? OutlineButton : SolidButton)};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: ${({ height, theme }) => height ?? theme.spacing.md};
    width: ${({ width }) => width ?? '100%'};
    border-radius: ${({ rounded }) => (rounded ? '0.4rem' : '0.1rem')};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme, size }) => {
        switch (Sizes[size ?? 'MEDIUM']) {
            case Sizes.SMALL:
                return theme.fontSize.xs;
            case Sizes.MEDIUM:
                return theme.fontSize.md;
            case Sizes.LARGER:
                return theme.fontSize.lg;
            default:
                return theme.fontSize.md;
        }
    }};
    outline: none;
    margin-top: ${({ marginVertical }) => marginVertical ?? 'none'};
    margin-bottom: ${({ marginVertical }) => marginVertical ?? 'none'};
    margin-left: ${({ marginHorizontal }) => marginHorizontal ?? 'none'};
    margin-right: ${({ marginHorizontal }) => marginHorizontal ?? 'none'};
    transition: all 0.2s;

    &:active {
        transform: scale(0.85);
    }
`;
