import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 1.6rem;
`;

export const SmallContainers = styled.div`
    background: #fefefe;
    border-radius: 8px;
    width: 100%;
    padding: 3.5rem 2rem;
`;

export const ChannelsSection = styled.div`
    margin-bottom: 2rem;
`;

export const GroupsSection = styled.div`
    margin-bottom: 2rem;

    & > ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 1.5rem;
    }

    li {
        font-weight: 400;
        font-size: 14px;
        line-height: 133%;
        color: #cc6e00;
        width: fit-content;
        background: #ffe3c2;
        padding: 8px;
        border: 1px solid #ffe3c2;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        &:hover {
            &::before {
                content: 'X';
                position: absolute;
                top: -10px;
                left: 0;
                color: red;
                width: 100%;
                height: 100%;
            }
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #ffffff;
        padding: 8px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;

        svg {
            margin-right: 12px;
        }
    }
`;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
    margin-bottom: 1.2rem;

    & > svg {
        margin-right: 15px;
    }
`;
