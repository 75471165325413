import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    & > div {
        position: relativex;
    }

    img {
        border-radius: 8px;
    }
`;

export const Download = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 0 8px 0 8px;

    &:hover {
        svg {
            color: #0b57f5;
        }
    }

    svg {
        color: #ffffff;
        font-size: 3.5rem;
        padding: 4px 6px;
    }
`;
