export const content = {
    contactList: {
        title: 'Você',
        search: {
            placeholder: 'Busque nas mensagens'
        },
        tabs: ['Abertas', 'Fechadas']
    },
    contactDetails: {
        empty: 'Selecione um contato no chat',
        title: 'Informações do contato',
        products: {
            title: 'Produtos sugeridos',
            priceLegend: 'Valor total:'
        },
        channels: {
            title: 'Canais'
        },
        groups: {
            title: 'Grupos',
            action: {
                label: 'Adicionar Grupo'
            }
        },
        details: {
            title: 'Detalhes do contato',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            email: 'Email',
            phone: 'Telefone',
            owner: 'Dono do contato'
        }
    }
};
