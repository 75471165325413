import styled from 'styled-components';

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 14px;
    gap: 10px;
    border: 1px solid rgba(163, 163, 163, 0.27);
    max-width: 80%;
    min-width: 200px;
`;

export const MessageText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

export const Time = styled.p`
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
`;
export const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dotted #66666660;
    border-top: 1px dotted #66666660;
    background-color: #f5f5f5;
    padding: 10px;
`;
export const InfoText = styled.h5`
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #666666;
`;

export const Container = styled.div<{ owner?: boolean }>`
    display: flex;
    align-items: flex-start;
    justify-content: 'flex-start';
    flex-direction: ${({ owner }) => (owner ? 'row-reverse' : 'row')};
    width: 100%;
    gap: 8px;

    ${Body} {
        background: ${({ owner }) => (owner ? '#457EF5' : '#f5f5f5')};
        border-radius: ${({ owner }) =>
            owner ? '16px 0px 16px 16px' : '0px 16px 16px 16px'};
    }

    ${MessageText} {
        color: ${({ owner }) => (owner ? '#ffffff' : '#000000')};
    }

    ${Time} {
        color: ${({ owner }) => (owner ? '#E0E0E0' : '#666666')};
    }
`;
