import React, { useCallback, useEffect } from 'react';

import {
    Actions,
    ButtonAdd,
    ColumnBody,
    ColumnCounter,
    ColumnHeader,
    ColumnsWrapper,
    Container,
    Header,
    SeeColumnsButton,
    TeamGroup
} from './styles';
import { BsPlusSquare } from 'react-icons/bs';
import Card from './Card';
import { MdOutlineChecklistRtl } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import Input from 'Components/Atoms/Input';
import Select from 'Components/Atoms/Select';
import { useForm } from 'react-hook-form';
import { useContactStore } from 'store/useContacts';
import { content } from '../content';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'Components/Atoms/Button';

type ContactBoardProps = {
    switchViewMode: () => void;
};

const ContactBoard: React.FC<ContactBoardProps> = ({ switchViewMode }) => {
    const { control, register, getValues, watch, setValue } = useForm<{
        filter: string;
        channel: string;
        value: string;
    }>();

    const { contentBoard } = content;
    const location = useLocation();
    const { colContacts, teamMembers, getColContacts, getTeamMembers } =
        useContactStore();
    const navigate = useNavigate();
    const channelField = watch('channel');
    const valueField = watch('value');
    const filterField = watch('filter');

    const handleOpenContact = useCallback(
        (id: number) => {
            navigate(`/contacts/${id}`);
        },
        [navigate]
    );

    const handleSearchContacts = useCallback(() => {
        const params = {
            channel: channelField,
            filter: filterField,
            value: valueField
        };
        getColContacts(params);
    }, [channelField, filterField, getColContacts, valueField]);

    useEffect(() => {
        handleSearchContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelField, valueField]);

    useEffect(() => {
        getColContacts();
        getTeamMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Header>
                <h1>{contentBoard.header.title}</h1>

                <SeeColumnsButton onClick={() => switchViewMode()}>
                    {contentBoard.header.buttonRows} <MdOutlineChecklistRtl />
                </SeeColumnsButton>
            </Header>
            <Actions>
                <div>
                    <div id="search">
                        <Input
                            name="filter"
                            leftIcon={FiSearch}
                            placeholder={contentBoard.actions.searchPlaceholder}
                            label={contentBoard.actions.searchLabel}
                            register={register('filter')}
                        />
                        <Button
                            width="110px"
                            height="48px"
                            size={'SMALL'}
                            rounded
                            marginHorizontal="8px"
                            type="button"
                            onClick={() => handleSearchContacts()}
                        >
                            {contentBoard.actions.searchButton}
                        </Button>
                    </div>

                    <Select
                        name="channel"
                        value={getValues('channel')}
                        label={contentBoard.actions.channelLabel}
                        control={control}
                        onChange={(e) =>
                            setValue('channel', e.currentTarget.value)
                        }
                        options={[
                            { value: '', label: 'Todos' },
                            { value: 'whatspp', label: 'Whatsapp' },
                            { value: 'sms', label: 'SMS' },
                            { value: 'instagram', label: 'Instagram' },
                            { value: 'telegram', label: 'Telegram' },
                            { value: 'mensseger', label: 'Mensseger' }
                        ]}
                    />
                    <Select
                        name="value"
                        value={getValues('value')}
                        label={contentBoard.actions.valueLabel}
                        control={control}
                        onChange={(e) =>
                            setValue('value', e.currentTarget.value)
                        }
                        options={[
                            { value: '1-100', label: '1-100' },
                            { value: '100-500', label: '100-500' },
                            { value: '500-1000', label: '500-1000' },
                            { value: '1001', label: '1000+' }
                        ]}
                    />
                </div>

                <TeamGroup>
                    {teamMembers?.users?.map((item) => (
                        <img src={item.avatar} key={item.id} />
                    ))}
                </TeamGroup>
            </Actions>
            <ColumnsWrapper>
                <div>
                    <ColumnHeader>
                        <span>
                            {contentBoard?.columns?.opened}
                            <ColumnCounter color="#a3a3a3">
                                {colContacts?.rows?.opened?.length}
                            </ColumnCounter>
                        </span>
                        <ButtonAdd
                            onClick={() =>
                                navigate(`/contacts/form`, {
                                    state: { background: location }
                                })
                            }
                        >
                            <BsPlusSquare size={18} />
                        </ButtonAdd>
                    </ColumnHeader>
                    <ColumnBody>
                        {colContacts?.rows?.opened?.map((item) => (
                            <Card
                                {...item}
                                name={`${item.firstName} ${item.lastName}`}
                                key={item.id}
                                onClick={() => handleOpenContact(item.id)}
                            />
                        ))}
                    </ColumnBody>
                </div>
                <div>
                    <ColumnHeader>
                        {contentBoard?.columns?.in_attendance}
                        <ColumnCounter color="#0B57F5">
                            {colContacts?.rows?.inAttendance?.length}
                        </ColumnCounter>
                    </ColumnHeader>
                    <ColumnBody>
                        {colContacts?.rows?.inAttendance?.map((item) => (
                            <Card
                                {...item}
                                name={`${item.firstName} ${item.lastName}`}
                                key={item.id}
                                onClick={() => handleOpenContact(item.id)}
                            />
                        ))}
                    </ColumnBody>
                </div>
                <div>
                    <ColumnHeader>
                        {contentBoard?.columns?.under_review}
                        <ColumnCounter color="#973FCF">
                            {colContacts?.rows?.underAnalysis?.length}
                        </ColumnCounter>
                    </ColumnHeader>
                    <ColumnBody>
                        {colContacts?.rows?.underAnalysis?.map((item) => (
                            <Card
                                {...item}
                                name={`${item.firstName} ${item.lastName}`}
                                key={item.id}
                                onClick={() => handleOpenContact(item.id)}
                            />
                        ))}
                    </ColumnBody>
                </div>
                <div>
                    <ColumnHeader>
                        {contentBoard?.columns?.blocked}
                        <ColumnCounter color="#F05656">
                            {colContacts?.rows?.blocked?.length}
                        </ColumnCounter>
                    </ColumnHeader>
                    <ColumnBody>
                        {colContacts?.rows?.blocked?.map((item) => (
                            <Card
                                {...item}
                                name={`${item.firstName} ${item.lastName}`}
                                key={item.id}
                                onClick={() => handleOpenContact(item.id)}
                            />
                        ))}
                    </ColumnBody>
                </div>
                <div>
                    <ColumnHeader>
                        {contentBoard?.columns?.done}
                        <ColumnCounter color="#78CE7E">
                            {colContacts?.rows?.done?.length}
                        </ColumnCounter>
                    </ColumnHeader>
                    <ColumnBody>
                        {colContacts?.rows?.done?.map((item) => (
                            <Card
                                {...item}
                                name={`${item.firstName} ${item.lastName}`}
                                key={item.id}
                                onClick={() => handleOpenContact(item.id)}
                            />
                        ))}
                    </ColumnBody>
                </div>
            </ColumnsWrapper>
        </Container>
    );
};

export default ContactBoard;
