import styled from 'styled-components';

export const Container = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 2rem;
    margin: 20px 0;
    opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`;
