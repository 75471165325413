import React, { createContext, useCallback, useContext, useRef } from 'react';
import {
    Edge,
    Node,
    OnEdgesChange,
    OnNodesChange,
    addEdge,
    updateEdge,
    useEdgesState,
    useNodesState
} from 'reactflow';

type CustomNode = Node<object, string | undefined> & { parentId?: string };
type AutomationContextType = {
    nodes: CustomNode[];
    edges: Edge<any>[];
    setNodes: React.Dispatch<React.SetStateAction<CustomNode[]>>;
    setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
    onNodesChange: OnNodesChange;
    onEdgesChange: OnEdgesChange;
    onEdgeUpdateStart: () => void;
    onEdgeUpdate: (oldEdge: any, newConnection: any) => void;
    onEdgeUpdateEnd: (_: any, edge: any) => void;
    onConnect: (connection: any) => void;
    handleAddNodes: (type?: string) => void;
    handleAddEdge: (source: string, target: string) => void;
    handleAddSubNodes: (props: {
        id: string;
        parentId: string;
        type: string;
        data?: object;
        position?: { x: number; y: number };
    }) => void;
};

export const AutomationContext = createContext({} as AutomationContextType);

export function AutomationProvider({ children }: any) {
    const edgeUpdateSuccessful = useRef(true);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);

    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const onConnect = useCallback((connection: any) => {
        setEdges((eds) => addEdge(connection, eds));
        setNodes((nodes) =>
            nodes.map((item) => {
                if (item.id === connection.source) {
                    item.data.target = connection.target;
                    item.data.source = connection.source;
                }

                if (item.id === connection.target) {
                    item.data.source = connection.source;
                }
                return item;
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddEdge = useCallback(
        (source: string, target: string) => {
            setEdges((eds) =>
                addEdge({ id: `e${source}-${target}`, source, target }, eds)
            );
        },
        [setEdges]
    );

    const handleAddNodes = useCallback(
        (type?: string) => {
            //@ts-ignore
            const mainNodes = nodes.filter((item) => !item.parentId);
            const lastNode = mainNodes?.length ? mainNodes.at(-1) : null;
            const nextId = lastNode ? Number(lastNode?.id) + 1 : 1;

            const newNodes = [
                ...nodes,
                {
                    id: nextId.toString(),
                    type: type || 'defaultNode',
                    position: {
                        x: lastNode ? lastNode.position.x : 200,
                        y: lastNode ? lastNode?.position?.y + 200 : 200
                    },
                    data: {}
                }
            ];
            setNodes(newNodes);
        },
        [nodes, setNodes]
    );

    const handleAddSubNodes = useCallback(
        (props: {
            id: string;
            parentId: string;
            type: string;
            data?: object;
            position?: { x: number; y: number };
        }) => {
            const newNodes = [
                ...nodes,
                {
                    id: props.id,
                    type: props?.type,
                    position: props?.position || { x: 200, y: 200 },
                    parentId: props?.parentId,
                    data: props.data || {}
                }
            ];
            setNodes(newNodes);
        },
        [nodes, setNodes]
    );

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback(
        (oldEdge: any, newConnection: any) => {
            edgeUpdateSuccessful.current = true;
            setEdges((els) => updateEdge(oldEdge, newConnection, els));
        },
        [setEdges]
    );

    const onEdgeUpdateEnd = useCallback(
        (_: any, edge: any) => {
            if (!edgeUpdateSuccessful.current) {
                setEdges((eds) => eds.filter((e) => e.id !== edge.id));
            }

            edgeUpdateSuccessful.current = true;
        },
        [setEdges]
    );

    return (
        <AutomationContext.Provider
            value={{
                edges,
                nodes,
                onConnect,
                setEdges,
                setNodes,
                onEdgesChange,
                onNodesChange,
                onEdgeUpdate,
                onEdgeUpdateStart,
                onEdgeUpdateEnd,
                handleAddEdge,
                handleAddNodes,
                handleAddSubNodes
            }}
        >
            {children}
        </AutomationContext.Provider>
    );
}

export function useAutomation() {
    return useContext(AutomationContext);
}
