import { toast } from 'react-toastify';
import DashboardService from 'services/dashboard';
import {
    DashboardAttendances,
    DashboardAttendantsNps,
    DashboardAttendantsPerformance,
    DashboardCallPerChannel,
    DashboardCallsPerDay,
    DashboardConsumedWindows,
    DashboardGeneralNps,
    DashboardLeadTime,
    DashboardRequestParams,
    TeamMember
} from 'services/dashboard/types';
import { create } from 'zustand';

export interface createDashboardSliceProps {
    getAttendances: (
        params: DashboardRequestParams
    ) => Promise<DashboardAttendances | null>;
    getLeadTime: (
        params: DashboardRequestParams
    ) => Promise<DashboardLeadTime | null>;
    getConsumedWindows: (
        params: DashboardRequestParams
    ) => Promise<DashboardConsumedWindows | null>;
    getGeneralNps: (
        params: DashboardRequestParams
    ) => Promise<DashboardGeneralNps | null>;
    getCallsPerChannel: (
        params: DashboardRequestParams
    ) => Promise<DashboardCallPerChannel | null>;
    getAttendantsNps: (
        params: DashboardRequestParams
    ) => Promise<DashboardAttendantsNps | null>;
    getAttendantPerformance: (
        params: DashboardRequestParams
    ) => Promise<DashboardAttendantsPerformance[] | []>;
    getCallsPerDay: (
        params: DashboardRequestParams
    ) => Promise<DashboardCallsPerDay[] | []>;
    getCallsPerDayMember: (
        params: DashboardRequestParams & { memberId: number }
    ) => Promise<DashboardCallsPerDay[] | []>;
    getTeamMember: (params: {
        period: string;
        memberId: number;
    }) => Promise<TeamMember | null>;
}

export const useDashboard = create<createDashboardSliceProps>()(() => ({
    getAttendances: async (params) => {
        try {
            const result = await DashboardService.getAttendances(params);
            return result as DashboardAttendances;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    },
    getLeadTime: async (params) => {
        try {
            const result = await DashboardService.getLeadTime(params);
            return result as DashboardLeadTime;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    },
    getConsumedWindows: async (params) => {
        try {
            const result = await DashboardService.getConsumedWindows(params);
            return result as DashboardConsumedWindows;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    },
    getCallsPerChannel: async (params) => {
        try {
            const result = await DashboardService.getCallsPerChannel(params);
            return result as DashboardCallPerChannel;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    },
    getGeneralNps: async (params) => {
        try {
            const result = await DashboardService.getGeneralNps(params);
            return result as DashboardGeneralNps;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    },
    getAttendantsNps: async (params) => {
        try {
            const result = await DashboardService.getAttendantsNps(params);
            return result as DashboardAttendantsNps;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    },
    getAttendantPerformance: async (params) => {
        try {
            const result = await DashboardService.getAttendantPerformance(
                params
            );
            return result as DashboardAttendantsPerformance[];
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return [];
        }
    },
    getCallsPerDay: async (params) => {
        try {
            const result = await DashboardService.getCallsPerDay(params);
            return result as DashboardCallsPerDay[];
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return [];
        }
    },
    getCallsPerDayMember: async (params) => {
        try {
            const result = await DashboardService.getCallsPerDayMember(params);
            return result as DashboardCallsPerDay[];
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return [];
        }
    },
    getTeamMember: async (params: { period: string; memberId: number }) => {
        try {
            const result = await DashboardService.getTeamMember(params);
            return result as TeamMember;
        } catch (error: any) {
            toast.error(error?.message || error, { toastId: 'dashboard' });
            return null;
        }
    }
}));
