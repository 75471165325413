import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: inherit;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 100%;
    margin-bottom: 2rem;
    color: #000000;
`;

export const ListValues = styled.ul<{ column?: boolean; alignment?: string }>`
    display: flex;
    align-items: ${({ alignment }) => alignment || 'center'};
    justify-content: flex-start;
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    gap: 2rem;
    width: 100%;
    cursor: pointer;
`;

export const Radio = styled.li`
    display: flex;
    align-items: center;
    padding-right: 1rem;
    cursor: pointer;

    & > label {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 100%;
        color: #292929;
        margin-left: 1rem;
        cursor: pointer;
    }

    @media (max-width: 1025px) {
        & > label {
            font-size: 1.3rem;
        }
    }
`;
