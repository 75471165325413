import React, { useCallback, useEffect, useState } from 'react';

import { Container, Content, CustomTabs, Title } from './styles';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LoggedLayout from 'Components/Templates/LoggedLayout';
import { HiOutlineChat } from 'react-icons/hi';
import { FaRegUser } from 'react-icons/fa';
import { ReactComponent as TextIcon } from 'assets/icons/text.svg';
import Profile from './Profile';
import Users from './Users';
import Channels from './Channels';
import Message from './Message/Table';
import { useSearchParams } from 'react-router-dom';

enum tabs {
    profile = 0,
    users = 1,
    // groups = 2,
    channels = 2,
    messages = 3
}
const Settings: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTab, serCurrentTab] = useState<number>(0);

    const handleTabRedirect = useCallback(() => {
        try {
            const currentTab = searchParams.get('tab')
                ? tabs[searchParams.get('tab') as keyof typeof tabs]
                : 0;

            serCurrentTab(currentTab);
        } catch {
            serCurrentTab(0);
        }
    }, [searchParams]);

    useEffect(() => {
        handleTabRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return (
        <LoggedLayout padding="23px 0">
            <Container>
                <CustomTabs>
                    <Tabs
                        defaultIndex={currentTab}
                        selectedIndex={currentTab}
                        onSelect={(index) => {
                            serCurrentTab(index);
                            setSearchParams({ tab: tabs[index] });
                        }}
                    >
                        <TabList>
                            <Title>Configurações</Title>
                            <Tab>
                                <FaRegUser />
                                Meus dados
                            </Tab>
                            <Tab>
                                <FaRegUser />
                                Usuários
                            </Tab>
                            {/* <Tab>
                                <HiOutlineUserGroup />
                                Groups
                            </Tab> */}
                            <Tab>
                                <HiOutlineChat />
                                Canais de comunicação
                            </Tab>
                            {/* <Tab>
                                <TextIcon />
                                Modelos de mensagem
                            </Tab> */}
                        </TabList>
                        <Content>
                            <TabPanel>
                                <Profile />
                            </TabPanel>
                            <TabPanel>
                                <Users />
                            </TabPanel>
                            {/* <TabPanel>
                                <Groups />
                            </TabPanel> */}
                            <TabPanel>
                                <Channels />
                            </TabPanel>
                            {/* <TabPanel>
                                <Message />
                            </TabPanel> */}
                        </Content>
                    </Tabs>
                </CustomTabs>
            </Container>
        </LoggedLayout>
    );
};

export default Settings;
