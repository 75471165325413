import React, { useCallback, useEffect, useState } from 'react';

import { Container, Card, ChannelsGroup } from './styles';
import { useDashboard } from 'store';
import { DashboardCallPerChannel } from 'services/dashboard/types';
import { icons } from 'Constant';

type CallsPerChannelProps = {
    period: string;
};

const CallsPerChannel: React.FC<CallsPerChannelProps> = ({ period }) => {
    const { getCallsPerChannel } = useDashboard();
    const [data, setData] = useState<DashboardCallPerChannel>({
        whatsapp: 0,
        facebook: 0,
        messenger: 0,
        instagram: 0,
        sms: 0
    });

    const handleAttendanceData = useCallback(async () => {
        const result = await getCallsPerChannel({ period });

        setData({
            facebook: result?.facebook || 0,
            whatsapp: result?.whatsapp || 0,
            messenger: result?.messenger || 0,
            instagram: result?.instagram || 0,
            sms: result?.sms || 0
        });
    }, [getCallsPerChannel, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <h3>Atendimentos por canal</h3>
            <ChannelsGroup>
                <Card>
                    {icons['whatsapp']}
                    <p>{data.whatsapp}</p>
                </Card>
                <Card>
                    {icons['facebook']}
                    <p>{data.facebook}</p>
                </Card>
                <Card>
                    {icons['messenger']}
                    <p>{data.messenger}</p>
                </Card>
            </ChannelsGroup>
        </Container>
    );
};

export default CallsPerChannel;
