import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface InputWrapperProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
    backgroundWhite?: boolean;
    width?: string | number;
}

interface ContainerProps {
    marginVertical?: string;
    marginHorizontal?: string;
    width?: string | number;
}

const InputContainer = css<InputWrapperProps>`
    display: flex;
    align-items: center;
    padding: 20px 24px;
    border-radius: 1rem;
    border: ${({ theme }) => `1px solid ${theme.colors.high.dark}`};
    background: #f9f9f9;
    color: ${({ theme }) => theme.colors.low.dark};
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin: 0.5rem 0;
    width: ${({ width }) => width ?? '100%'};
    max-height: ${({ theme }) => theme.spacing.lg};

    svg {
        margin: 0 0.5rem;
    }

    &:focus {
        border: ${({ theme }) => `1px solid ${theme.colors.low.dark}`};
    }

    input {
        width: ${({ width }) => width ?? '100%'};
        padding: 0 0 0 1rem;
        border: 0;
        background: transparent;
        color: ${({ theme }) => theme.colors.low.dark};
        &::placeholder,
        &::-webkit-input-placeholder,
        &:-ms-input-placeholder {
            color: ${({ theme }) => theme.colors.low.light};
            font-size: ${({ theme }) => theme.fontSize.xs};
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: 0;
            -webkit-text-fill-color: ${({ theme }) => theme.colors.low.light};
            -webkit-box-shadow: 0;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${({ width }) => width ?? '100%'};
    margin-top: ${({ marginVertical }) => marginVertical ?? 'none'};
    margin-bottom: ${({ marginVertical }) => marginVertical ?? 'none'};
    margin-left: ${({ marginHorizontal }) => marginHorizontal ?? 'none'};
    margin-right: ${({ marginHorizontal }) => marginHorizontal ?? 'none'};

    .phone-input-container {
        display: flex;
        align-items: center;
        padding: 20px 14px;
        border-radius: 1rem;
        border: 1px solid #cccccc;
        background: #f9f9f9;
        color: ${({ theme }) => theme.colors.low.dark};
        font-size: ${({ theme }) => theme.fontSize.xs};
        margin: 0.5rem 0;
        width: ${({ width }) => width ?? '100%'};
        max-height: ${({ theme }) => theme.spacing.lg};

        input {
            width: ${({ width }) => width ?? '100%'};
            /* padding: 0 0 0 1rem; */
            border: 0;
            background: transparent;
            color: ${({ theme }) => theme.colors.low.dark};
            &::placeholder,
            &::-webkit-input-placeholder,
            &:-ms-input-placeholder {
                color: ${({ theme }) => theme.colors.low.dark};
                font-size: ${({ theme }) => theme.fontSize.xs};
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: 0;
                -webkit-text-fill-color: ${({ theme }) =>
                    theme.colors.low.light};
                -webkit-box-shadow: 0;
                transition: background-color 5000s ease-in-out 0s;
            }
        }
    }

    .phone-input-button,
    .react-tel-input .flag-dropdown.open .selected-flag {
        left: 0;
        width: 50px;
        border-radius: 1rem 0 0 1rem;
        border: none;
        border-right: 1px solid #cccccc;
        background: #f9f9f9;
    }

    .phone-input-error {
        border: ${({ theme }) => `1px solid  ${theme.colors.error.pure}`};
    }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
    ${InputContainer}

    ${({ backgroundWhite, theme }) =>
        Boolean(backgroundWhite) &&
        css`
            background: ${theme.colors.high.pure};
        `}

  ${({ isErrored }) =>
        isErrored &&
        css`
            border: ${({ theme }) => `1px solid  ${theme.colors.error.pure}`};
        `}
  ${({ isFocused }) =>
        isFocused &&
        css`
            color: ${({ theme }) => theme.colors.low.dark};
            border: ${({ theme }) => `1px solid  ${theme.colors.high.light}`};
        `}
  ${({ isFilled }) =>
        isFilled &&
        css`
            color: ${({ theme }) => theme.colors.low.dark};
        `}
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.low.dark};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Error = styled(Tooltip)`
    margin-left: 1.6rem;
    svg {
        margin: 0;
    }
    span {
        background: ${({ theme }) => theme.colors.high.light};
        color: ${({ theme }) => theme.colors.low.dark};
        &::before {
            box-shadow: none;
        }
    }
`;
