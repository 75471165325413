import React from 'react';

import { Container, Card, Title, CardGroup } from './styles';

import { TeamsDetailsResponse } from 'services/settings/types';

type TalksProps = {
    currentMember: TeamsDetailsResponse | undefined;
};

const Talks: React.FC<TalksProps> = ({ currentMember }) => {
    return (
        <Container>
            <Title>
                Conversas <small>(Minutos)</small>
            </Title>
            <CardGroup>
                <Card color={'#E3F5FF'}>
                    <p>Em espera</p>
                    <span>{currentMember?.totals.conversations.onHold}</span>
                </Card>
                <Card color={'#E5ECF6'}>
                    <p>Tempo total</p>
                    <span>{currentMember?.totals.conversations.totalTime}</span>
                </Card>
            </CardGroup>
        </Container>
    );
};

export default Talks;
