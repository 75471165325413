import React from 'react';

import {
    Card,
    CardBody,
    CardButton,
    CardHeader,
    CardMessage,
    Container,
    Title
} from './styles';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMenu } from 'react-icons/ai';
import { useWatch } from 'react-hook-form';

type MessagePreviewProps = {
    control: any;
};

const MessagePreview: React.FC<MessagePreviewProps> = ({ control }) => {
    const messageFooter = '12:04 WhatsApp Maringá-PR';

    const messageBody = useWatch({
        control,
        name: 'text'
    });

    const buttonLabel = useWatch({
        control,
        name: 'messageBody.label'
    });

    const listName = useWatch({
        control,
        name: 'messageBody.title'
    });

    const actionType = useWatch({
        control,
        name: 'type'
    });

    return (
        <Container>
            <Title>Mensagem</Title>

            <Card>
                <CardHeader>
                    <FaWhatsapp size={20} color="#ffffff" /> Modelo de mensagem
                </CardHeader>
                <CardBody>
                    <CardMessage>
                        <p>{messageBody}</p>
                        <span>{messageFooter}</span>
                    </CardMessage>
                    {(buttonLabel || listName) && (
                        <CardButton>
                            {actionType === 'list' && (
                                <AiOutlineMenu size={16} />
                            )}
                            {buttonLabel || listName}
                        </CardButton>
                    )}
                </CardBody>
            </Card>
        </Container>
    );
};

export default MessagePreview;
