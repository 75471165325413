import Loading from 'Components/Atoms/Loading';
import MessageList from 'Components/Organisms/Chat/MessageList';
import React, { useCallback, useEffect, useState } from 'react';
import { useContactStore } from 'store/useContacts';
import MessageHeader from '../MessageHeader';
import { Container, EmptyChat, FooterFixed, HeaderFixed } from './styles';
import InputChat from 'Components/Organisms/Chat/InputChat';
import { io } from 'socket.io-client';
import TokenService from 'services/token';
import { ChatContactMessages } from 'services/contacts/types';
import { useWebSocketStore } from 'store';

// const socket = io(
//     `${process.env.REACT_APP_SOCKET_URL}?userId=${
//         TokenService.getAuthData()?.user.id
//     }`
// );
// socket.connect();

const ContactMessages: React.FC = () => {
    // const [messages, setMessages] = useState<ChatContactMessages[]>([]);
    const webSocket = useWebSocketStore();
    const {
        // currentContactMessages,
        currentContactDetails,
        loadingMessages,
        sendMessage
    } = useContactStore();

    const handleSocketConnection = useCallback(async () => {
        webSocket.getChatMessages(currentContactDetails);
        return null;
    }, [currentContactDetails]);

    const handleSendMessageWithAttachment = (params: {
        text: string;
        attachment?: File | Blob;
    }) => {
        const channelId = currentContactDetails?.contactChannels.find(
            (item) =>
                item.type === currentContactDetails.channelCommunicationSelected
        )?.id;

        if (channelId) {
            const formData = new FormData();
            formData.append('text', params.text);

            if (params.attachment) {
                formData.append('attachments', params.attachment);
            }

            sendMessage({
                contactChannelId: channelId,
                data: formData
            });
        }
    };

    useEffect(() => {
        if (currentContactDetails?.id) {
            handleSocketConnection();

            return () => {
                // socket.disconnect();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContactDetails]);

    if (loadingMessages) {
        return <Loading />;
    }

    return (
        <Container>
            {currentContactDetails?.id && (
                <HeaderFixed>
                    <MessageHeader />
                </HeaderFixed>
            )}
            {!webSocket.chatMessages.length ? (
                <EmptyChat>
                    {currentContactDetails?.id
                        ? 'Sem mensagens'
                        : 'Selecione um contato'}
                </EmptyChat>
            ) : (
                <MessageList
                    content={webSocket?.chatMessages as ChatContactMessages[]}
                />
            )}
            {currentContactDetails?.id &&
                currentContactDetails?.chatTicket?.status !== 'conclused' && (
                    <FooterFixed>
                        <InputChat
                            sendAudio={handleSendMessageWithAttachment}
                            sendFile={handleSendMessageWithAttachment}
                        />
                    </FooterFixed>
                )}
        </Container>
    );
};

export default ContactMessages;
