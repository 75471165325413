import React from 'react';

import { Container } from './styles';
import { socialType, icons } from 'Constant';

interface SocialIconProps {
    socialMedia: socialType;
    width?: string;
    height?: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({
    socialMedia,
    width = '2rem',
    height = '2rem'
}) => {
    return (
        <Container width={width} height={height}>
            {icons[socialMedia]}
        </Container>
    );
};

export default SocialIcon;
