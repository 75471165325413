import styled from 'styled-components';

export const Container = styled.div`
    overflow: hidden;
    background: #f9f9f9;

    * {
        scrollbar-width: thin;
        scrollbar-color: #c3c3c3 #ffffff;
    }

    *::-webkit-scrollbar {
        width: 12px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #c3c3c3;
        border-radius: 10px;
        border: 3px solid #ffffff;
    }
`;

export const ColumnsWrapper = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    gap: 14px;
    grid-template-columns: repeat(5, 1fr);
    overflow: hidden;

    & > div {
        height: calc(100vh - 170px);
    }

    @media (max-width: 1024px) {
        overflow: auto;

        & > div {
            width: 300px;
        }
    }
`;

export const ColumnHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.2rem 1.6rem;
    background: #ffffff;
    border-radius: 4px;
    max-height: 60px;
    max-width: 300px;

    font-weight: 600;
    font-size: 1.4rem;
    line-height: 100%;
    color: #292929;

    & > span {
        display: flex;
        align-items: center;
        gap: 2rem;
    }
`;

export const ColumnBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 10px;
    height: 100%;
    padding: 2.2rem 1.6rem;
    max-width: 300px;
    overflow: auto;

    gap: 12px;
    padding-bottom: 8rem;
`;

export const ColumnCounter = styled.span<{ color: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    text-align: center;
    padding: 0.3rem 0.7rem;

    color: #ffffff;
    background-color: ${({ color }) => color || '#a3a3a3'};
    border-radius: 100px;
    font-size: 1rem;
`;

export const ButtonAdd = styled.button`
    background: none;
    border: none;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: #292929;

        small {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #a3a3a3;
        }
    }
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 14px;

    input {
        padding: 15px 10px;
        min-width: 200px;
    }

    & > div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 75%;
        gap: 15px;

        & > button {
            min-width: 95px;
            margin-bottom: 5px;
        }
    }

    #search {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        & > div {
            flex-direction: column;
            width: 100%;
        }

        #search {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            gap: 10px;
        }

        #search > button {
            padding: 0 10px;
            margin: 0;
            width: 100%;
        }

        & > button {
            margin-top: 20px;
            width: 100%;
        }
    }
`;

export const SeeColumnsButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #a3a3a3;
    background: #f9f9f9;
    border-radius: 4px;
    border: none;

    svg {
        margin-left: 5px;
    }
`;

export const TeamGroup = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > img {
        width: 4rem;
        height: 4rem;
        border-radius: 4rem;
        border: 1px solid #ffffff;
        margin-left: -15px;
    }
`;
