/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useTheme } from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';
import type { InputProps } from './types';
import { Container, Error, Label } from './styles';
import PhoneInput from 'react-phone-input-2';
import { Controller } from 'react-hook-form';

const InputPhone: React.FC<InputProps> = ({
    name,
    label,
    error,
    value,
    marginVertical,
    marginHorizontal,
    control,
    ...rest
}) => {
    const theme = useTheme();

    return (
        <Container
            marginHorizontal={marginHorizontal}
            marginVertical={marginVertical}
            width={rest?.width}
        >
            <Label>
                {label}
                {error && (
                    <Error title={error}>
                        <FiAlertCircle
                            color={theme.colors.error.pure}
                            size={20}
                        />
                    </Error>
                )}
            </Label>
            <Controller
                render={({ field }) => (
                    <PhoneInput
                        {...field}
                        containerClass={`phone-input-container ${
                            error && 'phone-input-error'
                        }`}
                        buttonClass="phone-input-button"
                        onBlur={() => {
                            field.onBlur();
                        }}
                        country="br"
                    />
                )}
                control={control}
                name={name}
                defaultValue={value}
            />
        </Container>
    );
};

export default InputPhone;
