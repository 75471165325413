import React from 'react';
import RCImage from 'rc-image';
import { Container, Download } from './styles';
import { MdDownload } from 'react-icons/md';

type ImageViewerProps = {
    src: string;
    width?: number;
};
const ImageViewer: React.FC<ImageViewerProps> = ({ src, width = 200 }) => {
    const [visible, setVisible] = React.useState(false);

    const downloadImage = () => {
        const anchor = document.createElement('a');
        anchor.href = src;
        anchor.target = '_blank';
        anchor.download = 'image';
        anchor.click();
    };
    return (
        <Container>
            <RCImage
                width={width}
                preview={{
                    visible,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                    zIndex: 10
                }}
                src={src}
            />
            <Download onClick={downloadImage}>
                <MdDownload />
            </Download>
        </Container>
    );
};

export default ImageViewer;
