import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as ChatIcon } from 'assets/icons/chat-icon.svg';

import { Container, Header, Card, CardList, CloseButton } from './styles';
import { HiOutlineUserPlus } from 'react-icons/hi2';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

type ModalDecisionProps = {
    isOpen: boolean;
    handleClose: () => void;
};

const ModalDecision: React.FC<ModalDecisionProps> = ({
    handleClose,
    isOpen
}) => {
    const navigate = useNavigate();

    return (
        <Modal
            isOpen={isOpen}
            style={{
                content: { maxWidth: '80%', height: '52rem', margin: 'auto' },
                overlay: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0,0,0,0.6)'
                }
            }}
        >
            <Container>
                <CloseButton type="button" onClick={() => handleClose()}>
                    <AiOutlineClose size={18} />
                </CloseButton>
                <Header>
                    <h1>Crie uma automação personalizada</h1>
                    <p>
                        Cria uma regra customizada especialmente para o seu
                        negócio.
                    </p>
                </Header>

                <CardList>
                    <Card
                        onClick={() =>
                            navigate('board', {
                                state: { contactType: 'all_contacts' }
                            })
                        }
                    >
                        <ChatIcon />
                        <h3>Mensagens recebidas</h3>
                        <p>
                            Crie um fluxo para todas as novas mensagens
                            recebidas por seus clientes
                        </p>
                    </Card>

                    <Card
                        onClick={() =>
                            navigate('board', {
                                state: { contactType: 'new_contacts' }
                            })
                        }
                    >
                        <HiOutlineUserPlus size={14} color="#0B57F5" />
                        <h3>Novo contato</h3>
                        <p>
                            Automatize a comunicação com cada um dos novos
                            contatos.
                        </p>
                    </Card>
                </CardList>
            </Container>
        </Modal>
    );
};

export default ModalDecision;
