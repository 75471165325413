import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Title = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: ${({ theme }) => theme.lineHeight.md};
    letter-spacing: -0.9px;
    padding-top: 10%;
    color: #3c4257;

    ${customMediaQuery.greaterThan('md')`
    font-size: 36px;
    line-height: ${({ theme }) => theme.lineHeight.lg};
    font-size: ${({ theme }) => theme.fontSize.md};
    letter-spacing: initial;
  `}
`;

export const Subtitle = styled.h2`
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: ${({ theme }) => theme.lineHeight.lg};
    padding-top: 1.2rem;
    color: ${({ theme }) => theme.colors.low.light};
`;
