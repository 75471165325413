import React, { useCallback, useEffect, useState } from 'react';

import { Container, Card, Title, CardGroup } from './styles';
import { useDashboard } from 'store';
import { DashboardLeadTime } from 'services/dashboard/types';
import { BsInfoSquare } from 'react-icons/bs';

type LeadTimeProps = {
    period: string;
};

const LeadTime: React.FC<LeadTimeProps> = ({ period }) => {
    const { getLeadTime } = useDashboard();
    const [data, setData] = useState<
        {
            id: keyof DashboardLeadTime;
            label: string;
            color: string;
            value: number;
            percentage?: string;
        }[]
    >([
        {
            id: 'dailyTotal',
            label: 'Total (diário)',
            color: '#E3F5FF',
            value: 0,
            percentage: '0%'
        },
        {
            id: 'minutesWaitingTime',
            label: 'Espera (diário)',
            color: '#E5ECF6',
            value: 0
        }
    ]);

    const handleAttendanceData = useCallback(async () => {
        const result = await getLeadTime({ period });

        setData([
            {
                id: 'dailyTotal',
                label: 'Total (diário)',
                color: '#E3F5FF',
                value: result?.dailyTotal || 0,
                percentage: (result?.percentage || 0).toString()
            },
            {
                id: 'minutesWaitingTime',
                label: 'Espera (diário)',
                color: '#E5ECF6',
                value: result?.minutesWaitingTime || 0
            }
        ]);
    }, [getLeadTime, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <Title>
                Tempo médio de atendimentos <BsInfoSquare />
            </Title>
            <CardGroup>
                {data.map((item) => (
                    <Card key={item.id} color={item.color}>
                        <p>{item.label}</p>
                        <span>{item.value.toFixed(2)}</span>

                        {item.percentage && (
                            <small>{`${item.percentage}%`}</small>
                        )}
                    </Card>
                ))}
            </CardGroup>
        </Container>
    );
};

export default LeadTime;
