import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Container = styled.div<{ disabled?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    /* max-width: 300px; */
    width: 100%;

    & > p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 100%;
        color: #666666;
    }
`;

export const Content = styled.div`
    flex: 1;
    & > h3 {
        font-weight: 500;
        font-size: 1.4rem;
        color: #000000;
    }

    & > p {
        font-weight: 400;
        font-size: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 16rem;
        width: 100%;
        color: #666666;
        margin: 8px 0;

        ${customMediaQuery.between('md', 'lg')`
            max-witdh: 8rem;
        `}
    }

    & > span {
        font-weight: 400;
        font-size: 1.2rem;
        color: #222222;
        padding: 0.2rem 0.8rem;
        border-radius: 6px;
        background: #ffe3c2;
    }
`;

export const AvatarWrapper = styled.div`
    position: relative;
    margin-right: 1.6rem;

    & > svg {
        position: absolute;
        bottom: 10%;
        right: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid #fff;
        border-radius: 2rem;
    }

    ${customMediaQuery.lessThan('lg')`
        & > img {
            width: 3.4rem;
            height: 3.4rem;
            border-radius: 3.4rem;
        }
        
        & > svg {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 1.5rem;
        }    
    `}
`;
