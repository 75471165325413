import styled from 'styled-components';

export const Container = styled.img<{ size?: string; imageDefault?: boolean }>`
    display: block;
    width: ${({ size }) => size || '3rem'};
    height: ${({ size }) => size || '3rem'};
    border-radius: ${({ size }) => size || '3rem'};
    object-fit: ${({ imageDefault }) => (imageDefault ? 'contain' : 'cover')};
    background-color: ${({ imageDefault }) =>
        imageDefault ? '#aaaaaa' : 'none'};
    padding: ${({ imageDefault }) => (imageDefault ? '5px' : '0')};
`;
