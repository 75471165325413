/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    type InputHTMLAttributes,
    useCallback,
    useRef,
    useState
} from 'react';
import { useTheme } from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import MaskedInput from 'react-text-mask';
import InputMask from 'react-input-mask';
import { type InputProps, currencyMask } from './types';

import {
    Container,
    Error,
    InputWrapper,
    Label,
    PasswordButton
} from './styles';

const Input: React.FC<InputProps> = ({
    name,
    label,
    error,
    mask,
    currency,
    value,
    marginVertical,
    marginHorizontal,
    showPassword,
    backgroundWhite,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    register,
    beforeMaskedStateChange,
    type,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [showPasswordStatus, setShowPasswordStatus] = useState(false);
    const theme = useTheme();

    const handleInputFocus = useCallback(() => {
        setIsFocused(() => true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(() => false);
        setIsFilled(Boolean(inputRef.current?.value));
    }, []);

    const togglePasswordVisiblity = useCallback(() => {
        setShowPasswordStatus(!showPasswordStatus);
    }, [showPasswordStatus]);

    const validateTypePassword = () => {
        if (showPassword ?? false) {
            return showPasswordStatus ? 'text' : 'password';
        }
        return type;
    };
    return (
        <Container
            marginHorizontal={marginHorizontal}
            marginVertical={marginVertical}
            width={rest?.width}
        >
            <Label>
                {label}
                {error && (
                    <Error title={error}>
                        <FiAlertCircle
                            color={theme.colors.error.pure}
                            size={20}
                        />
                    </Error>
                )}
            </Label>
            <InputWrapper
                backgroundWhite={backgroundWhite}
                isErrored={!!error}
                isFilled={isFilled}
                isFocused={isFocused}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                width={rest?.width}
            >
                {LeftIcon != null && <LeftIcon size={20} />}
                {!mask && !currency && (
                    <input
                        id={name}
                        name={name}
                        value={value}
                        ref={inputRef}
                        type={validateTypePassword()}
                        {...rest}
                        {...register}
                    />
                )}

                {!mask && currency && (
                    <MaskedInput
                        mask={currencyMask}
                        id={name}
                        name={name}
                        value={value}
                        {...rest}
                        {...register}
                    />
                )}

                {mask && !currency && (
                    <InputMask
                        mask={mask}
                        id={name}
                        name={name}
                        value={value}
                        beforeMaskedStateChange={beforeMaskedStateChange}
                        maskPlaceholder={null}
                        {...rest}
                        {...register}
                    >
                        {(
                            inputProps: InputHTMLAttributes<HTMLInputElement>
                        ) => (
                            <input type="text" {...inputProps} {...register} />
                        )}
                    </InputMask>
                )}
                {showPassword && (
                    <PasswordButton
                        type="button"
                        onClick={togglePasswordVisiblity}
                    >
                        {showPasswordStatus ? (
                            <AiOutlineEyeInvisible size={20} />
                        ) : (
                            <AiOutlineEye size={20} />
                        )}
                    </PasswordButton>
                )}
                {RightIcon != null && <RightIcon size={20} />}
            </InputWrapper>
        </Container>
    );
};

export default Input;
