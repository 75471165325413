import React, { useCallback } from 'react';
import { Container, Header, MenuDesktopItem } from './styles';
import { ReactComponent as DeetzIcon } from 'assets/icons/DEETZ.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import UserDetails from 'Components/Molecules/UserDetails';
import { useAuthStore } from 'store';

type MenuDesktopProps = {
    content: {
        label: string;
        path: string;
    }[];
};
const MenuDesktop: React.FC<MenuDesktopProps> = ({ content }) => {
    const { userData, logout } = useAuthStore();
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        logout();
        navigate('/login');
    }, [logout, navigate]);

    return (
        <Header>
            <Container>
                <MenuDesktopItem>
                    <DeetzIcon />
                </MenuDesktopItem>
                {content.map((item) => (
                    <MenuDesktopItem key={item.path}>
                        <NavLink
                            to={`/${item.path}`}
                            replace={true}
                            preventScrollReset={true}
                            relative={'route'}
                        >
                            {item.label}
                        </NavLink>
                    </MenuDesktopItem>
                ))}
            </Container>

            <UserDetails
                image={userData?.avatar}
                name={userData?.firstName}
                logout={handleLogout}
            />
        </Header>
    );
};

export default MenuDesktop;
