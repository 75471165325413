export const modalContent = {
    newTitle: 'Novo usuário',
    editTitle: 'Edição de usuário',
    actions: {
        cancel: 'Cancelar',
        save: 'Salvar'
    },
    fields: {
        firstName: {
            label: 'Primeiro nome',
            placeholder: 'Ex: Paulo'
        },
        lastName: {
            label: 'Sobrenome',
            placeholder: 'Ex: Rodrigues de Oliveira'
        },
        email: {
            label: 'Email',
            placeholder: 'Ex: Rodrigues de Oliveira'
        },
        phone: {
            label: 'Telefone',
            placeholder: '(00) 00000-0000'
        },
        office: {
            label: 'Setor',
            placeholder: ''
        },
        role: {
            label: 'Posição',
            placeholder: ''
        },
        team: {
            label: 'Time',
            placeholder: ''
        },
        password: {
            label: 'Senha',
            placeholder: '',
            validations: {
                PASSWORD_CAMELCASE_CHECK: 'Letras maiúsculas e minúsculas',
                PASSWORD_NUMERIC_CHECK: 'Caracteres numéricos',
                PASSWORD_SIZE_CHECK: 'Mínimo de 7 caracteres'
            }
        }
    }
};
