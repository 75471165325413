import React, { useCallback, useState } from 'react';

import {
    Container,
    InputWrapper,
    MenssagesList,
    ResultLength,
    SmallContact,
    Title
} from './styles';
import { BsChevronLeft } from 'react-icons/bs';
import { useContactStore } from 'store/useContacts';
import Input from 'Components/Atoms/Input';
import { FiSearch } from 'react-icons/fi';
import { ChatContactMessages } from 'services/contacts/types';

const MessageSearch: React.FC = () => {
    const { setSearchMessageEnabled, currentContactMessages } =
        useContactStore();
    const [messagesFiltered, setMessagesFiltered] = useState<
        ChatContactMessages[]
    >([]);
    const [textValue, setTextValue] = useState('');

    const handleFilterMessages = useCallback(
        (text: string) => {
            if (!text) {
                setMessagesFiltered([]);
            } else {
                const messages = currentContactMessages.filter((message) =>
                    message.messageBody.text?.includes(text)
                );

                setMessagesFiltered(messages);
            }
        },
        [currentContactMessages]
    );

    const getAbbreviation = useCallback((text: string, reference: string) => {
        const words = text.split(' ');
        const indice = words.findIndex((word) =>
            word.toLowerCase().includes(reference.toLowerCase())
        );

        const start = indice > 4 ? indice - 4 : 0;
        const abbreviation = words.slice(start, indice + 1).join(' ');

        const initialBullets = !start ? '' : '...';

        return initialBullets + abbreviation + '...';
    }, []);

    const goToMessage = useCallback((messageId: number) => {
        const list = document.getElementById('messageList');
        const message = document.getElementById(`messageItem-${messageId}`);

        if (list && message) {
            message.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    return (
        <Container>
            <Title onClick={() => setSearchMessageEnabled()}>
                <BsChevronLeft size={16} />
                Cancelar busca
            </Title>

            <InputWrapper>
                <Input
                    name="search"
                    leftIcon={FiSearch}
                    placeholder="Texto"
                    onChange={(e) => {
                        handleFilterMessages(e.currentTarget.value);
                        setTextValue(e.currentTarget.value);
                    }}
                />
            </InputWrapper>

            <ResultLength>{`${
                messagesFiltered.length === 0
                    ? 'nenhum'
                    : messagesFiltered.length.toString()
            } resultado${
                messagesFiltered.length > 1 ? 's' : ''
            }`}</ResultLength>

            <MenssagesList>
                {messagesFiltered.map((message) => (
                    <li
                        key={message.id}
                        onClick={() => goToMessage(message.id)}
                    >
                        <SmallContact>
                            <img
                                src={message?.source?.avatar}
                                alt={message?.source?.firstName}
                            />
                            <div>
                                <h3>{`${message?.source?.firstName} ${message?.source?.lastName}`}</h3>
                                <p>
                                    {getAbbreviation(
                                        message?.messageBody?.text || '',
                                        textValue
                                    )}
                                </p>
                            </div>
                        </SmallContact>
                    </li>
                ))}
            </MenssagesList>
        </Container>
    );
};

export default MessageSearch;
