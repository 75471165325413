import { createGlobalStyle } from 'styled-components';
import { customMediaQuery } from './mediaQuery';

const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;

  }
  *:focus{
    outline: 0;
  }

  html {
    font-size: 62.5%;
  }

  html, body, #root{
    width: 100%;
    height: 100%;
    text-rendering: optimizelegibility;
  }
  body{
    -webkit-font-smoothing: antialiased;
  }
  body, input, button{
    font-family: 'Inter', sans-serif;
  }
  a{
    text-decoration: none;
  }
  ul{
    list-style: none;
  }
  button{
    cursor: pointer;
  }

  audio {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    object-fit: contain;
  }

  ${customMediaQuery.lessThan('md')`
    html {
      font-size: 50%;
    }
  `}

  em-emoji-picker {
    width: 100%;
    min-width: 300px;
    max-height: 200px;
    resize: horizontal;
    overflow: auto;
    z-index: 10
  }
`;

export default GlobalStyles;
