import styled from 'styled-components';

export const Container = styled.div<{ size: number }>`
    margin-bottom: 30px;
    cursor: pointer;
    p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: ${({ theme }) => theme.fontSize.xxs};
        color: ${({ theme }) => theme.colors.brand.pure};
        svg {
            margin-right: 1rem;
        }
    }
`;
