import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    form {
        width: 100%;
    }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;
    color: #3c4257;
    margin-bottom: 12px;
`;

export const Description = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #a3a3a3;
    margin-bottom: 24px;
`;

export const Resend = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #a3a3a3;
    }

    & > button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #0b57f5;
    }
`;
