import React, { useCallback, useState } from 'react';
import { Container, Title, Wrapper } from './styles';
import UnloggedLayout from 'Components/Templates/UnloggedLayout';
import { content } from './content';
import Goback from 'Components/Atoms/Goback';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from 'store';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import ValidationItem from 'Components/Molecules/ValidationItem';

const ResetPassword: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();
    const { resetPasswordAction } = useAuthStore();
    const schema = z
        .object({
            newPassword: z
                .string()
                .min(7, 'PASSWORD_SIZE_CHECK')
                .regex(/\d/gi, 'PASSWORD_NUMERIC_CHECK')
                .regex(/(?=.*[a-z])(?=.*[A-Z])/g, 'PASSWORD_CAMELCASE_CHECK'),
            repeatPassword: z
                .string()
                .min(7, 'PASSWORD_SIZE_CHECK')
                .regex(/\d/gi, 'PASSWORD_NUMERIC_CHECK')
                .regex(/(?=.*[a-z])(?=.*[A-Z])/g, 'PASSWORD_CAMELCASE_CHECK')
        })
        .refine((schema) => schema.newPassword === schema.repeatPassword, {
            message: 'PASSWORD_DIFFERENCE'
        });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid }
    } = useForm<{
        newPassword: string;
        repeatPassword: string;
    }>({
        resolver: zodResolver(schema)
    });

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);

        const result = await resetPasswordAction({
            newPassword: data.newPassword,
            token: token || ''
        });

        if (result) {
            navigate('/login', { replace: true, preventScrollReset: true });
        }
        setLoading(false);
    });

    const verifyValidity = (rule: any) => {
        return !!watch('newPassword')?.match(rule);
    };

    const goToHome = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    return (
        <UnloggedLayout backgroundImage={content.image}>
            <Wrapper>
                <Goback title={content.back} size={14} handleClick={goToHome} />
                <Container>
                    <Title>{content.title}</Title>
                    <form onSubmit={onSubmit}>
                        <Input
                            name="newPassword"
                            id="newPassword"
                            width="100%"
                            marginVertical="10px"
                            label={content.fields.newPassword.label}
                            placeholder={content.fields.newPassword.placeholder}
                            error={errors.newPassword?.message}
                            showPassword
                            register={register('newPassword')}
                        />
                        <ul>
                            <ValidationItem
                                checked={verifyValidity(
                                    /(?=.*[a-z])(?=.*[A-Z])/g
                                )}
                                label={
                                    content.fields.newPassword.validations
                                        .PASSWORD_CAMELCASE_CHECK
                                }
                            />
                            <ValidationItem
                                checked={verifyValidity(/\d/gi)}
                                label={
                                    content.fields.newPassword.validations
                                        .PASSWORD_NUMERIC_CHECK
                                }
                            />
                            <ValidationItem
                                checked={verifyValidity(/^.{7,}$/)}
                                label={
                                    content.fields.newPassword.validations
                                        .PASSWORD_SIZE_CHECK
                                }
                            />
                        </ul>

                        <Input
                            name="repeatPassword"
                            id="repeatPassword"
                            width="100%"
                            marginVertical="20px"
                            label={content.fields.password.label}
                            placeholder={content.fields.password.placeholder}
                            error={errors.repeatPassword?.message}
                            showPassword
                            register={register('repeatPassword')}
                        />

                        <Button
                            type="submit"
                            marginVertical="34px"
                            size="SMALL"
                            rounded
                            disabled={loading || !isValid}
                            loading={loading}
                        >
                            {content.submit}
                        </Button>
                    </form>
                </Container>
            </Wrapper>
        </UnloggedLayout>
    );
};

export default ResetPassword;
