import WelcomeImage from 'assets/images/login/welcome.webp';

export const content = {
    image: WelcomeImage,
    register: {
        text: 'Ainda não tem conta?',
        link: {
            text: 'Cadastre-se',
            url: '/register'
        }
    },
    title: 'Acesse a sua área restrita',
    subtitle: 'Entre com seu usuário e senha',
    fields: {
        email: {
            label: 'Email',
            placeholder: 'Seu email aqui'
        },
        password: {
            label: 'Senha',
            placeholder: 'Sua senha aqui'
        }
    },
    forgotPassword: {
        text: 'Esqueci a senha',
        url: '/forgot-password'
    },
    submit: 'Entrar',
    social: {
        title: 'Ou use as redes',
        button: 'Continue com o Google'
    }
};
