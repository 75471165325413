import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 4rem;
    flex-wrap: wrap;
`;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding-bottom: 1rem;
    color: #292929;

    small {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.8rem;
        padding-left: 0.6rem;
        color: #666666;
    }
`;

export const Card = styled.div<{
    color: string;
}>`
    background-color: ${({ color }) => color};
    width: 17rem;
    height: 11rem;
    padding: 2.4rem;
    border-radius: 1.6rem;
    box-sizing: border-box;
    position: relative;

    & > p {
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1c1c1c;
        padding-bottom: 0.8rem;
    }

    & > span {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3.6rem;
        color: #1c1c1c;
    }
`;

export const CardGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
`;
