import React from 'react';
import { Container } from './styles';
import ChatContact, { ChatContactProps } from '../ChatContact';
import { Channels } from 'services/contacts/types';

type ChatContactListProps = {
    contacts: ChatContactProps[];
    disabled?: boolean;
    openContact: (props: {
        contactId: number;
        channelCommunication: Channels;
    }) => void;
};

const ChatContactList: React.FC<ChatContactListProps> = ({
    contacts,
    disabled,
    openContact
}) => {
    return (
        <Container disabled={disabled}>
            {contacts.map((contact) => (
                <ChatContact
                    key={contact.id}
                    {...contact}
                    onClick={() =>
                        openContact({
                            contactId: contact.id,
                            channelCommunication: contact.channelCommunication
                        })
                    }
                />
            ))}
        </Container>
    );
};

export default ChatContactList;
