import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    position: relative;
    align-items: center;
    gap: 10px;
    background: none;
    border: none;

    & > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
    }
`;

export const Menu = styled.ul`
    position: absolute;
    width: 150px;
    height: auto;
    background: #ffffff;
    display: flex;
    z-index: 9999999999999;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    bottom: -6rem;
    right: 0;
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export const MenuItem = styled.li`
    padding: 20px 10px;
    width: 100%;

    button {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
        width: 100%;

        svg {
            margin-left: 10px;
        }

        &:hover {
            color: #0b57f5;

            svg {
                color: #0b57f5;
            }
        }
    }
`;
