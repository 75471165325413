import React from 'react';

import { Text, Link } from './styles';

interface TextLinkProps {
    text: string;
    linkText: string;
    linkUrl: string;
}
const TextLink: React.FC<TextLinkProps> = ({ text, linkText, linkUrl }) => {
    return (
        <Text>
            {text} <Link to={linkUrl}>{linkText}</Link>
        </Text>
    );
};

export default TextLink;
