import { toast } from 'react-toastify';
import AuthService from 'services/auth';
import { RegisterRequest, RegisterResponse } from 'services/auth/types';
import { create } from 'zustand';

export interface createRegisterSliceProps {
    step: number;
    data: RegisterRequest;
    nextStep: (data: RegisterRequest) => void;
    previousStep: () => void;
    signUpAction: (
        data: RegisterRequest,
        callback: () => void
    ) => Promise<RegisterResponse | null>;
}

const MAX_STEPS = 3;
const MIN_STEPS = 1;
const DEFAULT_DATA = {
    email: '',
    password: '',
    token: '',
    channels: [],
    firstName: '',
    lastName: '',
    phone: '',
    company: {
        name: '',
        category: '',
        numberOfCollaborators: '',
        siteUrl: ''
    }
};

export const useRegisterStore = create<createRegisterSliceProps>()(
    (set, get) => ({
        step: 1,
        data: DEFAULT_DATA,
        nextStep: (data: RegisterRequest) => {
            if (get().step < MAX_STEPS) {
                set((state) => ({ step: state.step + 1 }));
                set((state) => ({ data: { ...state.data, ...data } }));
            }
        },
        previousStep: () => {
            if (get().step > MIN_STEPS) {
                set((state) => ({ step: state.step - 1 }));
            }
        },
        signUpAction: async (formData: RegisterRequest, callback) => {
            try {
                const result = (await AuthService.register({
                    ...get().data,
                    ...formData
                })) as RegisterResponse;
                set(() => ({ step: 1, data: DEFAULT_DATA }));
                callback();
                toast.success('Cadastro efetuado com sucesso!');
                return result;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'register' });
                return null;
            }
        }
    })
);
