import { socialType } from 'Constant';
import React from 'react';

import { Body, Container, Footer, Header } from './styles';
import dayjs from 'dayjs';
import { MdAccessTime } from 'react-icons/md';
import { currencyFormat } from 'utils/currencyFormat';

export type CardProps = {
    id: number;
    name: string;
    channel: socialType;
    negotiationValue?: number;
    updatedAt: string;
    avatar: string;
    onClick: () => void;
};
const Card: React.FC<CardProps> = ({
    avatar,
    channel,
    name,
    updatedAt,
    negotiationValue,
    onClick
}) => {
    return (
        <Container onClick={() => onClick()}>
            <Header>
                <p>{name}</p>
            </Header>
            <Body>
                <span>{channel}</span>
                <p>
                    {currencyFormat('pt-BR', 'BRL').format(
                        negotiationValue || 0
                    )}
                </p>
            </Body>
            <Footer>
                <p>
                    <MdAccessTime color="#666666" />
                    {dayjs(updatedAt).format('hh:mm')}
                </p>
                <img src={avatar} alt={name} />
            </Footer>
        </Container>
    );
};

export default Card;
