import Divider from 'Components/Atoms/Divider';
import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h3`
    font-weight: 600;
    font-size: 2rem;
    line-height: 100%;
    color: #292929;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        flex-direction: column;

        button {
            width: 100%;
            margin-top: 20px;
        }
    }
`;

export const FormRow = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10rem;

    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 5rem;
    }
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 1.2rem;
`;

export const CustomDivider = styled(Divider)`
    margin: 4rem 0;
`;

export const FormTitle = styled.h3`
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 100%;
    color: #292929;
`;
