import React, { useState } from 'react';
import {
    ChannelsSection,
    Container,
    GroupsSection,
    SmallContainers,
    Title
} from './styles';
import { AiOutlinePlus } from 'react-icons/ai';
import { content } from '../content';
import SocialTip from 'Components/Molecules/SocialTip';
import { useContactStore } from 'store/useContacts';
import Loading from 'Components/Atoms/Loading';
import ModalGroups from '../ModalGroups';
import confirmDialog from 'Components/Atoms/ConfirmDialog';

const Groups: React.FC = () => {
    const { currentContactDetails, loadingDetails, removeContactGroup } =
        useContactStore();
    const [modalGroups, setModalGroups] = useState(false);

    if (loadingDetails) {
        return <Loading />;
    }

    const handleConfirmDelete = async (groupId: number) => {
        confirmDialog({
            title: 'Remover grupo do contato',
            subtitle: 'Deseja realmente remover este grupo?',
            acceptLabel: 'Sim',
            denyLabel: 'Não',
            acceptFunction: () =>
                removeContactGroup({
                    contactId: Number(currentContactDetails?.id),
                    groupId: groupId
                })
        });
    };

    return (
        <Container>
            <SmallContainers>
                <ChannelsSection>
                    <Title>{content.contactDetails.channels.title}</Title>

                    {currentContactDetails?.contactChannels.map((channel) => (
                        <SocialTip
                            socialMedia={channel.type}
                            key={channel.id}
                        />
                    ))}
                </ChannelsSection>
            </SmallContainers>

            <SmallContainers>
                <GroupsSection>
                    <Title>{content.contactDetails.groups.title}</Title>
                    <ul>
                        {currentContactDetails?.groups
                            .filter((group) => !!group.isActived)
                            .map((item) => (
                                <li
                                    key={item.id}
                                    onClick={() => handleConfirmDelete(item.id)}
                                >
                                    {item.name}
                                </li>
                            ))}
                    </ul>

                    <button onClick={() => setModalGroups(true)}>
                        <AiOutlinePlus size={16} />
                        {content.contactDetails.groups.action.label}
                    </button>
                </GroupsSection>
            </SmallContainers>

            <ModalGroups
                handleClose={() => setModalGroups(false)}
                isOpen={modalGroups}
            />
        </Container>
    );
};

export default Groups;
