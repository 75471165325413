/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../api';
import {
    AuthRequest,
    AuthResponse,
    ForgotPasswordRequest,
    RegisterRequest,
    RegisterResponse,
    ResetPasswordRequest,
    UserModel
} from './types';
import { ErrorBoundary } from 'services/errorBoundary';

class AuthService {
    async login(data: AuthRequest): Promise<AuthResponse | undefined> {
        try {
            const result = await api.post<AuthResponse>(
                `/signin/${data.token ? 'google' : 'email'}`,
                data
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async updateProfile(
        id: number,
        data: FormData
    ): Promise<UserModel | undefined> {
        try {
            const result = await api.put<UserModel>(`/users/${id}`, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async register(
        data: RegisterRequest
    ): Promise<RegisterResponse | undefined> {
        const { company, token, ...user } = data;
        try {
            const result = await api.post<RegisterResponse>(
                `/signup/${token ? 'google' : 'email'}`,
                {
                    company,
                    user: {
                        ...user
                    },
                    token: token
                }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async sendRecoveryToEmail(
        data: ForgotPasswordRequest
    ): Promise<number | undefined> {
        try {
            const result = await api.post('/users/forget-password', data);
            return result?.status;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async resetPassword(
        data: ResetPasswordRequest
    ): Promise<number | undefined> {
        try {
            const result = await api.put(
                `/users/reset-password/${data.token}`,
                data
            );
            return result?.status;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
}

export default new AuthService();
