import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #e4ebf3;
    margin: 24px 0;
`;

export const Text = styled.p`
    position: absolute;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: ${({ theme }) => theme.lineHeight.lg};
    font-size: 12px;
    text-align: center;
    color: #8d93a1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.high.pure};
    padding: 0 10px;
`;
