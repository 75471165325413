import React, { useCallback, useState } from 'react';

import LoggedLayout from 'Components/Templates/LoggedLayout';
import ContactTable from './ContactTable';
import ContactBoard from './ContactBoard';

const Contacts: React.FC = () => {
    const [columnActived, setColumnActived] = useState(false);

    const handleSwitchViewMode = useCallback(() => {
        setColumnActived((oldValue) => !oldValue);
    }, []);

    return (
        <LoggedLayout>
            {columnActived ? (
                <ContactBoard switchViewMode={handleSwitchViewMode} />
            ) : (
                <ContactTable switchViewMode={handleSwitchViewMode} />
            )}
        </LoggedLayout>
    );
};

export default Contacts;
