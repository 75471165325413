import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { Container, Title, RuleItem, RuleList } from './styles';
import { useTheme } from 'styled-components';

interface SectionTitleProps {
    title: string;
    rules: string[];
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, rules }) => {
    const theme = useTheme();
    return (
        <Container>
            <Title>{title}</Title>
            <RuleList>
                {rules.map((rule) => (
                    <RuleItem key={rule}>
                        <BsCheck color={theme.colors.success.pure} size={30} />
                        <p>{rule}</p>
                    </RuleItem>
                ))}
            </RuleList>
        </Container>
    );
};

export default SectionTitle;
