import React from 'react';
import { ReactComponent as Whatsapp } from 'assets/icons/whatsapp-icon.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram-icon.svg';
import { ReactComponent as Messenger } from 'assets/icons/messenger-icon.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook-icon.svg';
import { ReactComponent as SMS } from 'assets/icons/sms-icon.svg';
import { ReactComponent as Telegram } from 'assets/icons/telegram-icon.svg';
import {
    FaFacebook,
    FaFacebookMessenger,
    FaInstagram,
    FaTelegram,
    FaWhatsapp
} from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

export type socialType =
    | 'whatsapp'
    | 'instagram'
    | 'messenger'
    | 'sms'
    | 'telegram';

const groupsColors = {
    marketing: { background: '#D4F8D3', color: '#222222' },
    comercial: { background: '#FFF0BB', color: '#CC6E00' }
};

const icons = {
    whatsapp: <Whatsapp />,
    instagram: <Instagram />,
    sms: <SMS />,
    telegram: <Telegram />,
    messenger: <Messenger />,
    facebook: <Facebook />
};

const iconsCleaned = {
    whatsapp: <FaWhatsapp />,
    instagram: <FaInstagram />,
    sms: <FiMessageCircle />,
    telegram: <FaTelegram />,
    messenger: <FaFacebookMessenger />,
    facebook: <FaFacebook />
};

const socialLegends = {
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    sms: 'SMS',
    telegram: 'Telegram',
    messenger: 'Messenger',
    facebook: 'Facebook'
};

const statusLegends = {
    approved: {
        label: 'Aprovado',
        color: '#205A24'
    },
    pending: {
        label: 'Pendente',
        color: '#7A4200'
    },
    denied: {
        label: 'Negado',
        color: '#710A0A'
    }
};

export { groupsColors, icons, socialLegends, iconsCleaned, statusLegends };
