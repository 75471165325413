import LoggedLayout from 'Components/Templates/LoggedLayout';
import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from './styles';
import CallsPerDay from './CallsPerDay';
import dayjs from 'dayjs';
import Messages from './Messages';
import { useDashboard, useSettingsStore } from 'store';
import { useParams } from 'react-router-dom';
import Talks from './Talks';
import MemberDetails from './MemberDetails';
import { TeamsDetailsResponse } from 'services/settings/types';
import { DashboardCallsPerDay } from 'services/dashboard/types';

const TeamDetails: React.FC = () => {
    const [period, setPeriod] = useState(dayjs().format('MM/YYYY'));

    const [memberDetails, setMemberDetails] = useState<TeamsDetailsResponse>();
    const [dashboardData, setDashboardData] = useState<DashboardCallsPerDay[]>(
        []
    );
    const { getCallsPerDayMember } = useDashboard();
    const { getTeamsDetails } = useSettingsStore();
    const { memberId } = useParams();

    const handleGetCallsPerDayMember = useCallback(async () => {
        const result = await getCallsPerDayMember({
            memberId: Number(memberId),
            period: period
        });
        setDashboardData(result);
    }, [getCallsPerDayMember, memberId, period]);

    const handleGetTeamMemberDetails = useCallback(async () => {
        const result = await getTeamsDetails(Number(memberId));
        if (result) {
            setMemberDetails(result);
        }
    }, [getTeamsDetails, memberId]);

    useEffect(() => {
        if (memberId) {
            handleGetCallsPerDayMember();
            handleGetTeamMemberDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId, period]);

    return (
        <LoggedLayout backgroudInverted>
            <Row>
                <MemberDetails memberDetails={memberDetails} />
                <Col>
                    <CallsPerDay data={dashboardData} setPeriod={setPeriod} />
                    <Row style={{ flexWrap: 'wrap' }}>
                        <Messages currentMember={memberDetails} />
                        <Talks currentMember={memberDetails} />
                    </Row>
                </Col>
            </Row>
        </LoggedLayout>
    );
};

export default TeamDetails;
