import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ theme }) => theme.spacing.md};
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
        0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 10px;
    border: none;
    padding: 1.3rem 0;
`;

export const Text = styled.p`
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: 19px;
    padding-left: 1.5rem;
`;
