import React from 'react';
import { ReactComponent as GoogleIcon } from 'assets/icons/google-icon.svg';
import { Container, Text } from './styles';

interface GoogleButtonProps {
    text: string;
    onClick: () => void;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({ text, onClick }) => {
    return (
        <Container onClick={onClick}>
            <GoogleIcon />
            <Text>{text}</Text>
        </Container>
    );
};

export default GoogleButton;
