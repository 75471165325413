import React from 'react';
import { Body, Container } from './styles';
import MenuDesktop from 'Components/Organisms/MenuDesktop';
import MenuMobile from 'Components/Organisms/MenuMobile';
import { useAuthStore } from 'store';
import { Navigate } from 'react-router-dom';

const content = [
    { label: 'Dashboard', path: '../dashboard' },
    { label: 'Conversas', path: '../chat' },
    { label: 'Contatos', path: '../contacts' },
    { label: 'Automação', path: '../automation' },
    { label: 'Configuração', path: '../settings' }
];

interface LoggedLayoutProps {
    children: React.ReactNode;
    backgroudInverted?: boolean;
    padding?: string;
}

const LoggedLayout: React.FC<LoggedLayoutProps> = ({
    children,
    padding,
    backgroudInverted
}) => {
    const { accessToken } = useAuthStore();

    if (!accessToken) {
        return <Navigate to={'/login'} replace />;
    }

    return (
        <Container>
            <MenuDesktop content={content} />
            <MenuMobile content={content} />

            <Body padding={padding} backgroudInverted={backgroudInverted}>
                {children}
            </Body>
        </Container>
    );
};

export default LoggedLayout;
