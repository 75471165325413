import React from 'react';

type AudioProps = {
    src: string;
};

const Audio: React.FC<AudioProps> = ({ src }) => {
    return (
        <audio controls autoPlay={false} style={{ padding: 0 }}>
            <source src={src} />
        </audio>
    );
};

export default Audio;
