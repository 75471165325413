import UnloggedLayout from 'Components/Templates/UnloggedLayout';
import React from 'react';
import { content } from './content';

import { Container } from './styles';
import Step1 from './steps/step1';
import { useRegisterStore } from 'store/useRegisterStore';
import Step2 from './steps/step2';
import Step3 from './steps/step3';

const Register: React.FC = () => {
    const { step } = useRegisterStore();

    const stepsComponents: Record<number, React.ReactNode> = {
        1: <Step1 />,
        2: <Step2 />,
        3: <Step3 />
    };

    return (
        <UnloggedLayout backgroundImage={content.image}>
            <Container>{stepsComponents[step]}</Container>
        </UnloggedLayout>
    );
};

export default Register;
