import React from 'react';
import { GobackProps } from './types';
import { Container } from './styles';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useTheme } from 'styled-components';

const Goback: React.FC<GobackProps> = ({
    size = 25,
    title = 'Voltar',
    handleClick = () => null
}) => {
    const theme = useTheme();
    return (
        <Container size={size}>
            <p onClick={handleClick}>
                <AiOutlineArrowLeft
                    size={size}
                    color={theme.colors.brand.pure}
                />{' '}
                {title}
            </p>
        </Container>
    );
};

export default Goback;
