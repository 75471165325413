import React, { useCallback, useState } from 'react';
import { ButtonLogout, Container, Header, MenuMobileItem } from './styles';
import { ReactComponent as DeetzIcon } from 'assets/icons/DEETZ.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { FiMenu, FiX } from 'react-icons/fi';
import { BiExit } from 'react-icons/bi';

type MenuMobileProps = {
    content: {
        label: string;
        path: string;
    }[];
};
const MenuMobile: React.FC<MenuMobileProps> = ({ content }) => {
    const { logout } = useAuthStore();
    const [menuOpened, setMenuOpened] = useState(false);
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        logout();
        setMenuOpened(false);
        navigate('/login');
    }, [logout, navigate]);

    return (
        <Header>
            <DeetzIcon />

            {menuOpened ? (
                <FiX size={20} onClick={() => setMenuOpened(false)} />
            ) : (
                <FiMenu size={20} onClick={() => setMenuOpened(true)} />
            )}

            <Container opened={menuOpened}>
                {content.map((item) => (
                    <MenuMobileItem key={item.path}>
                        <NavLink
                            to={`/${item.path}`}
                            replace={true}
                            preventScrollReset={true}
                            onClick={() => setMenuOpened(false)}
                            relative={'route'}
                        >
                            {item.label}
                        </NavLink>
                    </MenuMobileItem>
                ))}
                <MenuMobileItem>
                    <ButtonLogout onClick={handleLogout}>
                        Sair <BiExit size={20} />
                    </ButtonLogout>
                </MenuMobileItem>
            </Container>
        </Header>
    );
};

export default MenuMobile;
