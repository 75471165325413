import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from './styles';

const Disclaimer: React.FC<{ text: string }> = ({ text }) => {
    return (
        <Container>
            <ReactMarkdown>{text}</ReactMarkdown>
        </Container>
    );
};

export default Disclaimer;
