import React, { useCallback, useEffect, useState } from 'react';
import { Card, Container } from './styles';
import { useDashboard } from 'store';
import { DashboardAttendantsPerformance } from 'services/dashboard/types';
import { useNavigate } from 'react-router-dom';

type GeneralPerformanceProps = {
    period: string;
};

const AttendancesPerformance: React.FC<GeneralPerformanceProps> = ({
    period
}) => {
    const { getAttendantPerformance } = useDashboard();
    const [data, setData] = useState<DashboardAttendantsPerformance[]>([]);
    const navigate = useNavigate();

    const handleAttendanceData = useCallback(async () => {
        const result = await getAttendantPerformance({ period });

        setData(result);
    }, [getAttendantPerformance, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <h3>Desempenho dos atendentes</h3>

            {data.map((item) => (
                <Card
                    key={item.id}
                    online={item.isActived}
                    onClick={() => navigate(`${item.id}`)}
                >
                    <img src={item.avatar} alt={item.firstName} />
                    <p>
                        {`${item.firstName} ${item.lastName}`}
                        <small>{`Conversas: ${item.totalTickets}`}</small>
                    </p>
                    <span>{item.isActived ? 'Online' : 'Offline'}</span>
                </Card>
            ))}
        </Container>
    );
};

export default AttendancesPerformance;
