/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutomation } from '../context';
import {
    DeleteButton,
    NodeContainer,
    handleStyleBottom,
    handleStyleTop
} from './styles';
import { BiTrash } from 'react-icons/bi';
import { Handle, Position } from 'reactflow';
import Input from 'Components/Atoms/Input';

export const MessageQuestion = memo(({ id, data }: any) => {
    const { setValue } = useFormContext();
    const { setNodes, setEdges, nodes } = useAutomation();
    const { register } = useFormContext();
    const isTheFirstNode = nodes.findIndex((item) => item.id === id) === 0;

    const handleRemoveNode = () => {
        const children = nodes
            .filter((item) => item.parentId === id)
            .map((item) => item.id);
        setNodes((old) =>
            old.filter((item) => item.id !== id && item.parentId !== id)
        );
        setEdges((old) =>
            old.filter(
                (item) =>
                    item.target !== id &&
                    item.source !== id &&
                    !children.includes(item.target) &&
                    !children.includes(item.source)
            )
        );
        setValue(`${id}.type`, undefined);
        setValue(`${id}.message`, undefined);
    };

    return (
        <NodeContainer>
            <DeleteButton onClick={handleRemoveNode}>
                <BiTrash size={24} />
            </DeleteButton>

            <>
                {!isTheFirstNode && (
                    <Handle
                        type="target"
                        position={Position.Top}
                        style={handleStyleTop}
                    />
                )}

                <h1>Mensagem com Pergunta</h1>
                <Input
                    id={`${id}.message`}
                    name={`${id}.message`}
                    label="Texto"
                    defaultValue={data.message}
                    onChange={(e) => (data.message = e.currentTarget.value)}
                    register={register}
                />

                <Handle
                    type="source"
                    position={Position.Bottom}
                    style={handleStyleBottom}
                    isConnectable={true}
                    id={`${id}-1`}
                />
            </>
        </NodeContainer>
    );
});
