import React, { useCallback, useEffect, useState } from 'react';

import { Container, Card, Title, CardGroup } from './styles';
import { useDashboard } from 'store';
import { DashboardAttendances } from 'services/dashboard/types';

type AttendancesProps = {
    period: string;
};

const Attendances: React.FC<AttendancesProps> = ({ period }) => {
    const { getAttendances } = useDashboard();
    const [data, setData] = useState<
        {
            id: keyof DashboardAttendances;
            label: string;
            color: string;
            value: number;
        }[]
    >([
        { id: 'opened', label: 'Abertos', color: '#f2f2f2', value: 0 },
        {
            id: 'inAttendance',
            label: 'Em atendimento',
            color: '#E8EFFF',
            value: 0
        },
        {
            id: 'underAnalysis',
            label: 'Em análise',
            color: '#EFE8FF',
            value: 0
        },
        { id: 'blocked', label: 'Bloqueado/Imp', color: '#FFE8EE', value: 0 },
        { id: 'conclused', label: 'Concluídos', color: '#E8F9FF', value: 0 }
    ]);

    const handleAttendanceData = useCallback(async () => {
        const result = await getAttendances({ period });

        if (result) {
            setData((old) =>
                old.map((item) => ({
                    ...item,
                    value: result[item.id] || 0
                }))
            );
        }
    }, [getAttendances, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <Title>Atendimentos</Title>
            <CardGroup>
                {data.map((item) => (
                    <Card key={item.id} color={item.color}>
                        <p>{item.label}</p>
                        <span>{item.value}</span>
                    </Card>
                ))}
            </CardGroup>
        </Container>
    );
};

export default Attendances;
