/* eslint-disable react/display-name */
import React from 'react';
import { useTable, Column, HeaderGroup, Row } from 'react-table';
import { v4 as uuid } from 'uuid';
import {
    ActionsGroup,
    ChannelColumn,
    GroupColumn,
    StatusColumn,
    TableBodyContainer,
    TableContainer,
    TableHeadContainer,
    TablePagination
} from './styles';
import SocialIcon from 'Components/Atoms/SocialIcon';
import { socialLegends, socialType, statusLegends } from 'Constant';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineInboxStack } from 'react-icons/hi2';
import ReactPaginate from 'react-paginate';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';

function TableRowSelection({
    columns,
    data,
    actions,
    handleNextPage,
    totalItems = 0,
    pageIndex = 1
}: {
    columns: Column[];
    data: object[];
    totalItems?: number;
    pageIndex?: number;
    handleNextPage?: (page: number) => void;
    actions?: {
        view?: (id: number) => void;
        edit?: (id: number) => void;
        delete?: (id: number) => void;
    };
}) {
    const rowsPerPage = 15;
    const totalPages = Math.ceil(totalItems / rowsPerPage);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    }: any = useTable({
        columns,
        data
    });

    const renderEspecialColumns = (cell: object | any, key: string) => {
        switch (cell.column.type) {
            case 'status':
                return (
                    <td
                        {...cell.getCellProps()}
                        key={key}
                        style={{
                            textAlign: cell.column?.align || 'left',
                            width: cell.column?.width || '100%'
                        }}
                    >
                        {cell.value && (
                            <StatusColumn
                                color={
                                    statusLegends[
                                        cell.value as keyof typeof statusLegends
                                    ]?.color || '#000'
                                }
                            >
                                {
                                    statusLegends[
                                        cell.value as keyof typeof statusLegends
                                    ]?.label
                                }
                            </StatusColumn>
                        )}
                    </td>
                );
            case 'channel':
                return (
                    <td
                        {...cell.getCellProps()}
                        key={key}
                        style={{
                            textAlign: cell.column?.align || 'left',
                            width: cell.column?.width || '100%'
                        }}
                    >
                        <ChannelColumn>
                            <SocialIcon
                                width="16px"
                                height="16px"
                                socialMedia={cell.value}
                            />{' '}
                            {socialLegends[cell.value as socialType]}
                        </ChannelColumn>
                    </td>
                );
            case 'group':
                return (
                    <td
                        {...cell.getCellProps()}
                        key={key}
                        style={{
                            textAlign: cell.column?.align || 'left',
                            width: cell.column?.width || '100%'
                        }}
                    >
                        <GroupColumn key={uuid()} group={cell.value}>
                            {cell.render('Cell')}
                        </GroupColumn>
                    </td>
                );
            case 'actions':
                return (
                    <td
                        {...cell.getCellProps()}
                        key={uuid()}
                        style={{
                            textAlign: cell.column?.align || 'left',
                            width: cell.column?.width || '100%'
                        }}
                    >
                        <ActionsGroup>
                            {Boolean(actions?.edit) && (
                                <BiEditAlt
                                    size={16}
                                    onClick={() =>
                                        actions?.edit &&
                                        actions?.edit(cell.row.original.id)
                                    }
                                />
                            )}
                            {Boolean(actions?.view) && (
                                <HiOutlineInboxStack
                                    size={16}
                                    onClick={() =>
                                        actions?.view &&
                                        actions?.view(cell.row.original.id)
                                    }
                                />
                            )}
                            {Boolean(actions?.delete) && (
                                <AiOutlineDelete
                                    size={16}
                                    onClick={() =>
                                        actions?.delete &&
                                        actions?.delete(cell.row.original.id)
                                    }
                                />
                            )}
                        </ActionsGroup>
                    </td>
                );

            default:
                return (
                    <td
                        {...cell.getCellProps()}
                        key={uuid()}
                        style={{
                            textAlign: cell.column?.align || 'left',
                            width: cell.column?.width || '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '250px'
                        }}
                    >
                        {cell.render('Cell')}
                    </td>
                );
        }
    };

    return (
        <>
            <TableContainer {...getTableProps()}>
                <TableHeadContainer>
                    {headerGroups.map((headerGroup: HeaderGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={uuid()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} key={uuid()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </TableHeadContainer>

                <TableBodyContainer {...getTableBodyProps()}>
                    {rows.map((row: Row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={uuid()}>
                                {row.cells.map((cell: any) => {
                                    return renderEspecialColumns(cell, uuid());
                                })}
                            </tr>
                        );
                    })}
                </TableBodyContainer>
            </TableContainer>

            <TablePagination>
                <ReactPaginate
                    breakLabel="..."
                    previousLabel={<FiChevronLeft />}
                    nextLabel={<FiChevronRight />}
                    onPageChange={({ selected }) => {
                        const currentPage = selected + 1;
                        handleNextPage && handleNextPage(currentPage);
                    }}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    renderOnZeroPageCount={null}
                    forcePage={pageIndex - 1}
                    pageClassName={'page'}
                    previousClassName={'control'}
                    nextClassName={'control'}
                    breakClassName={'break-me'}
                    containerClassName={'container'}
                    activeClassName={'active'}
                />
            </TablePagination>
        </>
    );
}

export default TableRowSelection;
