import React, { useEffect } from 'react';

import { Container } from './styles';
import Box from './Box';
import ContactDetails from './ContactDetails';
import ContactMessages from './ContactMessages';
import { useContactStore } from 'store/useContacts';
import { useParams } from 'react-router-dom';
import LoggedLayout from 'Components/Templates/LoggedLayout';
import Groups from './Groups';

const Messages: React.FC = () => {
    const { contactId } = useParams();

    const { getContactDetails, getContactMessages, clearCurrentContact } =
        useContactStore();

    const handleOpenContact = (id: number) => {
        getContactDetails({ contactId: id, saveState: true });
        getContactMessages(id);
    };

    useEffect(() => {
        if (contactId) {
            handleOpenContact(Number(contactId));
        }

        return () => {
            clearCurrentContact();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId]);

    return (
        <LoggedLayout>
            <Container>
                <Box style={{ gridArea: 'details' }}>
                    <ContactDetails />
                </Box>
                <Box style={{ gridArea: 'messages' }}>
                    <ContactMessages />
                </Box>
                <Box transparent padding="0" style={{ gridArea: 'groups' }}>
                    <Groups />
                </Box>
            </Container>
        </LoggedLayout>
    );
};

export default Messages;
