import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > svg {
        margin-right: 5px;
    }

    & > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #666666;
    }
`;
