import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Title = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: ${({ theme }) => theme.lineHeight.md};
    letter-spacing: -0.9px;
    padding-top: 10%;
    color: #3c4257;

    ${customMediaQuery.greaterThan('md')`
    font-size: 36px;
    line-height: ${({ theme }) => theme.lineHeight.lg};
    font-size: ${({ theme }) => theme.fontSize.md};
    letter-spacing: initial;
  `}
`;

export const RuleList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const RuleItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;

    & > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #07122f;
    }

    & > svg {
        padding-right: 10px;
    }
`;
