import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 3.4rem;
    color: #3c4257;
    margin-bottom: 2.4rem;
`;

export const FormTitle = styled.p`
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 100%;
    color: #292929;
    margin-bottom: 1.2rem;
`;

export const SocialMediaTitle = styled.p`
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;
    margin-bottom: 2.4rem;
    color: #07122f;
`;

export const AnySocialMedia = styled.div`
    margin-top: 24px;

    & > label {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 100%;
        color: #292929;
        padding-left: 10px;
    }
`;
