import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-rows: 6rem calc(100vh - 6rem);
`;

export const Header = styled.nav`
    width: 100%;
    height: 6rem;
    padding: 20px 28px;
    display: flex;
    background: #ffffff;
    align-items: center;
    justify-content: space-between;
`;

export const Body = styled.div<{
    backgroudInverted?: boolean;
    padding?: string;
}>`
    width: 100%;
    min-height: 100%;
    background: ${({ backgroudInverted }) =>
        backgroudInverted ? '#ffffff' : '#f9f9f9'};
    padding: ${({ padding }) => (padding ? padding : '23px 28px')};
    box-sizing: border-box;
    overflow: auto;

    @media (max-width: 1024px) {
        padding: ${({ padding }) => (padding ? padding : '13px 18px')};
    }
`;
