import React from 'react';
import { ReactComponent as Whatsapp } from 'assets/icons/whatsapp-icon.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram-icon.svg';
import { ReactComponent as Messenger } from 'assets/icons/messenger-icon.svg';
import { ReactComponent as SMS } from 'assets/icons/sms-icon.svg';
import { ReactComponent as Telegram } from 'assets/icons/telegram-icon.svg';
import { Container, Item } from './styles';

const SocialCheck: React.FC<{ register: any; name: string }> = ({
    register,
    name
}) => {
    const socialMedias = [
        'whatsapp',
        'instagram',
        'sms',
        'telegram',
        'messenger'
    ];
    const socialMediasLogo = {
        whatsapp: <Whatsapp />,
        instagram: <Instagram />,
        sms: <SMS />,
        telegram: <Telegram />,
        messenger: <Messenger />
    };

    return (
        <Container>
            {socialMedias.map((socialMedia) => (
                <Item key={socialMedia}>
                    <input
                        type="checkbox"
                        id={socialMedia}
                        value={socialMedia}
                        {...register(name)}
                    />

                    <label htmlFor={socialMedia}>
                        {
                            socialMediasLogo[
                                socialMedia as keyof typeof socialMediasLogo
                            ]
                        }
                    </label>
                </Item>
            ))}
        </Container>
    );
};

export default SocialCheck;
