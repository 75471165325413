import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 3rem;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 2rem;
    right: 3rem;
    background: none;
    border: none;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    & > h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 100%;
        text-align: center;
        color: #292929;
    }

    & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        text-align: center;
        color: #666666;
        margin-top: 1.5rem;
        max-width: 26rem;
    }
`;

export const CardList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    margin: 1.6rem 0;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;

    width: 30rem;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 1.6rem;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background: #cccccc;
    }

    h3 {
        margin-top: 1.3rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1c1c1c;
    }

    p {
        margin-top: 0.4rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #666666;
    }
`;
