import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding-bottom: 1rem;
    color: #292929;

    svg {
        margin-left: 1.2rem;
    }
`;

export const Card = styled.div<{
    color: string;
}>`
    background-color: ${({ color }) => color};
    width: 17rem;
    height: 11rem;
    padding: 2.4rem;
    border-radius: 1.6rem;
    box-sizing: border-box;
    position: relative;

    & > p {
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1c1c1c;
        padding-bottom: 0.8rem;
    }

    & > span {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3.6rem;
        color: #1c1c1c;
    }

    & > small {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #1c1c1c;
        position: absolute;
        right: 2.4rem;
        top: 60%;
        transform: translateY(-50%);
    }
`;

export const CardGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
`;
