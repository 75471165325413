import React, { useCallback, useState } from 'react';
import { Container, Description, Resend, Title, Wrapper } from './styles';
import UnloggedLayout from 'Components/Templates/UnloggedLayout';
import { content } from './content';
import Goback from 'Components/Atoms/Goback';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';

const ForgotPassword: React.FC = () => {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { forgotPasswordAction } = useAuthStore();

    const schema = z.object({
        email: z.string().email('Email inválido!').min(1, 'Campo obrigatório')
    });

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid }
    } = useForm<{
        email: string;
    }>({
        resolver: zodResolver(schema)
    });

    const onResendEmail = async () => {
        setLoading(true);
        await forgotPasswordAction({
            email: getValues().email
        });
        setLoading(false);
    };

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const result = await forgotPasswordAction({
            email: data.email
        });
        if (result) {
            setStep(2);
        }
        setLoading(false);
    });

    const goToHome = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    const component = {
        1: (
            <Container>
                <Title>{content.step1.title}</Title>
                <Description>{content.step1.description}</Description>
                <form onSubmit={onSubmit}>
                    <Input
                        name="email"
                        id="email"
                        type="email"
                        error={errors.email?.message}
                        label={content.step1.email.label}
                        placeholder={content.step1.email.placeholder}
                        register={register('email')}
                    />

                    <Button
                        type="submit"
                        marginVertical="34px"
                        size="SMALL"
                        rounded
                        disabled={loading || !isValid}
                        loading={loading}
                    >
                        {content.step1.submit}
                    </Button>
                </form>
            </Container>
        ),
        2: (
            <Container>
                <Title>{content.step2.title}</Title>
                <Description>
                    {content.step2.description.replace(
                        '{{email}}',
                        getValues().email
                    )}
                </Description>
                <Resend>
                    <p>{content.step2.resend.text}</p>{' '}
                    <button
                        type="button"
                        onClick={onResendEmail}
                        disabled={loading}
                    >
                        {content.step2.resend.submit}
                    </button>
                </Resend>

                <Button
                    type="submit"
                    marginVertical="34px"
                    size="SMALL"
                    rounded
                    onClick={goToHome}
                >
                    {content.step2.submit}
                </Button>
            </Container>
        )
    };

    return (
        <UnloggedLayout backgroundImage={content.image}>
            <Wrapper>
                <Goback title={content.back} size={14} handleClick={goToHome} />
                {component[step as keyof typeof component]}
            </Wrapper>
        </UnloggedLayout>
    );
};

export default ForgotPassword;
