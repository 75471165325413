import React, { useEffect, useState } from 'react';
import { ButtonSort, Container, Header, InputWrapper } from './styles';
import Input from 'Components/Atoms/Input';
import { RiSearchLine } from 'react-icons/ri';
import { BiSortAlt2 } from 'react-icons/bi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ChatContactList from 'Components/Organisms/Chat/ChatContactList';
import { useContactStore } from 'store/useContacts';
import Loading from 'Components/Atoms/Loading';
import { content } from '../content';
import { Channels } from 'services/contacts/types';
import { useWebSocketStore } from 'store';

const ContactList: React.FC = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const webSocket = useWebSocketStore();
    const {
        closedContacts,
        openedContacts,
        loadingContacts,
        getContactDetails,
        getContactMessages,
        reverseContactsLists,
        getTeamMembers,
        clearCurrentContact
    } = useContactStore();

    const handleOpenContact = ({
        contactId,
        channelCommunication
    }: {
        contactId: number;
        channelCommunication: Channels;
    }) => {
        getContactDetails({
            contactId: contactId,
            saveState: true,
            channelCommunication
        });
        getContactMessages(contactId);
        getTeamMembers();
    };

    useEffect(() => {
        if (currentTab === 0) {
            // getOpenedContacts();
            webSocket.getChatListOpen(['opened']);
        } else {
            // getClosedContacts();
            webSocket.getChatListClose(['conclused', 'botAttendance']);
        }
        return () => {
            clearCurrentContact();
            webSocket.clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    return (
        <Container>
            <Header>
                <p>{content.contactList.title}</p>
                <InputWrapper>
                    <Input
                        name="search"
                        id="search"
                        placeholder={content.contactList.search.placeholder}
                        leftIcon={() => <RiSearchLine />}
                    />

                    {/* <ButtonSort onClick={() => reverseContactsLists()}>
                        <BiSortAlt2 size={'20px'} />
                    </ButtonSort> */}
                </InputWrapper>
            </Header>

            <Tabs defaultIndex={0} onSelect={(index) => setCurrentTab(index)}>
                <TabList>
                    {content.contactList.tabs.map((item) => (
                        <Tab key={item}>{item}</Tab>
                    ))}
                </TabList>

                <TabPanel>
                    {webSocket.loadingContacts ? (
                        <Loading />
                    ) : (
                        <>
                            <ChatContactList
                                openContact={handleOpenContact}
                                contacts={webSocket.chatList.contactsResponse}
                            />
                        </>
                    )}
                </TabPanel>
                <TabPanel>
                    {webSocket.loadingContacts ? (
                        <Loading />
                    ) : (
                        <>
                            <ChatContactList
                                openContact={handleOpenContact}
                                disabled
                                contacts={
                                    webSocket.chatListClose.contactsResponse
                                }
                            />
                        </>
                    )}
                </TabPanel>
            </Tabs>
        </Container>
    );
};

export default ContactList;
