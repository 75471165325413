import React from 'react';
import { Container, Label, ListValues, Radio } from './styles';

type InputRadioProps = {
    id: string;
    name: string;
    options: {
        label: string;
        value: string | number;
    }[];
    label?: string;
    register?: any;
    column?: boolean;
    alignment?: string;
    defaultValue?: string | number;
};

const InputRadio: React.FC<InputRadioProps> = ({
    id,
    name,
    options,
    label,
    register,
    column,
    alignment,
    defaultValue
}) => {
    return (
        <Container>
            {label && <Label id={`${id}-label`}>{label}</Label>}
            <ListValues role="radiogroup" column={column} alignment={alignment}>
                {options.map((item) => {
                    return (
                        <Radio key={item.label} role="radio">
                            <input
                                type="radio"
                                id={item.value}
                                name={name}
                                value={item.value}
                                checked={item.value === defaultValue}
                                {...register(name)}
                            />

                            <label htmlFor={item.value.toString()}>
                                {item.label}
                            </label>
                        </Radio>
                    );
                })}
            </ListValues>
        </Container>
    );
};

export default InputRadio;
