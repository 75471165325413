import styled from 'styled-components';

export const Container = styled.div`
    background: #f9f9f9;
    width: 100%;
    border-radius: 8px;
    padding: 10px 20px;

    @media (max-width: 1024px) {
        padding: 10px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.8rem;
    margin-bottom: 12px;

    & > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #292929;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 1280px) {
        flex-direction: column;
    }
`;

export const GraphSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 4rem;
    margin-right: 1.5rem;
`;
