import { toast } from 'react-toastify';
import AuthService from 'services/auth';
import TokenService from 'services/token';
import {
    AuthRequest,
    AuthResponse,
    ForgotPasswordRequest,
    RegisterRequest,
    RegisterResponse,
    ResetPasswordRequest,
    UserModel
} from 'services/auth/types';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface createAuthSliceProps {
    userData?: UserModel | null;
    accessToken: string;
    signInAction: (data: AuthRequest) => Promise<boolean>;
    signUpAction: (data: RegisterRequest) => Promise<RegisterResponse | null>;
    forgotPasswordAction: (data: ForgotPasswordRequest) => Promise<boolean>;
    resetPasswordAction: (data: ResetPasswordRequest) => Promise<boolean>;
    updateProfile: (data: FormData) => void;
    logout: () => void;
}

export const useAuthStore = create<createAuthSliceProps>()(
    persist(
        (set, get) => ({
            userData: null,
            accessToken: '',
            logout: () => {
                set(() => ({ userData: null, accessToken: '' }));
                TokenService.removeAuthData();
                toast.info('Logout feito com sucesso!');
            },
            updateProfile: async (data: FormData) => {
                try {
                    const userId = get().userData?.id as number;
                    const response = await AuthService.updateProfile(
                        userId,
                        data
                    );

                    if (response?.id) {
                        set((old) => ({
                            userData: {
                                ...old.userData,
                                ...response
                            },
                            accessToken: old.accessToken
                        }));
                    }
                    toast.success('Perfil atualizado com sucesso!');
                } catch (error: any) {
                    toast.error(error?.message || error, { toastId: 'auth' });
                    return null;
                }
            },
            signInAction: async (data: AuthRequest) => {
                try {
                    const response = (await AuthService.login(
                        data
                    )) as AuthResponse;
                    const completeData = {
                        ...response.user,
                        company: response.company
                    } as UserModel;

                    if (response?.accessToken) {
                        set(() => ({
                            userData: completeData,
                            accessToken: response.accessToken
                        }));

                        TokenService.setAuthData(response);
                    }
                    return true;
                } catch (error: any) {
                    set(() => ({ userData: null, accessToken: '' }));
                    toast.error(error?.message || error, { toastId: 'auth' });
                    return false;
                }
            },
            signUpAction: async (data: RegisterRequest) => {
                try {
                    const result = await AuthService.register(data);
                    toast.success('Usuário cadastrado com sucesso!');
                    return result as RegisterResponse;
                } catch (error: any) {
                    toast.error(error?.message || error, { toastId: 'auth' });
                    return null;
                }
            },
            forgotPasswordAction: async (data: ForgotPasswordRequest) => {
                try {
                    await AuthService.sendRecoveryToEmail(data);
                    toast.success('Email enviado com sucesso!');
                    return true;
                } catch (error: any) {
                    toast.error(error?.message || error, { toastId: 'auth' });
                    return false;
                }
            },
            resetPasswordAction: async (data: ResetPasswordRequest) => {
                try {
                    await AuthService.resetPassword(data);
                    toast.success('Senha resetada com sucesso!');
                    return true;
                } catch (error: any) {
                    toast.error(error?.message || error, { toastId: 'auth' });
                    return false;
                }
            }
        }),
        {
            name: 'deetz-auth-store',
            storage: createJSONStorage(() => sessionStorage)
        }
    )
);
