import React, { useCallback, useEffect } from 'react';
import { Container, Header, Title, CloseButton, Row, Wrapper } from './styles';
import { MdClose } from 'react-icons/md';
import Input from 'Components/Atoms/Input';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from 'Components/Atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { modalContent } from './content';
import { useSettingsStore } from 'store';

const ModalGroupForm: React.FC = () => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const schema = z.object({
        name: z.string().min(1, 'Campo obrigatório')
    });

    const { fields, newTitle, editTitle, actions } = modalContent;
    const { groups, updateGroup, createGroup } = useSettingsStore();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<{
        name: string;
    }>({
        resolver: zodResolver(schema),
        mode: 'onChange'
    });

    const onSubmit = handleSubmit(async (data) => {
        if (groupId) {
            updateGroup(Number(groupId), data);
        } else {
            createGroup(data);
        }
        navigate(-1);
    });

    const recoveryGroupData = useCallback(
        async (groupId: number) => {
            const groupData = groups.find((item) => item.id === groupId);

            reset({
                name: groupData?.name
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reset]
    );

    useEffect(() => {
        if (groupId) {
            recoveryGroupData(Number(groupId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    return (
        <Wrapper>
            <Container>
                <Header>
                    <Title>{groupId ? editTitle : newTitle}</Title>
                    <CloseButton onClick={() => navigate(-1)}>
                        <MdClose size={24} />
                    </CloseButton>
                </Header>

                <form onSubmit={onSubmit}>
                    <Row>
                        <Input
                            name="name"
                            id="name"
                            label={fields.name.label}
                            placeholder={fields.name.placeholder}
                            register={register('name')}
                            error={errors.name?.message}
                        />
                    </Row>

                    <Row>
                        <Button
                            outline
                            rounded
                            size={'SMALL'}
                            onClick={() => navigate(-1)}
                        >
                            {actions.cancel}
                        </Button>
                        <Button type="submit" rounded size={'SMALL'}>
                            {actions.save}
                        </Button>
                    </Row>
                </form>
            </Container>
        </Wrapper>
    );
};

export default ModalGroupForm;
