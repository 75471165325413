import { toast } from 'react-toastify';
import { AutomationBody, AutomationResponse } from 'services/automation/types';
import AutomationService from 'services/automation';

import { create } from 'zustand';

export interface createAutomationSliceProps {
    automations: AutomationResponse[];
    getAutomations: () => void;
    getAutomationById: (id: number) => Promise<AutomationBody | undefined>;
    saveAutomation: (
        data: AutomationBody
    ) => Promise<AutomationBody | undefined>;
    updateAutomation: (
        data: AutomationBody
    ) => Promise<AutomationBody | undefined>;
}

export const useAutomationStore = create<createAutomationSliceProps>()(
    (set, get) => ({
        automations: [],
        getAutomations: async () => {
            try {
                const result = await AutomationService.getAutomations();
                set({ automations: result });
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'automation' });
            }
        },
        getAutomationById: async (id) => {
            try {
                const result = await AutomationService.findAutomation(id);
                return result;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'automation' });
                return undefined;
            }
        },
        saveAutomation: async (data) => {
            try {
                const result = await AutomationService.createAutomation(data);
                get().getAutomations();
                toast.success('Automação cadastrada com sucesso!', {
                    toastId: 'automation'
                });
                return result;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'automation' });
                return undefined;
            }
        },
        updateAutomation: async (data) => {
            try {
                const result = await AutomationService.updateAutomation(data);
                get().getAutomations();
                toast.success('Automação editada com sucesso!', {
                    toastId: 'automation'
                });
                return result;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'automation' });
                return undefined;
            }
        }
    })
);
