import React, { useCallback, useEffect, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BsChevronLeft } from 'react-icons/bs';
import LoggedLayout from 'Components/Templates/LoggedLayout';
import InputRadio from 'Components/Atoms/InputRadio';
import {
    CodeMirrorWrapper,
    Container,
    FormAnswersContainer,
    FormButtonContainer,
    FormFooter,
    FormListActionGroup,
    FormListActionRow,
    FormListContainer,
    GoBack,
    ListTitle,
    MainContainer,
    PreviewContainer,
    Row,
    TagList,
    Title
} from './styles';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/handlebars/handlebars';
import Input from 'Components/Atoms/Input';
import Select from 'Components/Atoms/Select';
import Button from 'Components/Atoms/Button';
import { FiTrash } from 'react-icons/fi';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import MessagePreview from '../MessagePreview';
import { MessageModelRequest } from 'services/settings/types';
import { useSettingsStore } from 'store';
import InputPhone from 'Components/Atoms/InputPhone';

const Message: React.FC = () => {
    const navigate = useNavigate();
    const { postMessageModels, updateMessageModels, getMessageModelDetails } =
        useSettingsStore();
    const [loading, setLoading] = useState(false);
    const { messageId } = useParams();
    const [cursor, setCursor] = useState(0);
    const {
        control,
        setValue,
        getValues,
        register,
        watch,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm<MessageModelRequest>({
        defaultValues: {
            name: '',
            phone: '',
            messageBody: {
                options: [{ typeAction: '', label: '' }]
            },
            type: 'button'
        }
    });
    const {
        fields: ListActionsFields,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'messageBody.options',
        rules: { minLength: 1 }
    });
    const tags = [
        { code: 'firstName', label: '{{ firstname }}' },
        { code: 'lastName', label: '{{ lastname }}' },
        { code: 'email', label: '{{ email }}' },
        { code: 'companyName', label: '{{ company }}' },
        { code: 'phone', label: '{{ phonenumber }}' },
        { code: 'role', label: '{{ jobtitle }}' }
    ];

    const handleAddTags = useCallback(
        (tag: { code: string; label: string }) => {
            const text = getValues('text').split('');
            text.splice(cursor, 0, ` ${tag.label} `);
            const newText = text.join('');
            setValue('text', newText);
        },
        [getValues, setValue, cursor]
    );

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        if (messageId) {
            const result = await updateMessageModels(Number(messageId), data);
            if (result) {
                navigate(-1);
            }
        } else {
            const result = await postMessageModels(data);
            if (result) {
                navigate(-1);
            }
        }
        setLoading(false);
    });

    const FormButton = () => (
        <FormButtonContainer>
            <Input
                id="messageBody.label"
                name="messageBody.label"
                type="text"
                placeholder="Label"
                register={register('messageBody.label')}
            />

            <Select
                name={'messageBody.typeAction'}
                placeholder="Selecione um tipo"
                value={getValues('messageBody.typeAction')}
                onChange={(e) =>
                    setValue('messageBody.typeAction', e.currentTarget.value)
                }
                options={[]}
                control={control}
            />

            <Input
                id="messageBody.link"
                name="messageBody.link"
                type="text"
                placeholder="Endereço"
                register={register('messageBody.link')}
            />
        </FormButtonContainer>
    );

    const FormList = () => (
        <FormListContainer>
            <Input
                id="messageBody.title"
                name="messageBody.title"
                type="text"
                placeholder="Nome da lista"
                register={register('messageBody.title')}
                width="30rem"
            />

            <FormListActionGroup>
                <ListTitle>Tipo de ação</ListTitle>
                {ListActionsFields.map((field, index) => (
                    <FormListActionRow key={field.id + index}>
                        <Input
                            id={`messageBody.options.${index}.typeAction`}
                            name={`messageBody.options.${index}.typeAction`}
                            type="text"
                            placeholder="Titulo de ação"
                            register={register(
                                `messageBody.options.${index}.typeAction`
                            )}
                        />
                        <Input
                            id={`messageBody.options.${index}.label`}
                            name={`messageBody.options.${index}.label`}
                            type="text"
                            placeholder="Descrição"
                            register={register(
                                `messageBody.options.${index}.label`
                            )}
                        />
                        {index !== 0 && (
                            <button type="button" onClick={() => remove(index)}>
                                <FiTrash size={20} />
                            </button>
                        )}
                    </FormListActionRow>
                ))}
            </FormListActionGroup>
            <Button
                rounded
                outline
                size="SMALL"
                width="18rem"
                type="button"
                onClick={() => append({ typeAction: '', label: '' })}
            >
                <AiOutlinePlus size={16} /> Adicionar item
            </Button>
        </FormListContainer>
    );

    const FormAnswers = () => <FormAnswersContainer />;

    const formFields = {
        list: <FormList />,
        button: <FormButton />,
        quickAnswers: <FormAnswers />
    };

    const recoveryMessageDetails = useCallback(async (messageId: number) => {
        const message = await getMessageModelDetails(messageId);
        reset({
            name: message?.name,
            phone: message?.phone,
            type: message?.type,
            text: message?.quickAnswers,
            messageBody: {
                label: message?.button.label,
                link: message?.button.link,
                typeAction: message?.button.typeAction,
                title: message?.list.title,
                options: message?.list.options
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (messageId) {
            recoveryMessageDetails(Number(messageId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageId]);

    return (
        <LoggedLayout>
            <Container>
                <MainContainer>
                    <GoBack onClick={() => navigate(-1)}>
                        <BsChevronLeft size={16} />
                        <p>Voltar</p>
                    </GoBack>

                    <Title>Mensagem</Title>

                    <form onSubmit={onSubmit}>
                        <Row>
                            <Input
                                name="name"
                                id="name"
                                label="Nome"
                                register={register('name')}
                                error={errors.name?.message}
                            />
                        </Row>
                        <Row>
                            <InputPhone
                                name="phone"
                                id="phone"
                                control={control}
                                label="Telefone"
                                error={errors.phone?.message}
                            />
                        </Row>
                        <CodeMirrorWrapper>
                            <TagList>
                                {tags.map((tag) => (
                                    <li
                                        key={tag.code}
                                        onClick={() => handleAddTags(tag)}
                                    >
                                        {tag.label}
                                    </li>
                                ))}
                            </TagList>
                            <Controller
                                render={({ field }) => (
                                    <CodeMirror
                                        onBeforeChange={(
                                            editor,
                                            data,
                                            value
                                        ) => {
                                            //display, doc, options
                                            field.onChange(value);
                                        }}
                                        value={field.value}
                                        onCursor={(e) =>
                                            setCursor(
                                                e.doc.sel.ranges[0].head.ch
                                            )
                                        }
                                        options={{
                                            mode: {
                                                name: 'handlebars',
                                                base: 'text/html',
                                                lineNumbers: false
                                            }
                                        }}
                                    />
                                )}
                                control={control}
                                name={'text'}
                            ></Controller>
                        </CodeMirrorWrapper>
                        <InputRadio
                            label="Tipo de ação"
                            id={'type'}
                            name={'type'}
                            key={watch('type')}
                            defaultValue={watch('type')}
                            options={[
                                {
                                    label: 'Botão',
                                    value: 'button'
                                },
                                {
                                    label: 'Lista',
                                    value: 'list'
                                }
                                // {
                                //     label: 'Respostas rápidas',
                                //     value: 'answers'
                                // }
                            ]}
                            register={register}
                        />

                        {formFields[watch('type')]}

                        <FormFooter>
                            <Button
                                type="submit"
                                size="SMALL"
                                marginVertical="1rem"
                                width="31rem"
                                loading={loading}
                                disabled={loading}
                            >
                                Salvar e enviar para a análise
                            </Button>
                            <p>A validação é feita exclusivamente pela META®</p>
                        </FormFooter>
                    </form>
                </MainContainer>
                <PreviewContainer>
                    <MessagePreview control={control} />
                </PreviewContainer>
            </Container>
        </LoggedLayout>
    );
};

export default Message;
