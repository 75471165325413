/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import RoutesComponent from 'Routes';
import React from 'react';
import GlobalStyles from 'theme/global';
import { Theme } from 'theme/theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/rc-image.css';
import { AutomationProvider } from 'Pages/AutomationBoard/context';

function App() {
    return (
        <BrowserRouter>
            <Theme>
                <GoogleOAuthProvider
                    clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? ''}
                >
                    <AutomationProvider>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            limit={1}
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                        <GlobalStyles />
                        <RoutesComponent />
                    </AutomationProvider>
                </GoogleOAuthProvider>
            </Theme>
        </BrowserRouter>
    );
}

export default App;
