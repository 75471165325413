import styled from 'styled-components';

export const Container = styled.div`
    width: 21rem;
    height: 10rem;

    & > h3 {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2%.8rem;
        color: #292929;
    }

    canvas {
        width: 6rem !important;
        height: 6rem !important;
        margin-right: 1.4rem;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 18rem;
        margin-top: 1.2rem;

        p {
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 2.9rem;
            color: #171725;
        }

        small {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.9rem;
            letter-spacing: 0.1px;
            color: #666666;
        }
    }
`;
