import React, { useEffect, useRef, useState } from 'react';
import {
    ChoiceLabel,
    CollapseOwners,
    Container,
    CurrentOwner,
    FieldLabel,
    OwnerItem,
    RadioOwner
} from './styles';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Input from 'Components/Atoms/Input';
import { FiSearch } from 'react-icons/fi';
import { Collapse } from 'react-collapse';
import Avatar from 'Components/Atoms/Avatar';

type SelectOwnerProps = {
    users: {
        id: number;
        name: string;
        avatar: string;
    }[];
    setOwner: (id: number) => void;
    currentOwner: {
        id: number;
        name: string;
        avatar: string;
    };
};
const SelectOwner: React.FC<SelectOwnerProps> = ({
    setOwner,
    currentOwner,
    users
}) => {
    const [collapseOpened, setCollapseOpened] = useState(true);
    const [selectOpened, setSelectOpened] = useState(false);
    const selectRef = useRef<any>(null);
    const [name, setName] = useState('');

    const filterName = () => {
        if (name) {
            return users.filter((i) =>
                i.name.toLowerCase().includes(name.toLowerCase())
            );
        }
        return users;
    };

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (
                selectRef.current &&
                !selectRef.current.contains(event.target)
            ) {
                setSelectOpened(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <Container>
            <CurrentOwner onClick={() => setSelectOpened(!selectOpened)}>
                <Avatar
                    src={currentOwner?.avatar}
                    size="2.8rem"
                    alt={currentOwner?.name}
                />

                <p>{currentOwner.name}</p>
                <BsChevronDown size={17} />
            </CurrentOwner>

            {selectOpened && (
                <RadioOwner ref={selectRef}>
                    <FieldLabel>Repasse de responsável</FieldLabel>
                    <Input
                        name="search"
                        leftIcon={FiSearch}
                        placeholder="Nome"
                        onChange={(e) => setName(e.currentTarget.value)}
                    />

                    <ChoiceLabel
                        onClick={() => setCollapseOpened(!collapseOpened)}
                    >
                        Equipe (nome){' '}
                        {collapseOpened ? (
                            <BsChevronDown size={17} />
                        ) : (
                            <BsChevronUp size={17} />
                        )}
                    </ChoiceLabel>
                    <Collapse isOpened={collapseOpened}>
                        <CollapseOwners>
                            {filterName().map((user) => (
                                <OwnerItem
                                    key={user.id}
                                    onClick={() => {
                                        setOwner(user.id);
                                        setSelectOpened(false);
                                    }}
                                >
                                    {/* <img src={user.avatar} alt={user.name} /> */}
                                    <Avatar
                                        src={user?.avatar}
                                        alt={user?.name}
                                    />
                                    <p>{user.name}</p>
                                </OwnerItem>
                            ))}
                        </CollapseOwners>
                    </Collapse>
                </RadioOwner>
            )}
        </Container>
    );
};

export default SelectOwner;
