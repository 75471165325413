import axios from 'axios';

export function ErrorBoundary(error: any): void {
    if (
        axios.isAxiosError(error) &&
        !!error?.response?.data &&
        Array.isArray(error?.response?.data) &&
        error.status !== 404
    ) {
        throw new Error(error?.response?.data[0]);
    }
    if (
        axios.isAxiosError(error) &&
        !!error?.response?.data &&
        !Array.isArray(error?.response?.data) &&
        error.status !== 404
    ) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        } else {
            throw new Error(error?.response?.data);
        }
    }
    if (error?.response?.data) {
        throw new Error(
            error?.response?.data?.message || error?.response?.statusText
        );
    }
    if (error?.message) {
        throw new Error(error?.message);
    }
    throw new Error(error);
}
