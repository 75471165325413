/* eslint-disable react/display-name */
import React, { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutomation } from '../context';
import { DeleteButton, NodeContainer, handleStyleTop } from './styles';
import { BiTrash } from 'react-icons/bi';
import { Handle, Position } from 'reactflow';
import Input from 'Components/Atoms/Input';
import { useContactStore } from 'store';
import Select from 'Components/Atoms/Select';

export const SelectGroup = memo(({ id, data }: any) => {
    const { getValues, setValue, register } = useFormContext();
    const { getGroups, groups } = useContactStore();

    const { setNodes, setEdges } = useAutomation();

    const handleRemoveNode = () => {
        setNodes((old) => old.filter((item) => item.id !== id));
        setEdges((old) =>
            old.filter((item) => item.target !== id && item.source !== id)
        );
        setValue(`${id}.group`, undefined);
    };

    useEffect(() => {
        if (!groups.length) {
            getGroups();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NodeContainer>
            <DeleteButton onClick={handleRemoveNode}>
                <BiTrash size={24} />
            </DeleteButton>
            <Handle
                type="target"
                position={Position.Top}
                style={handleStyleTop}
                isConnectable={true}
                id={`${id}-1`}
            />

            <Input
                id={`${id}.message`}
                name={`${id}.message`}
                label="Texto"
                marginVertical="10px"
                defaultValue={data.message}
                onChange={(e) => (data.message = e.currentTarget.value)}
                register={register}
            />

            <Select
                id={`${id}.group`}
                name={`${id}.group`}
                label={`Grupo`}
                value={getValues(`${id}.group`)}
                defaultValue={data.groupId}
                onChange={(e) => {
                    setValue(`${id}.group`, e.currentTarget.value);
                    data.groupId = e.currentTarget.value;
                }}
                options={groups.map((group) => ({
                    label: group.name,
                    value: group.id
                }))}
            />
        </NodeContainer>
    );
});
