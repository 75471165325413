export const modalContent = {
    newTitle: 'Novo grupo',
    editTitle: 'Edição de grupo',
    actions: {
        cancel: 'Cancelar',
        save: 'Salvar'
    },
    fields: {
        name: {
            label: 'Nome do grupo',
            placeholder: 'Ex: Marketing'
        }
    }
};
