import React from 'react';

import { Container } from './styles';
import SocialIcon from 'Components/Atoms/SocialIcon';
import { socialType } from 'Constant';

interface SocialTipPros {
    socialMedia: string;
}

const SocialTip: React.FC<SocialTipPros> = ({ socialMedia }) => {
    return (
        <Container>
            <SocialIcon
                socialMedia={socialMedia as socialType}
                width="20px"
                height="20px"
            />
            <p>{socialMedia}</p>
        </Container>
    );
};

export default SocialTip;
