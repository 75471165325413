import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: 100%;
    color: ${({ theme }) => theme.colors.low.dark};
    margin-bottom: 3rem;
    cursor: pointer;

    svg {
        margin-right: 1rem;
    }
`;

export const InputWrapper = styled.div`
    input {
        padding: 12px;
    }

    svg {
        margin-left: 12px;
    }
`;

export const ResultLength = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: 100%;
    margin-top: 3rem;
    color: ${({ theme }) => theme.colors.low.light};
`;

export const MenssagesList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 2rem 0;

    & > li {
        cursor: pointer;
    }
`;

export const SmallContact = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 3.2rem;
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
    }

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSize.xxs};
        line-height: 20px;
        color: ${({ theme }) => theme.colors.low.pure};
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.xxs};
        line-height: 20px;
        color: ${({ theme }) => theme.colors.low.light};
    }
`;
