import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: #292929;

        small {
            padding-left: 1rem;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #a3a3a3;
        }
    }
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 14px;

    input {
        padding: 15px 10px;
        min-width: 200px;
    }

    & > form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 75%;
        gap: 15px;

        & > button {
            min-width: 95px;
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        & > form {
            flex-direction: column;
            width: 100%;

            #search {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                gap: 10px;
            }

            #search > button {
                padding: 0 10px;
                margin: 0;
                width: 100%;
            }
        }

        & > button {
            margin-top: 20px;
            width: 100%;
        }
    }
`;

export const TableWrapper = styled.div`
    @media (max-width: 1024px) {
        width: 100%;
        height: max-content;
        overflow: auto;
    }
`;
