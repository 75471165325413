import React, { useEffect, useState } from 'react';

import { Col, Container, CustomDivider, FormRow, Row, Title } from './styles';
import UploadProfileImage from 'Components/Organisms/UploadProfileImage';
import { useAuthStore, useSettingsStore } from 'store';
import Button from 'Components/Atoms/Button';
import { FormTitle } from 'Pages/Register/styles';
import Input from 'Components/Atoms/Input';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputPhone from 'Components/Atoms/InputPhone';
import InputTime from 'Components/Atoms/InputTime';
import dayjs from 'dayjs';
import Select from 'Components/Atoms/Select';

const Profile: React.FC = () => {
    const { userData, updateProfile } = useAuthStore();
    const { getRoles, roles } = useSettingsStore();
    const [image, setImage] = useState<{
        url?: string;
        files?: File[];
    }>();

    const schema = z.object({
        avatar: z.instanceof(FileList).optional(),
        firstName: z.string().min(1, 'Campo obrigatório'),
        lastName: z.string().min(1, 'Campo obrigatório'),
        email: z.string().email('Email inválido!').min(1, 'Campo obrigatório'),
        phone: z.string().optional(),
        companyName: z.string().optional(),
        role: z.number().optional(),
        permission: z.string().optional(),
        startOfWork: z.string().optional(),
        endOfWork: z.string().optional()
    });

    const {
        register,
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors }
    } = useForm<{
        avatar?: File;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        companyName: string;
        role: number;
        permission: string;
        startOfWork: string;
        endOfWork: string;
    }>({
        resolver: zodResolver(schema),
        mode: 'onChange'
    });

    const onSubmit = handleSubmit(async (data) => {
        const formData = new FormData();
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('companyName', data.companyName);
        if (data.role) formData.append('role', data.role.toString());
        formData.append('permission', data.permission);
        formData.append('startOfWork', data.startOfWork);
        formData.append('endOfWork', data.endOfWork);
        if (image?.files) {
            formData.append('avatar', image.files[0]);
        }

        updateProfile(formData);
    });

    useEffect(() => {
        if (userData?.id) {
            reset({
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                role: userData.role.id,
                companyName: userData.company.name,
                phone: userData.phone,
                permission: userData.role.roles,
                endOfWork: userData.endOfWork,
                startOfWork: userData.startOfWork
                // endOfWork: dayjs(userData.endOfWork).format('HH:mm'),
                // startOfWork: dayjs(userData.startOfWork).format('HH:mm')
            });

            setImage({ url: userData.avatar });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    useEffect(() => {
        getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Title>Meus dados</Title>

            <form onSubmit={onSubmit}>
                <Row style={{ marginTop: '3rem' }}>
                    <UploadProfileImage
                        currentImage={image?.url}
                        register={register('avatar', {
                            onChange: (e) =>
                                setImage({
                                    files: e.currentTarget.files,
                                    url: URL.createObjectURL(
                                        e?.currentTarget?.files[0]
                                    )
                                })
                        })}
                    />

                    <Button type="submit" size={'SMALL'} rounded width="12rem">
                        Salvar
                    </Button>
                </Row>

                <CustomDivider />

                <FormRow>
                    <Col>
                        <FormTitle>Dados pessoais</FormTitle>
                        <Input
                            name="email"
                            id="email"
                            label="Email"
                            placeholder=""
                            register={register('email')}
                            error={errors.email?.message}
                        />
                        <Input
                            name="firstName"
                            id="firstName"
                            label="Primeiro nome"
                            placeholder=""
                            register={register('firstName')}
                            error={errors.firstName?.message}
                        />
                        <Input
                            name="lastName"
                            id="lastName"
                            label="Sobrenome"
                            placeholder=""
                            register={register('lastName')}
                            error={errors.lastName?.message}
                        />
                        <InputPhone
                            name="phone"
                            id="phone"
                            control={control}
                            label="Telefone"
                            placeholder="(99) 99999-9999"
                            error={errors.phone?.message}
                        />
                    </Col>
                    <Col>
                        <FormTitle>Empresa e papéis</FormTitle>
                        <Input
                            name="companyName"
                            id="companyName"
                            label="Nome da empresa"
                            placeholder=""
                            register={register('companyName')}
                            error={errors.companyName?.message}
                        />
                        <Select
                            name="role"
                            id="role"
                            label="Cargo"
                            placeholder="Selecione a função"
                            defaultValue={getValues('role')}
                            control={control}
                            error={errors.role?.message}
                            options={roles.map((item) => ({
                                label: item.name,
                                value: item.id
                            }))}
                        />
                        <Input
                            name="permission"
                            id="permission"
                            label="Papel no sistema"
                            placeholder=""
                            register={register('permission')}
                            error={errors.permission?.message}
                        />
                        <FormTitle>Expediente</FormTitle>
                        <Row style={{ gap: '1.5rem' }}>
                            <InputTime
                                name="startOfWork"
                                id="startOfWork"
                                label="Início"
                                type="time"
                                register={register('startOfWork')}
                                error={errors.startOfWork?.message}
                            />
                            <InputTime
                                name="endOfWork"
                                id="endOfWork"
                                label="Fim"
                                type="time"
                                register={register('endOfWork')}
                                error={errors.endOfWork?.message}
                            />
                        </Row>
                    </Col>
                </FormRow>
            </form>
        </Container>
    );
};

export default Profile;
