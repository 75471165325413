import React from 'react';
import { Container, Content, Label } from './styles';
import { BiUpload } from 'react-icons/bi';
import Avatar from 'Components/Atoms/Avatar';

type UploadProfileImageProps = {
    currentImage?: string;
    register?: any;
};

const UploadProfileImage: React.FC<UploadProfileImageProps> = ({
    currentImage,
    register
}) => {
    return (
        <Container>
            <Avatar
                src={currentImage}
                size="6.4rem"
                style={{ marginRight: '2rem' }}
            />
            <Content>
                <h3>Atualize sua foto de perfil</h3>
                <p>
                    Arquivo máximo de 3 megabytes nos formatos jpg, png e svg.
                </p>

                <input
                    type="file"
                    id="avatarUpload"
                    name="avatarUpload"
                    {...register}
                />
                <Label htmlFor="avatarUpload">
                    <BiUpload /> Fazer upload
                </Label>
            </Content>
        </Container>
    );
};

export default UploadProfileImage;
