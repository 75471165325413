export const content = {
    contactDetails: {
        title: 'Voltar para a lista',
        products: {
            title: 'Produtos sugeridos',
            priceLegend: 'Valor total:'
        },
        channels: {
            title: 'Canais'
        },
        groups: {
            title: 'Grupos',
            action: {
                label: 'Adicionar Grupo'
            }
        },
        details: {
            title: 'Detalhes do contato',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            email: 'Email',
            phone: 'Telefone',
            owner: 'Dono do contato'
        }
    }
};
