import styled from 'styled-components';

export const Row = styled.div<{ small?: boolean }>`
    font-style: normal;
    font-weight: 400;
    font-size: ${({ small }) => (small ? '10px' : '13px')};
    line-height: 14px;
    text-align: start;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Download = styled.button`
    border: none;
    background: none;
`;

export const Container = styled.div<{ darkMode?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    & > svg {
        color: ${({ darkMode }) => (darkMode ? '#ffffff' : '#000000')};
        font-size: 3rem;
    }

    ${Row} {
        color: ${({ darkMode }) => (darkMode ? '#ffffff' : '#000000')};
    }

    ${Download} {
        color: ${({ darkMode }) => (darkMode ? '#ffffff' : '#000000')};
        font-size: 2rem;
    }
`;
