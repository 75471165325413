import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;
`;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.6rem;
    color: #292929;

    svg {
        margin-left: 1.2rem;
    }
`;
