import styled from 'styled-components';

export const Container = styled.div`
    background: #ffffff;
    min-width: 36rem;
    padding: 3rem;
    padding-top: 0;
    border-radius: 0.8rem;

    @media (max-width: 1024px) {
        padding: 10px;
    }
`;
export const Title = styled.h3`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 1.6rem;
    color: #292929;
    cursor: pointer;

    svg {
        margin-right: 1rem;
    }
`;

export const AvatarSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3rem;
    margin-bottom: 2rem;

    & > p {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #222222;

            width: fit-content;
            background: #d4f8d3;
            border-radius: 6px;
            padding: 2px 8px;

            margin-top: 4px;
        }
    }
`;

export const Ranking = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    border-radius: 4px;
    padding: 1rem;

    & > p {
        margin-left: 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: #292929;
    }
`;

export const DetailTextItem = styled.p`
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #1c1c1c;
    margin-top: 2rem;

    strong {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1c1c1c;
    }
`;

export const GroupsSection = styled.div`
    margin-bottom: 2rem;

    & > ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;
    }

    li {
        font-weight: 400;
        font-size: 14px;
        line-height: 133%;
        color: #cc6e00;
        width: fit-content;
        background: #ffe3c2;
        padding: 8px;
        border: 1px solid #ffe3c2;
        border-radius: 4px;
    }
`;

export const DetailsSection = styled.div``;

export const DetailInfo = styled.p`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;

    strong {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #a3a3a3;
        padding-bottom: 8px;
    }

    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #000000;
    }
`;
