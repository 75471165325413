import styled from 'styled-components';

export const Container = styled.div<{
    width: string;
    height: string;
}>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: ${({ width }) => width};

    svg {
        width: ${({ width }) => width};
        height: ${({ height }) => height};
    }
`;
