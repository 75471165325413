import styled from 'styled-components';

export const Title = styled.h3`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
    margin-bottom: 1.2rem;

    & > svg {
        margin-right: 15px;
    }
`;
