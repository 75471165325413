import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 3.7rem;

    @media (max-width: 1024px) {
        gap: 3rem;
        flex-direction: column-reverse;
    }
`;

export const MainContainer = styled.div`
    background: #ffff;
    border-radius: 8px;
    padding: 4rem;
    width: 100%;
`;

export const GoBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    & > p {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 100%;
        color: #292929;
        margin-left: 1rem;
    }
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 100%;
    color: #000000;
    margin-top: 5rem;
`;

export const TagList = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    margin: 8px 0;

    & > li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 4px;
        background: #ffffff;
        border: 0.5px dashed #292929;
        border-radius: 4px;

        font-weight: 400;
        font-size: 1.1rem;
        line-height: 133%;
        color: #292929;

        cursor: pointer;

        &:hover {
            color: #ff8a00;
        }
    }
`;

export const PreviewContainer = styled.div`
    width: 100%;
    max-width: 33rem;
    background: #ffff;
    border-radius: 8px;
    padding: 4rem;

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

export const CodeMirrorWrapper = styled.div`
    margin-bottom: 4rem;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.9rem;

    .react-codemirror2 > div {
        background: #fbfbfb;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 1rem 1.3rem;
        max-height: 20rem;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .react-codemirror2 > div:first-child {
        display: none;
    }
`;

export const FormButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 2.7rem;
    margin: 5.4rem 0;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const FormFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.2rem;

    & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 100%;
        color: #ea1f1f;
    }
`;

export const FormListContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 2.7rem;
    margin: 3rem 0;
`;

export const FormAnswersContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 2.7rem;
    margin: 3rem 0;
`;

export const FormListActionGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const FormListActionRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    & > button {
        background: none;
        border: none;
        margin-left: 1rem;
    }
`;

export const ListTitle = styled.h3`
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 100%;
    color: #000000;
    margin-bottom: 1.4rem;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.8rem;
    margin-top: 2.4rem;
    width: 100%;
`;
