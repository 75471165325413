/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutomation } from '../context';
import {
    DeleteButton,
    NodeContainer,
    handleStyleBottom,
    handleStyleTop
} from './styles';
import { BiTrash } from 'react-icons/bi';
import { Handle, Position } from 'reactflow';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';

export const MessageButton = memo(({ id, data }: any) => {
    const { setValue, register } = useFormContext();
    const { setNodes, setEdges, nodes, handleAddSubNodes, handleAddEdge } =
        useAutomation();
    const isTheFirstNode = nodes.findIndex((item) => item.id === id) === 0;

    const nodeChildren = nodes.filter(
        (item) => Number(item.parentId) === Number(id)
    );
    const nextId = nodeChildren.length + 1;

    const nodeParent = nodes.find((item) => item.id === id);

    const positionY =
        (nodeParent?.position?.y || nodes[0].position.y) +
        (nodeParent?.height || nodes[0]?.height || 80) +
        80;

    const positionX =
        nextId > 1
            ? (nodeChildren.at(-1)?.position?.x || nodes[0].position.x) +
              (nodeParent?.width || nodes[0]?.width || 256) +
              80
            : nodeChildren.at(-1)?.position?.x || nodes[0].position.x;

    const handleRemoveNode = () => {
        const children = nodes
            .filter((item) => item.parentId === id)
            .map((item) => item.id);
        setNodes((old) =>
            old.filter((item) => item.id !== id && item.parentId !== id)
        );
        setEdges((old) =>
            old.filter(
                (item) =>
                    item.target !== id &&
                    item.source !== id &&
                    !children.includes(item.target) &&
                    !children.includes(item.source)
            )
        );
        setValue(`${id}.type`, undefined);
        setValue(`${id}.message`, undefined);
    };

    return (
        <NodeContainer>
            <DeleteButton onClick={handleRemoveNode}>
                <BiTrash size={24} />
            </DeleteButton>

            {!isTheFirstNode && (
                <Handle
                    type="target"
                    position={Position.Top}
                    style={handleStyleTop}
                    isConnectable={true}
                    id={id}
                />
            )}

            <h1>Mensagem com botões</h1>
            <Input
                id={`${id}.message`}
                name={`${id}.message`}
                label="Texto"
                defaultValue={data.message}
                marginVertical="10px"
                onChange={(e) => (data.message = e.currentTarget.value)}
                register={register}
            />

            <Button
                size={'SMALL'}
                disabled={nodeChildren.length >= 5}
                onClick={() => {
                    handleAddSubNodes({
                        id: `${id}.${nextId}`,
                        parentId: id,
                        type: 'buttonText',
                        position: {
                            x: positionX,
                            y: positionY
                        }
                    });

                    handleAddEdge(id, `${id}.${nextId}`);
                }}
            >
                Adicionar Opção
            </Button>

            <Handle
                type="source"
                position={Position.Bottom}
                style={handleStyleBottom}
                isConnectable={true}
                id={id}
            />
        </NodeContainer>
    );
});
