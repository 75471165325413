import ForgotImage from 'assets/images/forgot/forgot.webp';

export const content = {
    image: ForgotImage,
    back: 'Voltar',

    step1: {
        title: 'Esqueci a senha',
        description:
            'Informe seu email cadastrado para que possamos enviar o link de recuperação de senha.',
        email: {
            label: 'Email',
            placeholder: 'Usuário'
        },
        submit: 'Enviar'
    },
    step2: {
        title: 'Esqueci a senha',
        description:
            'Enviamos um email com o link de recuperação de senha para {{email}}.',
        resend: {
            text: 'Não recebeu o email?',
            submit: 'Reenviar email'
        },
        submit: 'Voltar para a home'
    }
};
