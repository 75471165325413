import React, { useEffect } from 'react';

import { Container } from './styles';
import MessageItem from '../MessageItem';
import { ChatContactMessages } from 'services/contacts/types';

export type MessageListProps = {
    content: ChatContactMessages[];
};
const MessageList: React.FC<MessageListProps> = ({ content }) => {
    const messagesEndRef = React.createRef<HTMLLIElement>();

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ inline: 'end' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    return (
        <Container id="messageList">
            {content.map((item) => (
                <li key={item.id} id={`messageItem-${item.id}`}>
                    <MessageItem {...item} />
                </li>
            ))}
            <li ref={messagesEndRef} />
        </Container>
    );
};

export default MessageList;
