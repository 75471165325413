import LoggedLayout from 'Components/Templates/LoggedLayout';
import React, { useEffect } from 'react';
import Box from './Box';
import { Container } from './styles';
import ContactList from './ContactList';
import ContactDetails from './ContactDetails';
import ContactMessages from './ContactMessages';
import { useContactStore } from 'store/useContacts';
import MessageSearch from './MessageSearch';
import { useWebSocketStore } from 'store';

const Chat: React.FC = () => {
    const webSocket = useWebSocketStore();
    const { searchMessageEnabled } = useContactStore();

    useEffect(() => {
        if (!webSocket.socket) {
            webSocket.connect();
        }
        webSocket.clear();
    }, []);

    return (
        <LoggedLayout>
            <Container>
                <Box style={{ gridArea: 'list' }}>
                    <ContactList />
                </Box>
                <Box padding="0 20px" style={{ gridArea: 'messages' }}>
                    <ContactMessages />
                </Box>
                <Box style={{ gridArea: 'details' }}>
                    {!searchMessageEnabled ? (
                        <ContactDetails />
                    ) : (
                        <MessageSearch />
                    )}
                </Box>
            </Container>
        </LoggedLayout>
    );
};

export default Chat;
