import { useCallback, useEffect, useState } from 'react';

const useTimer = () => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const hours = Math.floor(time / 360000);
    const minutes = Math.floor((time % 360000) / 6000);
    const seconds = Math.floor((time % 6000) / 100);
    const milliseconds = time % 100;

    const startAndStop = () => {
        setIsRunning(!isRunning);
    };

    const reset = () => {
        setTime(0);
    };

    const formatedTime = useCallback(
        () =>
            `${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}:${milliseconds.toString().padStart(2, '0')}`,
        [milliseconds, minutes, seconds]
    );

    useEffect(() => {
        let intervalId: string | number | NodeJS.Timeout | undefined;
        if (isRunning) {
            intervalId = setInterval(() => setTime(time + 1), 10);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    return {
        hours,
        minutes,
        seconds,
        milliseconds,
        startAndStop,
        reset,
        formatedTime
    };
};

export default useTimer;
