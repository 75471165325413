import React from 'react';
import { Link } from './styles';

interface ForgotPasswordLinkProps {
    text: string;
    url: string;
}

const ForgotPasswordLink: React.FC<ForgotPasswordLinkProps> = ({
    text,
    url
}) => {
    return <Link to={url}>{text}</Link>;
};

export default ForgotPasswordLink;
