import TokenService from './token';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

type Token = {
    exp: number;
};

api.interceptors.request.use(async (config: any) => {
    const data = TokenService.getAuthData();
    const token =
        data?.accessToken && (jwt_decode(data?.accessToken || '') as Token);

    if (data && token && token?.exp <= Math.floor(Date.now() / 1000)) {
        await TokenService.removeAuthData();
        await sessionStorage.removeItem('deetz-auth-store');
        window.location.href = window.location.origin;
        return;
    }
    if (config && data) {
        config.headers = {
            Authorization: `Bearer ${data?.accessToken}`
        };
    }

    return config;
});

export default api;
