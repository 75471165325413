/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../api';
import {
    TeamsResponse,
    RolesResponse,
    PermissionsResponse,
    MessageModelRequest,
    MessageModel,
    MessageModelResponse,
    TeamsDetailsResponse,
    Group
} from './types';
import { ErrorBoundary } from 'services/errorBoundary';

class SettingsService {
    async getTeams(): Promise<TeamsResponse[] | undefined> {
        try {
            const result = await api.get<TeamsResponse[]>('/teams');
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getTeamsDetails(
        memberId: number
    ): Promise<TeamsDetailsResponse | undefined> {
        try {
            const result = await api.get<TeamsDetailsResponse>(
                `/teams/${memberId}`
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getGroups(): Promise<Group[] | undefined> {
        try {
            const result = await api.get<Group[]>('/groups');
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async createGroup(data: Group): Promise<Group | undefined> {
        try {
            const result = await api.post<Group>(`/groups`, data);
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async updateGroup(
        groupId: number,
        data: Group
    ): Promise<Group | undefined> {
        try {
            const result = await api.put<Group>(`/groups/${groupId}`, data);
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async deleteGroup(groupId: number): Promise<Group | undefined> {
        try {
            const result = await api.delete<Group>(`/groups/${groupId}`);
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getRoles(): Promise<RolesResponse[] | undefined> {
        try {
            const result = await api.get<RolesResponse[]>('/roles');
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getPermissions(): Promise<PermissionsResponse[] | undefined> {
        try {
            const result = await api.get<PermissionsResponse[]>('/permissions');
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getMessageModels(params?: {
        filter?: string;
        page?: number;
        rowsByPage?: number;
    }): Promise<MessageModel[] | undefined> {
        try {
            const result = await api.get<MessageModel[]>('/templateMessage', {
                params: params
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getMessageModelDetail(
        id: number
    ): Promise<MessageModelResponse | undefined> {
        try {
            const result = await api.get<MessageModelResponse>(
                `/templateMessage/${id}`
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async deleteMessageModels(messageId: number) {
        try {
            await api.delete(`/templateMessage/${messageId}`);
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async postMessageModels(
        data: MessageModelRequest
    ): Promise<MessageModel | undefined> {
        try {
            const result = await api.post<MessageModel>('/templateMessage', {
                name: data.name,
                type: data.type,
                phone: data.phone,
                quickAnswers: data.text,
                status: 'pending',
                isActived: true,
                button: {
                    label: data.messageBody.label,
                    typeAction: data.messageBody.typeAction,
                    link: data.messageBody.link
                },
                list: {
                    title: data.messageBody.title,
                    options: data.messageBody.options?.map((option) => ({
                        label: option.label,
                        typeAction: option.typeAction,
                        link: ''
                    }))
                }
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async updateMessageModels(
        id: number,
        data: MessageModelRequest
    ): Promise<MessageModel | undefined> {
        try {
            const result = await api.put<MessageModel>(
                `/templateMessage/${id}`,
                {
                    name: data.name,
                    type: data.type,
                    phone: data.phone,
                    quickAnswers: data.text,
                    status: 'pending',
                    isActived: true,
                    button: {
                        label: data.messageBody.label,
                        typeAction: data.messageBody.typeAction,
                        link: data.messageBody.link
                    },
                    list: {
                        title: data.messageBody.title,
                        options: data.messageBody.options?.map((option) => ({
                            label: option.label,
                            typeAction: option.typeAction,
                            link: ''
                        }))
                    }
                }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
}

export default new SettingsService();
