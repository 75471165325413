import React from 'react';
import { Container } from './styles';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useTheme } from 'styled-components';

const ValidationItem = (props: { checked: boolean; label: string }) => {
    const theme = useTheme();
    return (
        <Container>
            {!props?.checked ? (
                <MdCheckBoxOutlineBlank
                    color={theme.colors.high.medium}
                    size={20}
                />
            ) : (
                <MdOutlineCheckBox
                    color={theme.colors.success.pure}
                    size={20}
                />
            )}
            <p>{props.label}</p>
        </Container>
    );
};

export default ValidationItem;
