import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const InputCheckbox = styled.input`
    min-width: auto !important;
    margin-right: 12px;
`;
