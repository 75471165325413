import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 25% 46% 25%;
    box-sizing: border-box;
    align-items: center;
    justify-items: center;
    column-gap: 2.4rem;
    width: 100%;
    grid-template-areas:
        'list messages details'
        'list messages details'
        'list messages details';

    @media (max-width: 1024px) {
        grid-template-columns: 100%;
        grid-template-areas:
            'list'
            'details'
            'messages';
    }
`;
