import React, { useCallback, useEffect } from 'react';
import { TfiLayoutColumn3 } from 'react-icons/tfi';

import {
    Container,
    Actions,
    Header,
    SeeColumnsButton,
    TableWrapper
} from './styles';
import Input from 'Components/Atoms/Input';
import { FiSearch } from 'react-icons/fi';
import Button from 'Components/Atoms/Button';
import SelectCheckbox from 'Components/Atoms/SelectCheckbox';
import { useForm } from 'react-hook-form';
import { content } from '../content';
import TableRowSelection from 'Components/Organisms/TableRowSelection';
import { useContactStore } from 'store/useContacts';
import { useLocation, useNavigate } from 'react-router-dom';

type ContactTableProps = {
    switchViewMode: () => void;
};
const ContactTable: React.FC<ContactTableProps> = ({ switchViewMode }) => {
    const { setValue, getValues, watch, register } = useForm<{
        filter: string;
        owners: number[];
        groups: string[];
    }>();

    const { contentTable } = content;
    const location = useLocation();

    const {
        rowContacts,
        teamMembers,
        groups,
        getRowContacts,
        getTeamMembers,
        getGroups
    } = useContactStore();
    const navigate = useNavigate();

    const handleOpenContact = useCallback(
        (id: number) => {
            navigate(`/contacts/${id}`);
        },
        [navigate]
    );

    // const handleConfirmDelete = async (contactId: number) => {
    //     confirmDialog({
    //         title: 'Remover contato',
    //         subtitle: 'Deseja realmente remover este contato?',
    //         acceptLabel: 'Sim',
    //         denyLabel: 'Não',
    //         acceptFunction: () => deleteContact(contactId)
    //     });
    // };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'name'
            },
            {
                Header: 'Canal',
                accessor: 'channel',
                type: 'channel'
            },
            {
                Header: 'Identificador',
                accessor: 'phoneNumber'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Grupo',
                accessor: 'group',
                type: 'group'
            },
            {
                Header: 'Ações',
                accessor: 'actions',
                type: 'actions'
            }
        ],
        []
    );

    const handleSearchContacts = useCallback(() => {
        const params = getValues();
        if (params.groups || params.owners || params.filter) {
            getRowContacts(params);
        }
    }, [getRowContacts, getValues]);

    const handleNextPage = useCallback(
        (page: number) => {
            const params = getValues();
            getRowContacts({ ...params, page: page, rowsByPage: 15 });
        },
        [getRowContacts, getValues]
    );

    useEffect(() => {
        getRowContacts();
        getTeamMembers();
        getGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Header>
                <h1>
                    {contentTable.header.title}{' '}
                    <small>{rowContacts?.rows?.length}</small>
                </h1>

                <SeeColumnsButton onClick={() => switchViewMode()}>
                    {contentTable.header.buttonColumns} <TfiLayoutColumn3 />
                </SeeColumnsButton>
            </Header>
            <Actions>
                <form>
                    <div id="search">
                        <Input
                            name="filter"
                            leftIcon={FiSearch}
                            placeholder={contentTable.actions.searchPlaceholder}
                            register={register('filter')}
                        />
                        <Button
                            width="110px"
                            height="48px"
                            size={'SMALL'}
                            rounded
                            marginHorizontal="8px"
                            type="button"
                            disabled={!watch('filter')?.length}
                            onClick={() => handleSearchContacts()}
                        >
                            {contentTable.actions.searchButton}
                        </Button>
                    </div>
                    <SelectCheckbox
                        name="owners"
                        placeholder={contentTable.actions.ownerPlaceholder}
                        options={teamMembers.users.map(
                            ({ id, lastName, firstName }) => ({
                                value: id,
                                label: `${firstName} ${lastName}`
                            })
                        )}
                        onChange={(options) =>
                            setValue(
                                'owners',
                                options.map((option) => option.value)
                            )
                        }
                        onBlur={() => handleSearchContacts()}
                    />
                    <SelectCheckbox
                        name="groups"
                        placeholder={contentTable.actions.groupPlaceholder}
                        options={groups.map((group) => ({
                            label: group.name,
                            value: group.id
                        }))}
                        onChange={(options) =>
                            setValue(
                                'groups',
                                options.map((option) => option.value)
                            )
                        }
                        onBlur={() => handleSearchContacts()}
                    />
                </form>
                <Button
                    width="138px"
                    height="48px"
                    size={'SMALL'}
                    rounded
                    onClick={() =>
                        navigate(`/contacts/form`, {
                            state: { background: location }
                        })
                    }
                >
                    {contentTable.actions.newContactButton}
                </Button>
            </Actions>

            <TableWrapper>
                <TableRowSelection
                    columns={columns}
                    totalItems={rowContacts.totalItems}
                    handleNextPage={handleNextPage}
                    data={
                        rowContacts?.rows?.map((contact) => ({
                            ...contact,
                            name: `${contact.firstName} ${contact.lastName}`,
                            phoneNumber: contact.phoneNumber,
                            status: contact.isActived ? 'Ativo' : 'Bloqueado',
                            group:
                                contact?.groups
                                    ?.map((item) => item.name)
                                    ?.toString() || ''
                        })) || []
                    }
                    actions={{
                        edit: (id) =>
                            navigate(`/contacts/form/${id}`, {
                                state: { background: location }
                            }),
                        view: (id) => handleOpenContact(id)
                        // delete: (id) => handleConfirmDelete(id)
                    }}
                />
            </TableWrapper>
        </Container>
    );
};

export default ContactTable;
