import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    max-width: 300px;

    @media (max-width: 1024px) {
        justify-content: flex-end;
        max-width: 100%;
        padding-top: 10px;
    }
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: 17px;
    color: ${({ theme }) => theme.colors.low.pure};
    flex: 1;
`;

export const SearchMessage = styled.button`
    background: none;
    border: none;
    margin-left: 10px;
`;
