import ResetImage from 'assets/images/forgot/reset.webp';

export const content = {
    image: ResetImage,
    back: 'Voltar',

    title: 'Crie a sua nova senha',
    fields: {
        newPassword: {
            label: 'Nova senha',
            placeholder: 'Sua senha',
            validations: {
                PASSWORD_CAMELCASE_CHECK: 'Letras maiúsculas e minúsculas',
                PASSWORD_NUMERIC_CHECK: 'Caracteres numéricos',
                PASSWORD_SIZE_CHECK: 'Mínimo de 7 caracteres',
                PASSWORD_DIFFERENCE: 'Digite a mesma senha em ambos os campos'
            }
        },
        password: {
            label: 'Repita a senha',
            placeholder: 'Sua senha'
        }
    },
    submit: 'Salvar'
};
