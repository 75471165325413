import React from 'react';
import { ReactComponent as Whatsapp } from 'assets/icons/whatsapp-icon.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram-icon.svg';
import { ReactComponent as Messenger } from 'assets/icons/messenger-icon.svg';
import { ReactComponent as SMS } from 'assets/icons/sms-icon.svg';
import { ReactComponent as Telegram } from 'assets/icons/telegram-icon.svg';
import { AvatarWrapper, Container, Content } from './styles';
import dayjs from 'dayjs';
import Avatar from 'Components/Atoms/Avatar';
import { ListChatContactItem } from 'services/contacts/types';

export type ChatContactProps = ListChatContactItem;

type ChatContactActionProps = {
    onClick: () => void;
};

const socialIcons = {
    whatsapp: <Whatsapp />,
    instagram: <Instagram />,
    sms: <SMS />,
    telegram: <Telegram />,
    messenger: <Messenger />
};

const ChatContact: React.FC<ChatContactProps & ChatContactActionProps> = ({
    avatar,
    channelCommunication: channel,
    name: firstName,
    groups,
    lastMessage,
    onClick
}) => {
    return (
        <Container onClick={onClick}>
            <AvatarWrapper>
                <Avatar src={avatar} size="6.4rem" alt={firstName} />
                {socialIcons[channel]}
            </AvatarWrapper>
            <Content>
                <h3>{firstName}</h3>

                <p>
                    {lastMessage?.messageBody
                        ? lastMessage?.messageBody?.text
                        : ''}
                </p>
                {groups?.length > 0 && (
                    <span>{groups.map((item) => item.name).toString()}</span>
                )}
            </Content>
            <p>{dayjs(lastMessage?.updatedAt).format('hh:mm')}</p>
        </Container>
    );
};

export default ChatContact;
