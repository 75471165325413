export interface Edge {
    id: string;
    source: string;
    sourceHandle?: string;
    target: string;
    targetHandle?: string | null;
}

interface NodeData {
    message?: string;
    target?: string;
    source?: string;
    groupId?: string;
}

export interface Node {
    id: string;
    type: string;
    position: { x: number; y: number };
    message?: string;
    options?: Node[];
    groups?: Node[];
    data?: NodeData;
    parentId?: string;
}

interface InputData {
    edges: Edge[];
    nodes: Node[];
}

interface OutputNode {
    order?: number;
    type?: string;
    message?: string;
    text?: string;
    next?: number;
    previous?: string;
    options?: any[];
    groups?: OutputNode[];
    groupId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OutputData extends Array<OutputNode> {}

function convertNode(inputNode: Node, inputData?: Node[]): OutputNode | null {
    const outputNode: OutputNode = {
        order: Number(inputNode.id),
        type: inputNode.type
    };

    if (inputNode.data) {
        outputNode.next = Number(inputNode.data.target);
        outputNode.message = inputNode?.data?.message;
    }

    if (inputNode.type === 'buttonText') {
        outputNode.text = inputNode.data?.message;
        outputNode.message = undefined;
        outputNode.type = undefined;
        outputNode.order = undefined;
    }

    if (inputNode.type === 'selectGroup') {
        outputNode.text = inputNode.data?.message;
        outputNode.groupId = Number(inputNode.data?.groupId) || undefined;
        outputNode.message = undefined;
        outputNode.type = undefined;
        outputNode.order = undefined;
    }

    if (inputNode.type === 'messageButton') {
        outputNode.options = inputData
            ?.filter(
                (node) =>
                    node.type === 'buttonText' && node.parentId === inputNode.id
            )
            ?.map((node) => convertNode(node));
    }

    if (inputNode.type === 'messageAttendance') {
        outputNode.options = inputData
            ?.filter(
                (node) =>
                    node.type === 'selectGroup' &&
                    node.parentId === inputNode.id
            )
            ?.map((node) => convertNode(node));
    }

    return outputNode;
}

export function convertFlowToAPIContract(inputData: InputData): OutputData {
    const outputData: OutputData = [];
    const nodes = [...inputData.nodes].filter(
        (node) => !['selectGroup', 'buttonText'].includes(node.type)
    );
    for (const node of nodes) {
        const converted = convertNode(node, [...inputData.nodes]);
        if (converted) {
            outputData.push(converted);
        }
    }

    return outputData;
}
