import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 4rem;
    & > h3 {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2%.8rem;
        color: #292929;
        margin-bottom: 1rem;
    }
`;

export const Card = styled.div<{ online?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.2rem;
    max-width: 27rem;
    min-width: 23rem;
    position: relative;
    cursor: pointer;

    img {
        width: 4rem;
        height: 4rem;
        border-radius: 4rem;
        margin-right: 1.6rem;
    }

    p {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #000000;
        max-width: 16.8rem;
    }

    small {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1c1c1c;
    }

    span {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #222222;
        padding: 0.2rem 0.8rem;
        border-radius: 0.6rem;
        background-color: ${({ online }) => (online ? '#D4F8D3' : '#FBE7E9')};
    }
`;
