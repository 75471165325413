import React, { useCallback, useState } from 'react';

import {
    AvatarSection,
    ChannelsSection,
    Container,
    DetailInfo,
    DetailsSection,
    GroupsSection,
    Title
} from './styles';
import { useContactStore } from 'store/useContacts';
import Divider from 'Components/Atoms/Divider';
import SocialTip from 'Components/Molecules/SocialTip';
import { AiOutlinePlus } from 'react-icons/ai';
import { content } from '../content';
import Loading from 'Components/Atoms/Loading';
import { BsChevronLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Avatar from 'Components/Atoms/Avatar';
import ModalGroups from '../ModalGroups';
import confirmDialog from 'Components/Atoms/ConfirmDialog';

const ContactDetails: React.FC = () => {
    const { currentContactDetails, loadingDetails, removeContactGroup } =
        useContactStore();
    const navigate = useNavigate();
    const [modalGroups, setModalGroups] = useState(false);

    const handleGoBack = useCallback(() => {
        navigate('/contacts');
    }, [navigate]);

    if (loadingDetails) {
        return <Loading />;
    }

    const handleConfirmDelete = async (groupId: number) => {
        confirmDialog({
            title: 'Remover grupo do contato',
            subtitle: 'Deseja realmente remover este grupo?',
            acceptLabel: 'Sim',
            denyLabel: 'Não',
            acceptFunction: () =>
                removeContactGroup({
                    contactId: Number(currentContactDetails?.id),
                    groupId: groupId
                })
        });
    };

    return (
        <Container>
            <Title onClick={() => handleGoBack()}>
                <BsChevronLeft />
                {content.contactDetails.title}
            </Title>
            <AvatarSection>
                <Avatar
                    src={currentContactDetails?.avatar}
                    size="6.4rem"
                    style={{ marginRight: '1rem' }}
                    alt={currentContactDetails?.firstName}
                />

                <p>{`${currentContactDetails?.firstName} ${currentContactDetails?.lastName}`}</p>
            </AvatarSection>

            <Divider />

            <ChannelsSection>
                <Title>{content.contactDetails.channels.title}</Title>

                {currentContactDetails?.contactChannels.map((channel) => (
                    <SocialTip socialMedia={channel.type} key={channel.id} />
                ))}
            </ChannelsSection>

            <GroupsSection>
                <Title>{content.contactDetails.groups.title}</Title>
                <ul>
                    {currentContactDetails?.groups
                        .filter((group) => !!group.isActived)
                        .map((item) => (
                            <li
                                key={item.id}
                                onClick={() => handleConfirmDelete(item.id)}
                            >
                                {item.name}
                            </li>
                        ))}
                </ul>

                <button onClick={() => setModalGroups(true)}>
                    <AiOutlinePlus size={16} />
                    {content.contactDetails.groups.action.label}
                </button>
            </GroupsSection>

            <Divider />

            <DetailsSection>
                <Title>{content.contactDetails.details.title}</Title>

                <DetailInfo>
                    <strong>{content.contactDetails.details.firstName}</strong>
                    <span>{currentContactDetails?.firstName}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.lastName}</strong>
                    <span>{currentContactDetails?.lastName}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.email}</strong>
                    <span>{currentContactDetails?.email}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.phone}</strong>
                    <span>{currentContactDetails?.phoneNumber}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.owner}</strong>
                    <span>
                        {currentContactDetails?.owner?.firstName}{' '}
                        {currentContactDetails?.owner?.lastName}
                    </span>
                </DetailInfo>
            </DetailsSection>

            <ModalGroups
                handleClose={() => setModalGroups(false)}
                isOpen={modalGroups}
            />
        </Container>
    );
};

export default ContactDetails;
