import { lighten } from 'polished';
import styled from 'styled-components';

const statusColor = {
    enabled: '#CC6E00',
    disabled: '#AAAAAA'
};
export const Container = styled.div`
    background: #fefefe;
    border-radius: 8px;
    padding: 3.5rem 2rem;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    height: 9rem;
    width: 100%;

    & > h1 {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 100%;
        color: #292929;
        align-self: flex-start;

        span {
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 100%;
            color: #a3a3a3;
            margin-left: 1rem;
        }
    }

    & > button {
        align-self: flex-end;
    }
`;

export const AutomationTable = styled.table`
    width: 100%;
    margin: 30px 0;
    border-collapse: collapse;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;

    & > thead {
        tr {
            height: 40px;
            background: #f9f9f9;
        }
        th {
            text-align: left;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #292929;
            padding-left: 16px;
        }

        th:first-child {
            max-width: 6rem;
        }
    }

    & > tbody {
        border: 1px solid #f6f6f6;
        tr {
            height: 60px;

            &:hover {
                background-color: #f1f1f1;
            }
        }
        td {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: #292929;
            padding-left: 16px;
        }

        td:first-child {
            max-width: 30px;
        }
    }
`;

export const ChannelColumn = styled.td`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 60px;

    svg {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
`;

export const StatusColumn = styled.td<{ status: keyof typeof statusColor }>`
    & > span {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 133%;
        text-align: center;
        color: ${({ status }) => statusColor[status]};

        padding: 6px 8px;
        background: ${({ status }) => lighten(0.45, statusColor[status])};
        border: ${({ status }) =>
            `1px solid ${lighten(0.65, statusColor[status])}`};
        border-radius: 4px;
        max-width: 8rem;
    }
`;
