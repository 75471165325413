import React from 'react';
import { Container, Content, Download, Row } from './styles';
import { MdDownload } from 'react-icons/md';
import {
    BsFileEarmark,
    BsFileWord,
    BsFileZip,
    BsFiletypeCsv,
    BsFiletypePdf,
    BsFiletypeTxt
} from 'react-icons/bs';
import { FileType } from 'services/contacts/types';

type DocumentProps = {
    src: string;
    fileName?: string;
    fileSize?: string;
    fileType?: FileType;
    darkMode?: boolean;
};

const Document: React.FC<DocumentProps> = ({
    src,
    fileName,
    fileSize,
    fileType,
    darkMode
}) => {
    const downloadImage = () => {
        const anchor = document.createElement('a');
        anchor.href = src;
        anchor.target = '_blank';
        anchor.download = fileName ?? '';
        anchor.click();
    };

    const fileTypeToMime = {
        'application/pdf': 'PDF',
        'application/vnd.rar': 'RAR',
        'application/zip': 'ZIP',
        'application/msword': 'DOC',
        'text/csv': 'CSV',
        'text/plain': 'TXT'
    };

    const fileTypeIcon = {
        'application/pdf': <BsFiletypePdf />,
        'application/vnd.rar': <BsFileZip />,
        'application/zip': <BsFileZip />,
        'application/msword': <BsFileWord />,
        'text/csv': <BsFiletypeCsv />,
        'text/plain': <BsFiletypeTxt />
    };
    return (
        <Container darkMode={darkMode} onClick={downloadImage}>
            {fileType ? (
                fileTypeIcon[fileType] || <BsFileEarmark />
            ) : (
                <BsFileEarmark />
            )}
            <Content>
                <Row>{fileName}</Row>
                {fileSize && (
                    <Row small>
                        {fileSize} - {fileType && fileTypeToMime[fileType]}
                    </Row>
                )}
            </Content>
            <Download onClick={downloadImage}>
                <MdDownload />
            </Download>
        </Container>
    );
};

export default Document;
