import React from 'react';
import dayjs from 'dayjs';
import 'rc-image/assets/index.css';

import {
    Body,
    Container,
    Footer,
    MessageText,
    Time,
    ContainerInfo,
    InfoText
} from './styles';
import ImageViewer from 'Components/Molecules/ImageViewer';
import Audio from 'Components/Molecules/Audio';
import Document from 'Components/Molecules/Document';
import { ChatContactMessages, ContentType } from 'services/contacts/types';
import Placeholder from 'assets/images/chat/placeholder.png';
import User from 'assets/images/chat/user.png';
import Avatar from 'Components/Atoms/Avatar';

const MessageItem: React.FC<ChatContactMessages> = ({
    createdAt,
    sentBy,
    owner,
    source,
    messageBody,
    id
}) => {
    const messageType: Record<ContentType, any> = {
        text: <MessageText>{messageBody.text}</MessageText>,
        image: <ImageViewer src={messageBody?.link || Placeholder} />,
        file: (
            <Document
                darkMode={owner}
                src={messageBody.link || ''}
                fileName={messageBody?.fileKey}
                fileSize={messageBody?.fileSize}
                fileType={messageBody?.fileType}
            />
        ),
        audio: <Audio src={messageBody.link || ''} />,
        info: (
            <>
                <h6>{messageBody?.text}</h6>
            </>
        ),
        textLink: <></>,
        video: <></>
    };
    if (messageBody.contentType === 'info')
        return (
            <ContainerInfo>
                <InfoText>
                    {messageBody?.text}
                    {' - '}
                    {dayjs(createdAt).format('DD/MM/YYYY hh:mm')}
                </InfoText>
            </ContainerInfo>
        );

    return (
        <Container owner={owner || sentBy === 'bot'}>
            {(sentBy === 'contact' || sentBy === 'user') && (
                <Avatar src={source?.avatar || User} size="32px" />
            )}
            <Body>
                {messageType[messageBody.contentType]}
                <Footer>
                    <Time>{dayjs(createdAt).format('DD/MM/YYYY hh:mm')}</Time>
                    {source?.channel && <Time>{source.channel}</Time>}
                </Footer>
            </Body>
        </Container>
    );
};

export default MessageItem;
