import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Login } from '../Pages';
import Register from 'Pages/Register';
import ForgotPassword from 'Pages/ForgotPassword';
import Chat from 'Pages/Chat';
import Contacts from 'Pages/Contacts';
import Messages from 'Pages/Contacts/Messages';
import ModalContactForm from 'Pages/Contacts/ModalContactForm';
import Dashboard from 'Pages/Dashboard';
import TeamDetails from 'Pages/TeamDetails';
import Settings from 'Pages/Settings';
import MessageForm from 'Pages/Settings/Message/Form';
import Automation from 'Pages/Automation';
import AutomationBoard from 'Pages/AutomationBoard';
import ResetPassword from 'Pages/ResetPassword';
import ModalUserForm from 'Pages/Settings/Users/ModalUserForm';
import ModalGroupForm from 'Pages/Settings/Groups/ModalGroupForm';

const RoutesComponent: React.FC = () => {
    const location = useLocation();
    const background = location.state && location.state.background;
    return (
        <>
            <Routes location={background || location}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route
                    path="reset-password/:token"
                    element={<ResetPassword />}
                />
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="chat" element={<Chat />} />
                <Route path="contacts">
                    <Route index element={<Contacts />} />
                    <Route path=":contactId" element={<Messages />} />
                </Route>

                <Route
                    path="contacts/form/:contactId?"
                    element={<ModalContactForm />}
                />

                <Route path="dashboard">
                    <Route index element={<Dashboard />} />
                    <Route path=":memberId" element={<TeamDetails />} />
                </Route>
                <Route path="automation">
                    <Route index element={<Automation />} />
                    <Route
                        path="board/:automationId?"
                        element={<AutomationBoard />}
                    />
                </Route>
                <Route path="settings">
                    <Route index element={<Settings />} />

                    <Route
                        path="message-model/form/:messageId?"
                        element={<MessageForm />}
                    />

                    <Route
                        path="team-members/form/:userId?"
                        element={<ModalUserForm />}
                    />

                    <Route
                        path="groups/form/:groupId?"
                        element={<ModalGroupForm />}
                    />
                </Route>
            </Routes>
            {background && (
                <Routes>
                    <Route
                        path="contacts/form/:contactId?"
                        element={<ModalContactForm />}
                    />

                    <Route
                        path="settings/team-members/form/:userId?"
                        element={<ModalUserForm />}
                    />
                    <Route
                        path="settings/groups/form/:groupId?"
                        element={<ModalGroupForm />}
                    />
                </Routes>
            )}
        </>
    );
};

export default RoutesComponent;
