/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    type InputHTMLAttributes,
    useCallback,
    useRef,
    useState
} from 'react';
import { useTheme } from 'styled-components';
import { FiAlertCircle } from 'react-icons/fi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import MaskedInput from 'react-text-mask';
import InputMask from 'react-input-mask';
import { type InputProps, currencyMask } from './types';

import { Container, Error, InputWrapper, Label } from './styles';

const InputTime: React.FC<InputProps> = ({
    name,
    label,
    error,
    mask,
    currency,
    value,
    marginVertical,
    marginHorizontal,
    showPassword,
    backgroundWhite,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    register,
    beforeMaskedStateChange,
    type,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const theme = useTheme();

    const handleInputFocus = useCallback(() => {
        setIsFocused(() => true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(() => false);
        setIsFilled(Boolean(inputRef.current?.value));
    }, []);

    const { onChange } = register;

    return (
        <Container
            marginHorizontal={marginHorizontal}
            marginVertical={marginVertical}
            width={rest?.width}
        >
            <Label>
                {label}
                {error && (
                    <Error title={error}>
                        <FiAlertCircle
                            color={theme.colors.error.pure}
                            size={20}
                        />
                    </Error>
                )}
            </Label>
            <InputWrapper
                backgroundWhite={backgroundWhite}
                isErrored={!!error}
                isFilled={isFilled}
                isFocused={isFocused}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                width={rest?.width}
            >
                <input
                    id={name}
                    name={name}
                    value={value}
                    ref={inputRef}
                    // type={'time'}
                    // onChange={(e) => {
                    //     console.log(e.target.value);
                    //     onChange(e);
                    // }}
                    {...rest}
                    {...register}
                />
                {JSON.stringify(value)}
            </InputWrapper>
        </Container>
    );
};

export default InputTime;
