import RegisterImage from 'assets/images/register/register.webp';

export const content = {
    image: RegisterImage,
    step1: {
        register: {
            text: 'Ainda não tem conta?',
            link: {
                text: 'Faça o Login',
                url: '/login'
            }
        },
        title: 'Cadastre-se gratuitamente',
        rules: ['Sem cartão de crédito obrigatório', 'Cancele quando quiser'],
        fields: {
            email: {
                label: 'Email',
                placeholder: 'Seu email'
            },
            password: {
                label: 'Senha',
                placeholder: 'Sua senha',
                validations: {
                    PASSWORD_CAMELCASE_CHECK: 'Letras maiúsculas e minúsculas',
                    PASSWORD_NUMERIC_CHECK: 'Caracteres numéricos',
                    PASSWORD_SIZE_CHECK: 'Mínimo de 7 caracteres'
                }
            }
        },
        submit: 'Cadastrar',
        social: {
            title: 'Ou use as redes',
            button: 'Cadastre-se com o Google'
        },
        disclaimer:
            'Ao se cadastrar, você concorda com os [Termos](http://localhost) e [Política de privacidade](http://localhost) da OMNI'
    },
    step2: {
        back: 'Voltar',
        title: 'Conte mais sobre você',

        persornalForm: {
            title: 'Dados pessoais',
            firstName: {
                label: 'Primeiro nome',
                placeholder: 'Ex: Paulo'
            },
            lastName: {
                label: 'Sobrenome',
                placeholder: 'Ex: Rodrigues de Oliveira'
            },
            phone: {
                label: 'Telefone',
                placeholder: '(00) 00000-0000'
            }
        },
        companyForm: {
            title: 'Sobre sua empresa',
            companyName: {
                label: 'Nome da sua empresa',
                placeholder: 'Nome da sua empresa'
            },
            website: {
                label: 'Site da empresa (Caso tenha)',
                placeholder: 'Site da empresa'
            },
            companySize: {
                label: 'Tamanho',
                placeholder: 'Selecione',
                options: [
                    { label: '1-10', value: '1-10' },
                    { label: '11-30', value: '11-30' },
                    { label: '31-50', value: '31-50' },
                    { label: '50-100', value: '50-100' }
                ]
            },
            segment: {
                label: 'Segmento',
                placeholder: 'Selecione',
                options: [
                    { label: 'Agronegócio', value: 'Agronegócio' },
                    { label: 'Aviação', value: 'Aviação' },
                    { label: 'Construção', value: 'Construção' },
                    { label: 'Consultoria', value: 'Consultoria' },
                    { label: 'Bens de Consumo', value: 'Bens de Consumo' },
                    { label: 'Educação', value: 'Educação' },
                    {
                        label: 'Serviços Financeiros',
                        value: 'Serviços Financeiros'
                    },
                    {
                        label: 'Governo ou Gestão Pública',
                        value: 'Governo ou Gestão Pública'
                    },
                    { label: 'Saúde', value: 'Saúde' },
                    {
                        label: 'Escritórios de Advocacia e Serviços',
                        value: 'Escritórios de Advocacia e Serviços'
                    },
                    {
                        label: 'Lazer, Viagens e Turismo',
                        value: 'Lazer, Viagens e Turismo'
                    },
                    {
                        label: 'Logística e Cadeia de Suprimentos',
                        value: 'Logística e Cadeia de Suprimentos'
                    },
                    {
                        label: 'Manufatura e Produção',
                        value: 'Manufatura e Produção'
                    },
                    {
                        label: 'Mídia, Marketing e Publicidade',
                        value: 'Mídia, Marketing e Publicidade'
                    },
                    { label: 'Farmacêutico', value: 'Farmacêutico' },
                    {
                        label: 'Serviços Profissionais',
                        value: 'Serviços Profissionais'
                    },
                    {
                        label: 'Varejo e Atacado',
                        value: 'Varejo e Atacado'
                    },
                    { label: 'Tecnologia', value: 'Tecnologia' },
                    {
                        label: 'Telecomunicações',
                        value: 'Telecomunicações'
                    },
                    {
                        label: 'Serviços Públicos, Energia e Produtos Químicos',
                        value: 'Serviços Públicos, Energia e Produtos Químicos'
                    }
                ]
            }
        },
        submit: 'Continuar'
    },
    step3: {
        back: 'Voltar',
        title: 'Que canais de comunicação você atualmente usa',
        subtitle: 'Você pode selecionar mais de um se necessário.',
        anyOption: 'Não utilizo nenhuma dessas ferramentas',
        submit: 'Começar a usar'
    }
};
