import React, { useCallback, useEffect } from 'react';
import { Container, Header, Title, CloseButton, Row, Wrapper } from './styles';
import { modalContent } from '../content';
import { MdClose } from 'react-icons/md';
import Input from 'Components/Atoms/Input';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputPhone from 'Components/Atoms/InputPhone';
import Select from 'Components/Atoms/Select';
import Divider from 'Components/Atoms/Divider';
import Button from 'Components/Atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useContactStore } from 'store/useContacts';

const ModalContactForm: React.FC = () => {
    const { newTitle, editTitle, actions, fields, platformTitle } =
        modalContent;
    const navigate = useNavigate();
    const { contactId } = useParams();
    const schema = z.object({
        firstName: z.string().min(1, 'Campo obrigatório'),
        lastName: z.string().min(1, 'Campo obrigatório'),
        email: z.string().email('Email inválido!').min(1, 'Campo obrigatório'),
        phoneNumber: z.string().optional(),
        groups: z.string().optional(),
        whatsapp: z.string().min(1, 'Campo obrigatório'),
        sms: z.string().min(1, 'Campo obrigatório'),
        instagram: z.string().min(1, 'Campo obrigatório'),
        facebook: z.string().min(1, 'Campo obrigatório')
    });

    const {
        groups,
        getGroups,
        getContactDetails,
        updateContact,
        createContact
    } = useContactStore();
    const {
        register,
        control,
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isValid, errors }
    } = useForm<{
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        groups: string;
        whatsapp: string;
        sms: string;
        instagram: string;
        facebook: string;
    }>({
        resolver: zodResolver(schema),
        mode: 'onChange'
    });

    const onSubmit = handleSubmit(async (data) => {
        let result = false;
        if (contactId) {
            result = await updateContact(Number(contactId), {
                ...data,
                groups: Number(data.groups)
            });
        } else {
            result = !!(await createContact({
                ...data,
                groups: Number(data.groups)
            }));
        }
        if (result) {
            navigate(-1);
        }
    });

    const recoveryContactData = useCallback(
        async (contactId: number) => {
            const response = await getContactDetails({ contactId: contactId });
            if (response) {
                reset({
                    ...response,
                    groups: response?.groups[0]?.id
                        ? response?.groups[0]?.id.toString()
                        : '',
                    whatsapp:
                        response?.contactChannels?.find(
                            (item) => item.type === 'whatsapp'
                        )?.identifier || '',
                    sms:
                        response?.contactChannels?.find(
                            (item) => item.type === 'sms'
                        )?.identifier || '',
                    instagram:
                        response?.contactChannels?.find(
                            (item) => item.type === 'instagram'
                        )?.identifier || '',
                    facebook:
                        response?.contactChannels?.find(
                            (item) => item.type === 'messenger'
                        )?.identifier || ''
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reset]
    );

    useEffect(() => {
        if (contactId) {
            recoveryContactData(Number(contactId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId]);

    useEffect(() => {
        getGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Container>
                <Header>
                    <Title>{contactId ? editTitle : newTitle}</Title>
                    <CloseButton onClick={() => navigate(-1)}>
                        <MdClose size={24} />
                    </CloseButton>
                </Header>

                <form onSubmit={onSubmit}>
                    <Row>
                        <Input
                            name="firstName"
                            id="firstName"
                            label={fields.firstName.label}
                            placeholder={fields.firstName.placeholder}
                            register={register('firstName')}
                            error={errors.firstName?.message}
                        />
                        <Input
                            name="lastName"
                            id="lastName"
                            label={fields.lastName.label}
                            placeholder={fields.lastName.placeholder}
                            register={register('lastName')}
                            error={errors.lastName?.message}
                        />
                    </Row>
                    <Row>
                        <Input
                            name="email"
                            id="email"
                            label={fields.email.label}
                            placeholder={fields.email.placeholder}
                            register={register('email')}
                            error={errors.email?.message}
                        />
                    </Row>
                    <Row>
                        <InputPhone
                            name="phoneNumber"
                            id="phoneNumber"
                            control={control}
                            label={fields.phone.label}
                            placeholder={fields.phone.placeholder}
                            error={errors.phoneNumber?.message}
                        />
                    </Row>
                    <Row>
                        <Select
                            name="groups"
                            id="groups"
                            value={getValues('groups')}
                            onChange={(e) =>
                                setValue('groups', e.currentTarget.value)
                            }
                            control={control}
                            label={fields.group.label}
                            error={errors.groups?.message}
                            options={groups.map((group) => ({
                                label: group.name,
                                value: group.id
                            }))}
                        />
                    </Row>

                    <Divider />

                    <Title>{platformTitle}</Title>
                    <Row>
                        <Input
                            name="whatsapp"
                            id="whatsapp"
                            label={fields.whatsapp.label}
                            placeholder={fields.whatsapp.placeholder}
                            error={errors.whatsapp?.message}
                            register={register('whatsapp')}
                            mask="(99) 99999-9999"
                        />
                        <Input
                            name="sms"
                            id="sms"
                            label={fields.sms.label}
                            placeholder={fields.sms.placeholder}
                            error={errors.sms?.message}
                            register={register('sms')}
                            mask="(99) 99999-9999"
                        />
                    </Row>
                    <Row>
                        <Input
                            name="instagram"
                            id="instagram"
                            label={fields.instagram.label}
                            error={errors.instagram?.message}
                            register={register('instagram')}
                        />
                        <Input
                            name="facebook"
                            id="facebook"
                            label={fields.facebook.label}
                            error={errors.facebook?.message}
                            register={register('facebook')}
                        />
                    </Row>

                    <Row>
                        <Button
                            outline
                            rounded
                            size={'SMALL'}
                            onClick={() => navigate(-1)}
                        >
                            {actions.cancel}
                        </Button>
                        <Button
                            type="submit"
                            rounded
                            size={'SMALL'}
                            disabled={!isValid}
                        >
                            {actions.save}
                        </Button>
                    </Row>
                </form>
            </Container>
        </Wrapper>
    );
};

export default ModalContactForm;
