import React, { useCallback, useEffect } from 'react';

import { Container, Actions, Header, TableWrapper } from './styles';
import Input from 'Components/Atoms/Input';
import { FiSearch } from 'react-icons/fi';
import Button from 'Components/Atoms/Button';
import SelectCheckbox from 'Components/Atoms/SelectCheckbox';
import { useForm } from 'react-hook-form';
import TableRowSelection from 'Components/Organisms/TableRowSelection';
import { useContactStore } from 'store/useContacts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettingsStore } from 'store';
import dayjs from 'dayjs';

const Users: React.FC = () => {
    const { setValue, getValues, register } = useForm<{
        filter: string;
        teams: number[];
        roles: number[];
    }>();

    const location = useLocation();

    const { teamMembers, getTeamMembers } = useContactStore();
    const { teams, roles, getTeams, getRoles } = useSettingsStore();

    const navigate = useNavigate();

    // const handleOpenContact = useCallback(
    //     (id: number) => {
    //         navigate(`/team-member/${id}`);
    //     },
    //     [navigate]
    // );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'name'
            },
            {
                Header: 'Posição',
                accessor: 'role'
            },
            {
                Header: 'Times',
                accessor: 'team'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Setor',
                accessor: 'office',
                type: 'office'
            },
            {
                Header: 'Criado em',
                accessor: 'createdAt'
            },
            {
                Header: 'Ações',
                accessor: 'actions',
                type: 'actions'
            }
        ],
        []
    );

    const handleSearchTeamMember = useCallback(() => {
        const params = getValues();
        getTeamMembers({
            filter: params.filter || '',
            roles: params.roles || '',
            teams: params.teams || ''
        });
    }, [getTeamMembers, getValues]);

    // const handleConfirmDelete = async (userId: number) => {
    //     confirmDialog({
    //         title: 'Remover usuário',
    //         subtitle: 'Deseja realmente remover este usuário?',
    //         acceptLabel: 'Sim',
    //         denyLabel: 'Não',
    //         acceptFunction: () => deleteTeamMember(userId)
    //     });
    // };

    useEffect(() => {
        getTeamMembers();
        getTeams();
        getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Header>
                <h1>
                    Usuários
                    <small>({teamMembers?.totalItems})</small>
                </h1>
            </Header>
            <Actions>
                <form>
                    <div id="search">
                        <Input
                            name="filter"
                            leftIcon={FiSearch}
                            placeholder="Busque por nome ou email"
                            register={register('filter')}
                        />
                        <Button
                            width="110px"
                            height="48px"
                            size={'SMALL'}
                            rounded
                            marginHorizontal="8px"
                            type="button"
                            onClick={() => handleSearchTeamMember()}
                        >
                            Buscar
                        </Button>
                    </div>
                    <SelectCheckbox
                        name="teams"
                        placeholder="Todos os times"
                        options={teams.map(({ id, name }) => ({
                            value: id,
                            label: name
                        }))}
                        onChange={(options) =>
                            setValue(
                                'teams',
                                options.map((option) => option.value)
                            )
                        }
                        onBlur={() => handleSearchTeamMember()}
                    />
                    <SelectCheckbox
                        name="roles"
                        placeholder="Todas as posições"
                        options={roles.map(({ id, name }) => ({
                            label: name,
                            value: id
                        }))}
                        onChange={(options) =>
                            setValue(
                                'roles',
                                options.map((option) => option.value)
                            )
                        }
                        onBlur={() => handleSearchTeamMember()}
                    />
                </form>
                <Button
                    width="138px"
                    height="48px"
                    size={'SMALL'}
                    rounded
                    onClick={() =>
                        navigate(`team-members/form`, {
                            state: { background: location }
                        })
                    }
                >
                    Novo usuário
                </Button>
            </Actions>

            <TableWrapper>
                <TableRowSelection
                    columns={columns}
                    totalItems={teamMembers.totalItems}
                    handleNextPage={() => null}
                    data={teamMembers.users.map((teamMember) => ({
                        ...teamMember,
                        role: teamMember.role.name,
                        name: `${teamMember.firstName} ${teamMember.lastName}`,
                        team: teamMember?.team?.name || '',
                        createdAt: dayjs(teamMember.createdAt).format(
                            'DD/MM/YY'
                        )
                    }))}
                    actions={{
                        edit: (id) =>
                            navigate(`team-members/form/${id}`, {
                                state: { background: location }
                            })
                        // delete: (id) => handleConfirmDelete(id)
                    }}
                />
            </TableWrapper>
        </Container>
    );
};

export default Users;
