import styled from 'styled-components';

export const Container = styled.div`
    max-width: 240px;
    width: 100%;
    position: relative;
`;

export const CurrentOwner = styled.div`
    display: flex;
    align-items: center;
    max-width: 240px;
    width: 100%;
    padding: 8px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    cursor: pointer;

    p {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
        padding-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const RadioOwner = styled.div`
    width: 100%;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 30px 20px;
    position: absolute;

    input {
        padding: 12px;
    }

    svg {
        margin-left: 12px;
    }
`;

export const FieldLabel = styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #292929;
    margin-bottom: 18px;
`;

export const ChoiceLabel = styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    line-height: 98%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #292929;
    padding: 20px 0;
    cursor: pointer;
`;

export const CollapseOwners = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const OwnerItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    padding: 8px 4px;
    border-radius: 4px;
    transition: all 0.3s ease;

    img {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 2.8rem;
        margin-right: 8px;
        object-fit: cover;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
        transition: all 0.3s ease;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.brand.pure};

        p {
            color: ${({ theme }) => theme.colors.high.pure};
        }
    }
`;
