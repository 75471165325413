import React from 'react';
import { Container, Content, SearchMessage, Title } from './styles';
import SelectOwner from 'Components/Organisms/SelectOwner';
// import CheckOwner from 'Components/Organisms/CheckOwner';
import { useContactStore } from 'store/useContacts';
import { FiSearch } from 'react-icons/fi';
import TokenService from 'services/token';
import Button from 'Components/Atoms/Button';

const MessageHeader: React.FC = () => {
    const {
        currentContactDetails,
        teamMembers,
        updateContactOwner,
        setSearchMessageEnabled,
        endChat
    } = useContactStore();

    const currentContactOwner = () => {
        const owner = [
            ...teamMembers.users,
            TokenService.getAuthData()?.user as any
        ].find((item) => item?.id === currentContactDetails?.owner?.id);

        return {
            id: owner?.id || 0,
            name: owner
                ? `${owner?.firstName} ${owner?.lastName}`
                : 'Sem responsável',
            avatar: owner?.avatar || ''
        };
    };

    return (
        <Container>
            <Title>{`${currentContactDetails?.firstName} ${currentContactDetails?.lastName}`}</Title>
            <Content>
                {/* <CheckOwner
                    addUsers={(data) => {
                        if (currentContactDetails?.id) {
                            updateChatUsers(
                                currentContactDetails.chatTicket.id,
                                data
                            );
                        }
                    }}
                    users={teamMembers.users.map((item) => ({
                        id: item.id,
                        name: `${item?.firstName} ${item?.lastName}`,
                        avatar: item.avatar
                    }))}
                /> */}

                {currentContactDetails?.status !== 'conclused' && (
                    <>
                        <Button
                            size={'SMALL'}
                            rounded
                            style={{ padding: '0 10px', fontSize: '12px' }}
                            onClick={() => {
                                if (currentContactDetails?.chatTicket.id) {
                                    endChat(
                                        currentContactDetails?.chatTicket.id
                                    );
                                }
                            }}
                        >
                            Encerrar Atendimento
                        </Button>
                        <SelectOwner
                            users={teamMembers.users.map((item) => ({
                                id: item.id,
                                name: `${item?.firstName} ${item?.lastName}`,
                                avatar: item.avatar
                            }))}
                            currentOwner={currentContactOwner()}
                            setOwner={(data) => {
                                if (currentContactDetails?.id) {
                                    updateContactOwner({
                                        contactId: currentContactDetails?.id,
                                        ownerId: data,
                                        channelCommunication:
                                            currentContactDetails.channelCommunicationSelected
                                    });
                                }
                            }}
                        />
                    </>
                )}

                <SearchMessage onClick={() => setSearchMessageEnabled()}>
                    <FiSearch size={20} />
                </SearchMessage>
            </Content>
        </Container>
    );
};

export default MessageHeader;
