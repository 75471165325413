import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';
import { useDashboard } from 'store';

type ConsumedWindowProps = {
    period: string;
};

import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

const ConsumedWindow: React.FC<ConsumedWindowProps> = ({ period }) => {
    const { getConsumedWindows } = useDashboard();
    const [data, setData] = useState<{ quantity: number; total: number }>({
        quantity: 0,
        total: 0
    });

    const handleAttendanceData = useCallback(async () => {
        const result = await getConsumedWindows({ period });

        setData({
            total: result?.total || 0,
            quantity: result?.quantity || 0
        });
    }, [getConsumedWindows, period]);

    const graphData = {
        labels: ['Total', 'Valor'],
        datasets: [
            {
                data: [data.total, data.quantity],
                backgroundColor: ['#0062FF', '#E8EFFF'],
                borderColor: ['#0062FF', '#E8EFFF'],
                borderWidth: 1
            }
        ]
    };

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <h3>Janelas consumidas</h3>
            <div>
                <Doughnut
                    data={graphData}
                    width={'20px'}
                    height={'20px'}
                    options={{
                        cutout: '90%'
                    }}
                />

                <p>
                    {data.quantity}/<small>{data.total}</small>
                </p>
            </div>
        </Container>
    );
};

export default ConsumedWindow;
