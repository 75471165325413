import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3rem;
    width: 100%;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;
