import { useRef, useState } from 'react';

export const useAudioRecorder = () => {
    const [permission, setPermission] = useState(false);
    const mediaRecorder = useRef<any>(null);
    const [recordingStatus, setRecordingStatus] = useState('');
    const [stream, setStream] = useState<MediaStream>();
    const [audio, setAudio] = useState<Blob>();
    const [audioChunks, setAudioChunks] = useState<any[]>([]);

    const getMicrophonePermission = async () => {
        if ('MediaRecorder' in window) {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false
                });
                setPermission(true);
                setStream(mediaStream);
            } catch (err: any) {
                console.error(err?.message);
            }
        } else {
            alert('The MediaRecorder API is not supported in your browser.');
        }
    };

    const startRecording = async () => {
        if (stream) {
            setRecordingStatus('recording');
            const media = new MediaRecorder(stream);

            mediaRecorder.current = media;

            mediaRecorder.current.start();

            const localAudioChunks: any[] | ((prevState: never[]) => never[]) =
                [];

            mediaRecorder.current.ondataavailable = (event: {
                data: { size: number };
            }) => {
                if (typeof event.data === 'undefined') return;
                if (event.data.size === 0) return;
                localAudioChunks.push(event.data);
            };

            setAudioChunks(localAudioChunks);
        }
    };

    const stopRecording = () => {
        mediaRecorder.current.stop();

        mediaRecorder.current.onstop = () => {
            const audioData = new Blob(audioChunks, {
                type: 'audio/wav; codecs=0'
            });
            const reader = new FileReader();
            reader.readAsDataURL(audioData);

            reader.onloadend = function () {
                const base64data = reader.result as unknown as Blob;
                setAudio(base64data);
                setRecordingStatus('inactive');
                setAudioChunks([]);
            };
        };
    };

    const discartRecording = () => {
        setAudio(new Blob());
        setRecordingStatus('');
        setAudioChunks([]);
    };

    return {
        getMicrophonePermission,
        startRecording,
        stopRecording,
        discartRecording,
        permission,
        recordingStatus,
        audio
    };
};
