import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 25% 50% 20%;
    box-sizing: border-box;
    align-items: center;
    justify-items: center;
    column-gap: 2.4rem;
    width: 100%;

    grid-template-areas:
        'details messages groups'
        'details messages groups'
        'details messages groups';

    @media (max-width: 1024px) {
        grid-template-columns: 100%;
        grid-template-areas:
            'details'
            'groups'
            'messages';
    }
`;
