import { toast } from 'react-toastify';
import ContactsService from 'services/contacts';
import {
    Channels,
    ChatContactDetails,
    ChatContactMessages,
    ColContactResult,
    ContactChannels,
    ContactParams,
    GroupsResult,
    IChatMessage,
    ListChatContact,
    RowContactModel,
    TeamMember,
    TeamMemberModel
} from 'services/contacts/types';
import { create } from 'zustand';

export interface createContactSliceProps {
    openedContacts: ListChatContact;
    closedContacts: ListChatContact;
    rowContacts: RowContactModel;
    colContacts: ColContactResult;
    teamMembers: TeamMember;
    groups: GroupsResult[];
    currentContactDetails: ChatContactDetails | null;
    currentContactMessages: ChatContactMessages[] | [];
    loadingContacts: boolean;
    loadingMessages: boolean;
    loadingDetails: boolean;
    searchMessageEnabled: boolean;
    addContactGroup: (params: { contactId: number; groupId: number }) => void;
    removeContactGroup: (params: {
        contactId: number;
        groupId: number;
    }) => void;
    clearContactDetails: () => void;
    getContactChannelsDetails: (
        channel: string
    ) => Promise<ContactChannels | undefined>;
    getGroups: () => void;
    endChat: (chatTicket: number) => void;
    createTeamMember: (params: TeamMemberModel) => void;
    updateTeamMember: (userId: string, params: TeamMemberModel) => void;
    deleteTeamMember: (memberId: number) => void;
    getUserById: (memberId: number) => any;
    getTeamMembers: (params?: {
        filter: string;
        teams: number[];
        roles: number[];
    }) => void;
    getOpenedContacts: () => void;
    getClosedContacts: () => void;
    getRowContacts: (params?: {
        filter?: string;
        owners?: number[];
        groups?: string[];
        rowsByPage?: number;
        page?: number;
    }) => Promise<RowContactModel>;
    getColContacts: (params?: {
        filter?: string;
        channels?: string[];
        value?: string;
    }) => void;
    reverseContactsLists: () => void;
    getContactDetails: (props: {
        contactId: number;
        saveState?: boolean;
        channelCommunication?: Channels;
    }) => Promise<ChatContactDetails | null>;
    getContactMessages: (contactId: number) => void;
    updateContactOwner: (props: {
        contactId: number;
        ownerId: number;
        channelCommunication?: Channels;
    }) => void;
    updateChatUsers: (chatId: number, users: number[]) => void;
    updateContact: (
        contactId: number,
        params: ContactParams
    ) => Promise<boolean>;
    deleteContact: (contactId: number) => void;
    createContact: (
        params: ContactParams
    ) => Promise<ChatContactDetails | boolean>;
    setSearchMessageEnabled: () => void;
    sendMessage: (message: IChatMessage) => void;
    clearCurrentContact: () => void;
}

export const useContactStore = create<createContactSliceProps>()(
    (set, get) => ({
        openedContacts: {
            contactsResponse: [],
            quantity: 0
        },
        closedContacts: {
            contactsResponse: [],
            quantity: 0
        },
        currentContactMessages: [],
        teamMembers: {
            page: 0,
            totalItems: 0,
            users: []
        },
        groups: [],
        rowContacts: { rows: [], page: 1, totalItems: 0 },
        colContacts: {
            rows: {
                blocked: [],
                done: [],
                inAttendance: [],
                opened: [],
                underAnalysis: [],
                botAttendance: [],
                conclused: []
            }
        },
        currentContactDetails: null,
        loadingContacts: false,
        loadingMessages: false,
        loadingDetails: false,
        searchMessageEnabled: false,
        clearCurrentContact: () => {
            set(() => ({
                currentContactMessages: [],
                currentContactDetails: null
            }));
        },
        sendMessage: async (message) => {
            try {
                const currentContactDetails = get().currentContactDetails;
                if (currentContactDetails) {
                    ContactsService.sendContactMessage(
                        message,
                        currentContactDetails
                    );
                }
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        setSearchMessageEnabled: () => {
            set((old) => ({ searchMessageEnabled: !old.searchMessageEnabled }));
        },
        endChat: async (chatTicket) => {
            try {
                await ContactsService.endChat(chatTicket);
                toast.success('Atendimento encerrado com sucesso!');
                get().getOpenedContacts();
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        updateChatUsers: async (chatId: number, users: number[]) => {
            try {
                await ContactsService.updateChatUsers(chatId, users);
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        updateContactOwner: async ({
            contactId,
            ownerId,
            channelCommunication
        }) => {
            try {
                await ContactsService.patchContactOwner(contactId, ownerId);

                get().getContactDetails({
                    contactId,
                    saveState: true,
                    channelCommunication: channelCommunication
                });
                toast.success('Contato atualizado com sucesso!');
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        addContactGroup: async (params) => {
            try {
                const group = await ContactsService.addContactGroup(params);
                if (group) {
                    set(({ currentContactDetails }) => ({
                        currentContactDetails: currentContactDetails
                            ? {
                                  ...currentContactDetails,
                                  groups: [
                                      ...currentContactDetails.groups,
                                      group
                                  ]
                              }
                            : null
                    }));
                }
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        removeContactGroup: async (params) => {
            try {
                await ContactsService.removeContactGroup(params);
                set(({ currentContactDetails }) => ({
                    currentContactDetails: currentContactDetails
                        ? {
                              ...currentContactDetails,
                              groups: currentContactDetails?.groups.filter(
                                  (group) => group.id !== params.groupId
                              )
                          }
                        : null
                }));
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        updateContact: async (contactId: number, data: ContactParams) => {
            try {
                await ContactsService.updateContact(contactId, data);

                const contacts = await ContactsService.getRowContacts();

                set(() => ({
                    rowContacts: contacts
                }));
                toast.success('Contato atualizado com sucesso!');
                return true;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
                return false;
            }
        },
        deleteContact: async (contactId: number) => {
            try {
                await ContactsService.deleteContact(contactId);
                get().getRowContacts();
                toast.success('Contato removido com sucesso!');
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        createContact: async (data: ContactParams) => {
            try {
                const result = await ContactsService.createContact(data);
                toast.success('Contato adicionado com sucesso!');
                const allContacts = await ContactsService.getRowContacts();
                set(() => ({
                    rowContacts: allContacts
                }));
                return result as ChatContactDetails;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
                return false;
            }
        },
        getUserById: async (id: number) => {
            try {
                const user = await ContactsService.getUserById(id);
                return user;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        createTeamMember: async (params) => {
            try {
                await ContactsService.createTeamMember(params);
                const result = await ContactsService.getTeamMembers();
                set(() => ({
                    teamMembers: result
                }));
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        updateTeamMember: async (userId, params) => {
            try {
                await ContactsService.updateTeamMember(userId, params);
                const result = await ContactsService.getTeamMembers();
                set(() => ({
                    teamMembers: result
                }));
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        deleteTeamMember: async (memberId) => {
            try {
                await ContactsService.deleteTeamMember(memberId);
                get().getTeamMembers();
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        getContactChannelsDetails: async (channel) => {
            try {
                const response =
                    await ContactsService.getContactChannelsDetails(channel);
                return response;
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        getTeamMembers: async (params) => {
            try {
                const response = await ContactsService.getTeamMembers(params);
                set(() => ({ teamMembers: response }));
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        getGroups: async () => {
            try {
                const response = await ContactsService.getGroups();
                set(() => ({ groups: response }));
            } catch (error: any) {
                set(() => ({ groups: [] }));
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        getOpenedContacts: async () => {
            try {
                set(() => ({ loadingContacts: true }));

                const response = await ContactsService.getOpenedContacts();
                set(() => ({
                    openedContacts: response,
                    loadingContacts: false
                }));
            } catch (error: any) {
                set(() => ({
                    openedContacts: {
                        contactsResponse: [],
                        quantity: 0
                    },
                    loadingContacts: false
                }));
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        getColContacts: async (params) => {
            try {
                set(() => ({ loadingContacts: true }));

                const response = await ContactsService.getColContacts(params);
                set(() => ({
                    colContacts: response,
                    loadingContacts: false
                }));
            } catch (error: any) {
                set(() => ({
                    colContacts: {
                        rows: {
                            blocked: [],
                            done: [],
                            inAttendance: [],
                            opened: [],
                            underAnalysis: [],
                            botAttendance: [],
                            conclused: []
                        }
                    },
                    loadingContacts: false
                }));
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        getRowContacts: async (params) => {
            try {
                set(() => ({ loadingContacts: true }));

                const response = await ContactsService.getRowContacts(params);
                set(() => ({
                    rowContacts: response,
                    loadingContacts: false
                }));
                return response as RowContactModel;
            } catch (error: any) {
                set(() => ({
                    rowContacts: { rows: [], page: 1, totalItems: 0 },
                    loadingContacts: false
                }));
                toast.error(error?.message || error, { toastId: 'contact' });
                return { rows: [], page: 0, totalItems: 0 };
            }
        },
        getClosedContacts: async () => {
            try {
                set(() => ({ loadingContacts: true }));
                const response = await ContactsService.getClosedContacts();
                set(() => ({
                    closedContacts: response,
                    loadingContacts: false
                }));
            } catch (error: any) {
                set(() => ({
                    closedContacts: {
                        quantity: 0,
                        contactsResponse: []
                    },
                    loadingContacts: false
                }));
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        reverseContactsLists: async () => {
            set(({ openedContacts, closedContacts }) => ({
                closedContacts: {
                    quantity: closedContacts.quantity,
                    contactsResponse: closedContacts.contactsResponse.reverse()
                },
                openedContacts: {
                    quantity: openedContacts.quantity,
                    contactsResponse: openedContacts.contactsResponse.reverse()
                }
            }));
        },
        getContactDetails: async ({
            contactId,
            saveState = false,
            channelCommunication
        }) => {
            try {
                set(() => ({ loadingDetails: true }));
                const response = await ContactsService.getContactDetails(
                    contactId
                );
                if (saveState) {
                    if (channelCommunication) {
                        response!.channelCommunicationSelected =
                            channelCommunication;
                    }
                    set(() => ({
                        currentContactDetails: response
                    }));
                }
                set(() => ({
                    loadingDetails: false
                }));

                return response as ChatContactDetails;
            } catch (error: any) {
                set(() => ({
                    currentContactDetails: null,
                    loadingDetails: false
                }));
                toast.error(error?.message || error, { toastId: 'contact' });
                return null;
            }
        },
        getContactMessages: async (contactId: number) => {
            try {
                set(() => ({ loadingMessages: true }));
                const response = await ContactsService.getContactMessages(
                    contactId
                );
                set(() => ({
                    currentContactMessages: response,
                    loadingMessages: false
                }));
            } catch (error: any) {
                set(() => ({
                    currentContactMessages: [],
                    loadingMessages: false
                }));
                toast.error(error?.message || error, { toastId: 'contact' });
            }
        },
        clearContactDetails: () => {
            set(() => ({
                currentContactDetails: null
            }));
        }
    })
);
