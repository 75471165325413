import styled from 'styled-components';

export const Container = styled.div`
    background: #f5f5f5;
    padding: 2rem 3rem;
    border-radius: 8px;
    border: 1px solid #ea1f1f;
`;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
    margin-bottom: 1rem;
`;

export const Subtitle = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 1.5rem;
`;

export const Button = styled.button`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    background: none;
    border: 1px solid #292929;
    border-radius: 3px;
    padding: 4px 6px;
    margin-right: 10px;
`;
