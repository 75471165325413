import styled from 'styled-components';

export const NodeContainer = styled.div`
    position: relative;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 16px;
    width: 36rem;
    position: relative;

    h1 {
        padding-bottom: 10px;
    }
`;

export const NodeController = styled.div`
    position: absolute;
    padding: 1.6rem;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #cccccc;
    border-radius: 16px;
    width: 100%;
    position: relative;
    z-index: 11;
`;

export const Controllers = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 34rem;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #cccccc;
    border-radius: 16px;
    z-index: 11;
    zoom: 0.8;
    gap: 20px;
`;

export const DeleteButton = styled.button`
    position: absolute;
    top: -15px;
    right: -15px;
    border: 0;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 50px;
    color: red;
`;

const handleStyle = {
    width: '20px',
    height: '20px',
    background: '#0b57f5'
};

export const handleStyleTop = {
    ...handleStyle,
    top: '-12px'
};

export const handleStyleBottom = {
    ...handleStyle,
    bottom: '-12px'
};
