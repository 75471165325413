/* eslint-disable react/display-name */
import LoggedLayout from 'Components/Templates/LoggedLayout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow from 'reactflow';
import 'reactflow/dist/style.css';
import {
    ButtonText,
    DefaultNode,
    Message,
    MessageAttendance,
    MessageButton,
    MessageEnd,
    MessageQuestion,
    SelectGroup
} from './nodeTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { useAutomation } from './context';
import Button from 'Components/Atoms/Button';
import { Controllers, NodeController } from './nodeTypes/styles';
import Select from 'Components/Atoms/Select';
import Input from 'Components/Atoms/Input';
import { useAutomationStore } from 'store';
import { Node, Edge, convertFlowToAPIContract } from './convertToAPI';
import { BoardWrapper } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { AutomationBody } from 'services/automation/types';

const AutomationBoard: React.FC = () => {
    // const { state } = useLocation();
    const navigate = useNavigate();
    const { automationId } = useParams();
    const [nodeTypeSelected, setNodeTypeSelected] = useState('');
    const [automationName, setAutomationName] = useState('');
    // const [automationContactType, setAutomationContactType] = useState(
    //     state?.contactType
    // );

    const { saveAutomation, updateAutomation, getAutomationById } =
        useAutomationStore();

    const nodeTypes = useMemo(
        () => ({
            defaultNode: DefaultNode,
            message: Message,
            messageButton: MessageButton,
            messageEnd: MessageEnd,
            messageQuestion: MessageQuestion,
            messageAttendance: MessageAttendance,
            buttonText: ButtonText,
            selectGroup: SelectGroup
        }),
        []
    );

    const methods = useForm();
    const {
        edges,
        nodes,
        onEdgesChange,
        onNodesChange,
        handleAddNodes,
        onConnect,
        onEdgeUpdate,
        onEdgeUpdateEnd,
        onEdgeUpdateStart,
        setEdges,
        setNodes
    } = useAutomation();

    const handleSaveAutomation = useCallback(() => {
        const data = {
            name: automationName,
            dataFront: JSON.stringify({ edges, nodes }),
            steps: convertFlowToAPIContract({
                edges: edges as unknown as Edge[],
                nodes: nodes as unknown as Node[]
            })
        };
        if (automationId) {
            updateAutomation({
                ...data,
                id: Number(automationId),
                isActived: true
            } as unknown as AutomationBody);
        } else {
            saveAutomation({
                ...data,
                isActived: true
            } as unknown as AutomationBody);
        }
        navigate('/automation');
    }, [
        automationId,
        automationName,
        edges,
        navigate,
        nodes,
        saveAutomation,
        updateAutomation
    ]);

    const handleRecoveryAutomation = useCallback(async () => {
        const automation = await getAutomationById(Number(automationId));
        const dataFront =
            automation?.dataFront && JSON.parse(automation?.dataFront);

        if (dataFront) {
            const { edges, nodes } = dataFront;

            setNodes(nodes);
            setEdges(edges);
            setAutomationName(automation.name);
        }
    }, [automationId, getAutomationById, setEdges, setNodes]);

    const handleClearAutomation = useCallback(() => {
        setNodes([]);
        setEdges([]);
        setAutomationName('');
    }, [setEdges, setNodes]);

    useEffect(() => {
        if (automationId) {
            handleRecoveryAutomation();
        }

        return () => handleClearAutomation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automationId]);

    return (
        <LoggedLayout backgroudInverted>
            <BoardWrapper>
                <FormProvider {...methods}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        snapToGrid={true}
                        nodeTypes={nodeTypes}
                        style={{
                            stroke: '#0b57f5',
                            strokeWidth: 4
                        }}
                        onConnect={onConnect}
                        onEdgeUpdate={onEdgeUpdate}
                        onEdgeUpdateEnd={onEdgeUpdateEnd}
                        onEdgeUpdateStart={onEdgeUpdateStart}
                    >
                        <Controllers>
                            <NodeController>
                                <Input
                                    label="Nome da Automação"
                                    id="name"
                                    name="name"
                                    value={automationName}
                                    onChange={(e) =>
                                        setAutomationName(e.currentTarget.value)
                                    }
                                />
                                {/* <Select
                                    label="Canal da Automação"
                                    id="channel"
                                    name="channel"
                                    marginVertical="20px"
                                    defaultValue={automationChannel}
                                    onChange={(e) =>
                                        setAutomationChannel(
                                            e.currentTarget.value
                                        )
                                    }
                                    options={
                                        channels?.map((item) => ({
                                            label: item.type,
                                            value: item.type
                                        })) || []
                                    }
                                /> */}

                                {/* <Select
                                    id={'contactType'}
                                    name={'contactType'}
                                    label="Tipos de Contatos"
                                    defaultValue={automationContactType}
                                    onChange={(e) =>
                                        setAutomationContactType(
                                            e.currentTarget.value
                                        )
                                    }
                                    options={[
                                        {
                                            label: 'Somente novos contatos',
                                            value: 'new_contacts'
                                        },
                                        {
                                            label: 'Contatos dentro da base',
                                            value: 'all_contacts'
                                        }
                                    ]}
                                /> */}
                            </NodeController>
                            <NodeController>
                                <Select
                                    id={'nodeType'}
                                    name={'nodeType'}
                                    label="Tipo de Etapa"
                                    marginVertical="12px"
                                    onChange={(e) =>
                                        setNodeTypeSelected(
                                            e.currentTarget.value
                                        )
                                    }
                                    options={[
                                        { value: 'message', label: 'Mensagem' },
                                        {
                                            value: 'messageQuestion',
                                            label: 'Mensagem com Pergunta'
                                        },
                                        {
                                            value: 'messageButton',
                                            label: 'Mensagem com Botões'
                                        },
                                        {
                                            value: 'messageAttendance',
                                            label: 'Redirecionamento p/ atendimento'
                                        },
                                        {
                                            value: 'messageEnd',
                                            label: 'Encerramento'
                                        }
                                    ]}
                                />
                                <Button
                                    onClick={() =>
                                        handleAddNodes(nodeTypeSelected)
                                    }
                                    rounded
                                    disabled={!nodeTypeSelected}
                                    size={'SMALL'}
                                >
                                    Adicionar Etapa
                                </Button>
                            </NodeController>

                            <Button
                                rounded
                                size={'SMALL'}
                                onClick={handleSaveAutomation}
                                style={{ zIndex: '11' }}
                                disabled={!automationName || nodes.length < 2}
                            >
                                {automationId ? 'Salvar Edição' : 'Salvar'}
                            </Button>

                            <Button
                                rounded
                                type="button"
                                outline
                                size={'SMALL'}
                                onClick={() => navigate('/automation')}
                                style={{ zIndex: '11' }}
                                disabled={!automationName || nodes.length < 2}
                            >
                                Sair
                            </Button>
                        </Controllers>
                    </ReactFlow>
                </FormProvider>
            </BoardWrapper>
        </LoggedLayout>
    );
};

export default AutomationBoard;
