import React from 'react';
import { ThemeProvider, css } from 'styled-components';

export const theme = {
    lineHeight: {
        default: '100%',
        xs: '116%',
        sm: '124%',
        md: '133%',
        lg: '150%'
    },
    fontSize: {
        xxs: '14px',
        xs: '16px',
        sm: '20px',
        md: '24px',
        lg: '32px',
        xl: '48px',
        xxl: '64px'
    },
    borderRadius: {
        none: '0px',
        sm: '2px',
        md: '8px',
        lg: '16px',
        pill: '500px',
        circular: '50%'
    },
    borderWidth: {
        none: '0px',
        hairline: '1px',
        thin: '2px',
        thick: '4px',
        heavy: '8px'
    },
    opacity: {
        semiopaque: 0.72,
        intense: 0.64,
        medium: 0.32,
        light: 0.16,
        semitransparent: 0.08
    },
    shadow: {
        level1: '0px 4px 8px rgba(0, 0, 0, 0.08)',
        level2: '0px 8px 24px rgba(0, 0, 0, 0.16)',
        level3: '0px 16px 48px rgba(0, 0, 0, 0.24)'
    },
    spacing: {
        quarck: '4px',
        nano: '8px',
        xxxs: '16px',
        xxs: '24px',
        xs: '32px',
        sm: '40px',
        md: '48px',
        lg: '56px',
        xl: '64px',
        xxl: '80px',
        xxxl: '120px',
        huge: '160px',
        giant: '200px'
    },
    inset: {
        quarck: '4px 4px',
        nano: '8px 8px',
        xs: '16px 16px',
        sm: '24px 24px',
        md: '32px 32px',
        lg: '40px 40px'
    },
    fontFamily: {
        inter: "'Inter', sans-serif"
    },
    fontWeight: {
        bold: 700,
        medium: 500,
        regular: 400
    },
    colors: {
        brand: {
            pure: '#0B57F5',
            light: '#76A2FF',
            medium: '#457EF5',
            dark: '#0C3FA8'
        },
        highlight: {
            pure: '#FF8A00',
            light: '#FFE3C2',
            medium: '#CC6E00',
            dark: '#7A4200'
        },
        low: {
            pure: '#000000',
            light: '#A3A3A3',
            medium: '#666666',
            dark: '#292929'
        },
        high: {
            pure: '#FFFFFF',
            light: '#F5F5F5',
            medium: '#E0E0E0',
            dark: '#CCCCCC'
        },
        error: {
            pure: '#EA1F1F',
            light: '#FDC4C4',
            medium: '#F05656',
            dark: '#710A0A'
        },
        success: {
            pure: '#3AA141',
            light: '#D2EFD4',
            medium: '#78CE7E',
            dark: '#205A24'
        }
    },
    grid: {
        sm: css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            margin: 0 24px;
            gap: 8px;
            width: 100%;
            max-width: 312px;
        `,
        md: css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            margin: 0 32px;
            gap: 16px;
            width: 100%;
            max-width: 704px;
        `,
        lg: css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            margin: 0 88px;
            gap: 24px;
            width: 100%;
            max-width: 1190px;
        `,
        xl: css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            margin: 0 auto;
            gap: 24px;
            width: 100%;
            max-width: 1440px;
        `
    }
};

export const Theme: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
