import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Container, Title, Subtitle, Button } from './styles';
import 'react-confirm-alert/src/react-confirm-alert.css';

type ConfirmDialogProps = {
    title: string;
    subtitle?: string;
    denyLabel: string;
    acceptLabel: string;
    acceptFunction: () => void;
};

const confirmDialog = ({
    acceptFunction,
    acceptLabel,
    denyLabel,
    title,
    subtitle
}: ConfirmDialogProps) =>
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <Container>
                    <Title>{title}</Title>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    <Button
                        onClick={() => {
                            acceptFunction();
                            onClose();
                        }}
                    >
                        {acceptLabel}
                    </Button>
                    <Button onClick={onClose}>{denyLabel}</Button>
                </Container>
            );
        }
    });
export default confirmDialog;
