import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    width: fit-content;

    border: 1px solid #e0e0e0;
    border-radius: 4px;

    & > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
        margin-left: 1rem;
    }
`;
