import styled from 'styled-components';
import whatsappBg from 'assets/icons/whatsapp-bg.png';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 2.4rem;
`;

export const Card = styled.div`
    background: #ffffff;
    border-radius: 1.2rem;
    width: 100%;
    max-width: 23rem;

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 100%;
    border-radius: 1.2rem 1.2rem 0 0;
    color: #ffffff;
    background: #3b7767;
    width: 100%;

    svg {
        margin-right: 1rem;
    }
`;

export const CardBody = styled.div`
    border: 1px solid #a3a3a3;
    border-radius: 0 0 1.2rem 1.2rem;
    padding: 2.5rem 1.5rem;
    height: min-content;
    background-image: url(${whatsappBg});
    background-color: rgba(163, 163, 163, 0.08);
`;

export const CardMessage = styled.div`
    padding: 0.8rem 1.4rem;
    background: #f5f5f5;
    border: 1px solid rgba(163, 163, 163, 0.27);
    border-radius: 0px 1.6rem 1.6rem 1.6rem;
    height: min-content;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #000000;

        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 300%;

        color: #666666;
    }
`;

export const CardButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #a3a3a3;
    border-radius: 4px;
    padding: 0.7rem 0;

    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.8rem;
    color: #0b57f5;

    text-transform: uppercase;
    margin-top: 1rem;

    svg {
        margin-right: 2rem;
    }
`;
