import { groupsColors } from 'Constant';
import styled from 'styled-components';
import { lighten } from 'polished';

export const TableContainer = styled.table`
    width: 100%;
    margin: 30px 0;
    border-collapse: collapse;
`;

export const TablePagination = styled.div`
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
    }
    .break-me {
        cursor: default;
        padding: 0;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.colors.low.medium};

        color: ${({ theme }) => theme.colors.brand.pure};
        font-size: ${({ theme }) => theme.fontSize.md};
        font-weight: 400;
    }
    .page {
        padding: 0;
        margin: 0;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.colors.low.medium};

        color: ${({ theme }) => theme.colors.brand.pure};
        font-size: ${({ theme }) => theme.fontSize.xxs};
        font-weight: 400;

        transition: all 0.2s ease;
        cursor: pointer;

        & > a {
            padding: 1rem 1.8rem;
        }

        &:hover {
            background: ${({ theme }) => theme.colors.brand.pure};
            color: ${({ theme }) => theme.colors.high.pure};
            border-radius: 0.5rem;
        }
    }

    .active {
        background: ${({ theme }) => theme.colors.brand.pure};
        color: ${({ theme }) => theme.colors.high.pure};
        border-radius: 0.5rem;
        width: 3rem;
        height: 3rem;
    }

    .control {
        padding: 0;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        background: ${({ theme }) => theme.colors.brand.light};
        margin: 0 1.7rem;

        color: ${({ theme }) => theme.colors.high.pure};
        font-size: ${({ theme }) => theme.fontSize.md};
        font-weight: 600;

        transition: all 0.2s ease;
        cursor: pointer;

        svg {
            padding-top: 5px;
        }

        &:hover {
            background: ${({ theme }) => theme.colors.brand.pure};
            color: ${({ theme }) => theme.colors.high.pure};
            border-radius: 0.5rem;
        }
    }
`;

export const TableHeadContainer = styled.thead`
    tr {
        height: 40px;
        background: #f9f9f9;
    }
    th {
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
        padding-left: 16px;
        min-width: 10rem;
    }

    th:first-child {
        max-width: 30px;
    }
`;

export const TableBodyContainer = styled.tbody`
    tr {
        height: 60px;
    }
    td {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;
        padding-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 10rem;
    }
`;

export const ChannelColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
        margin-right: 10px;
    }
`;

export const GroupColumn = styled.div<{ group: keyof typeof groupsColors }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    color: ${({ group }) => groupsColors[group]?.color || '#1C1C1C'};
    width: fit-content;
    background: ${({ group }) => groupsColors[group]?.background || '#F2F2F2'};
    padding: 8px;
    border: ${({ group }) =>
        `1px solid ${groupsColors[group]?.background || '#F2F2F2'}`};
    border-radius: 4px;
`;

export const ActionsGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & > svg {
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            color: ${({ theme }) => theme.colors.brand.pure};
        }
    }
`;

export const Pagination = styled.div`
    & > button {
        background: none;
        border: none;
        padding: 10px;
        color: ${({ theme }) => theme.colors.brand.pure};
        font-size: 12px;
        font-weight: 800;

        &:disabled {
            color: ${({ theme }) => theme.colors.low.medium};
        }
    }

    & > span {
        padding: 10px;
        font-size: 12px;
    }

    & > span > input {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px 6px;
        max-width: 80px;
    }

    & > select {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px 6px;
    }
`;

export const StatusColumn = styled.div<{ color: string }>`
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 133%;
    text-align: center;
    color: ${({ color }) => color};

    padding: 6px 8px;
    background: ${({ color }) => lighten(0.65, color)};
    border: ${({ color }) => `1px solid ${lighten(0.65, color)}`};
    border-radius: 4px;
    max-width: 8rem;
`;
