import styled from 'styled-components';

export const Container = styled.div``;

export const CustomTabs = styled.div`
    .react-tabs {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .react-tabs__tab-list {
        display: flex;
        flex-direction: column;
        width: 20%;
        max-width: 30rem;
        min-width: 25rem;
        border: none;
    }

    .react-tabs__tab {
        background-color: #ffffff;
        border: none;
        display: flex;
        align-items: center;

        font-weight: 500;
        font-size: 1.4rem;
        line-height: 100%;
        color: #292929;

        padding: 1.9rem 1.6rem;

        border-bottom: 1px solid #e0e0e0;

        svg {
            margin-right: 1.8rem;
        }
    }

    .react-tabs__tab--selected {
        background: rgba(118, 162, 255, 0.1);
        border-bottom: 1px solid #76a2ff;
        color: #0c3fa8;
    }

    @media (max-width: 1024px) {
        .react-tabs {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;

            .react-tabs__tab-list {
                display: flex;
                flex-direction: row;
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                border: none;
                overflow-x: auto;
                overflow-y: hidden;

                & > h1 {
                    display: none;
                }
            }
        }
        .react-tabs__tab {
            min-width: 15rem;
        }
    }
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 100%;
    color: #292929;
    padding: 1.9rem;
    margin-top: 3.5rem;
`;

export const Content = styled.div`
    background: #ffffff;
    padding: 4.9rem 3.7rem;
    border-radius: 0.8rem;
    width: 100%;
    min-height: 40rem;
    margin-right: 2.5rem;
`;
