import styled from 'styled-components';

export const Container = styled.div`
    .react-tabs__tab {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        background: none;
        border: none;
        color: #292929;
    }

    .react-tabs__tab--selected {
        position: relative;
        color: #0b57f5;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 2px;
            background-color: #0b57f5;
        }
    }

    .react-tabs__tab-list {
        border: none;
    }
`;

export const Header = styled.div`
    margin-bottom: 2rem;
    p {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        padding-bottom: 1.8rem;
        color: #292929;
    }
    input {
        padding: 10px 24px 10px 1rem;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
`;

export const ButtonSort = styled.button`
    background: none;
    border: none;
`;
