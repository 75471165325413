import LoggedLayout from 'Components/Templates/LoggedLayout';
import React, { useState } from 'react';
import { Container, GraphSection, Header, Row } from './styles';
import Input from 'Components/Atoms/Input';
import dayjs from 'dayjs';
import Attendances from './Attendances';
import LeadTime from './LeadTime';
import ConsumedWindow from './ConsumedWindow';
import CallsPerChannel from './CallsPerChannel';
import GeneralNps from './GeneralNPS';
import CallsPerDay from './CallsPerDay';
import AttendancesNps from './AttendancesNps';
import AttendancesPerformance from './AttendancesPerformance';

const Dashboard: React.FC = () => {
    const [period, setPeriod] = useState(dayjs().format('YYYY-MM'));

    const formatPeriod = (date: string) => date.split('-').reverse().join('/');
    return (
        <LoggedLayout backgroudInverted>
            <Header>
                <h1>Período dos dados</h1>
                <Input
                    name="period"
                    id="period"
                    type="month"
                    width="20rem"
                    defaultValue={dayjs().format('YYYY-MM')}
                    max={dayjs().format('YYYY-MM')}
                    onChange={(e) => setPeriod(e.currentTarget.value)}
                />
            </Header>
            <Container>
                <Row>
                    <Attendances period={formatPeriod(period)} />
                    <LeadTime period={formatPeriod(period)} />
                </Row>

                <Row>
                    <GraphSection>
                        <Row>
                            <ConsumedWindow period={formatPeriod(period)} />
                            <CallsPerChannel period={formatPeriod(period)} />
                            <GeneralNps period={formatPeriod(period)} />
                        </Row>
                        <CallsPerDay period={formatPeriod(period)} />
                    </GraphSection>
                    <AttendancesNps period={formatPeriod(period)} />
                    <AttendancesPerformance period={formatPeriod(period)} />
                </Row>
            </Container>
        </LoggedLayout>
    );
};

export default Dashboard;
