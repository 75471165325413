import React from 'react';
import { Container } from './styles';
import { ReactComponent as LoadingBubbles } from 'assets/icons/loading-bubbles.svg';

const Loading: React.FC = () => {
    return (
        <Container>
            <LoadingBubbles />
        </Container>
    );
};

export default Loading;
