import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

export const Image = styled.img`
    height: 100vh;
    width: auto;
    max-width: 100%;
    object-fit: cover;
    object-position: left;
`;

export const Left = styled.div`
    display: none;
    height: 100%;
    width: 50%;

    ${customMediaQuery.greaterThan('md')`
    display: initial;
  `}
`;

export const Right = styled.div`
    width: 100%;
    height: 100%;
    padding: 3.5rem 2.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow: auto;

    ${customMediaQuery.greaterThan('md')`
    padding: 4.8rem 5%;
  `}
`;
