import api from '../api';
import { AutomationBody, AutomationResponse } from './types';
import { ErrorBoundary } from 'services/errorBoundary';

class AutomationService {
    async getAutomations(): Promise<AutomationResponse[] | undefined> {
        try {
            const result = await api.get<AutomationResponse[]>('/automations');
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async findAutomation(id: number): Promise<AutomationBody | undefined> {
        try {
            const result = await api.get<AutomationBody>(`/automations/${id}`);
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async createAutomation(
        data: AutomationBody
    ): Promise<AutomationBody | undefined> {
        try {
            const result = await api.post<AutomationBody>('/automations', data);
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async updateAutomation(
        data: AutomationBody
    ): Promise<AutomationBody | undefined> {
        try {
            const result = await api.put<AutomationBody>(
                `/automations/${data.id}`,
                data
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
}

export default new AutomationService();
