import React, { useEffect, useRef, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { BiExit } from 'react-icons/bi';

import { Container, Menu, MenuItem } from './styles';
import Avatar from 'Components/Atoms/Avatar';

type UserDetailsProps = {
    image?: string;
    name?: string;
    logout: () => void;
};

const UserDetails: React.FC<UserDetailsProps> = ({ image, name, logout }) => {
    const menuRef = useRef<any>(null);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <Container onClick={() => setShowMenu(true)} ref={menuRef}>
            <Avatar src={image} size="3.2rem" alt={name} />

            <p>{name}</p>
            <BsChevronDown size={12} color="#292929" />

            {showMenu && (
                <Menu>
                    <MenuItem>
                        <button
                            onClick={() => {
                                logout();
                                setShowMenu(false);
                            }}
                        >
                            Sair <BiExit size={18} />
                        </button>
                    </MenuItem>
                </Menu>
            )}
        </Container>
    );
};

export default UserDetails;
