import React, { useCallback, useEffect } from 'react';
import {
    Container,
    Header,
    Title,
    CloseButton,
    Row,
    Wrapper,
    InputWrapper
} from './styles';
import { MdClose } from 'react-icons/md';
import Input from 'Components/Atoms/Input';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputPhone from 'Components/Atoms/InputPhone';
import Select from 'Components/Atoms/Select';
import Button from 'Components/Atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useContactStore } from 'store/useContacts';
import { modalContent } from './content';
import { useSettingsStore } from 'store';
import ValidationItem from 'Components/Molecules/ValidationItem';

const ModalUserForm: React.FC = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const schema = z.object({
        firstName: z.string().min(1, 'Campo obrigatório'),
        lastName: z.string().min(1, 'Campo obrigatório'),
        email: z.string().email('Email inválido!').min(1, 'Campo obrigatório'),
        phone: z.string().optional(),
        office: z.string().optional(),
        ...(!userId
            ? {
                  password: z
                      .string()
                      .min(7, 'PASSWORD_SIZE_CHECK')
                      .regex(/\d/gi, 'PASSWORD_NUMERIC_CHECK')
                      .regex(
                          /(?=.*[a-z])(?=.*[A-Z])/g,
                          'PASSWORD_CAMELCASE_CHECK'
                      )
              }
            : { password: z.string().nullable().optional() }),
        role: z.string().optional(),
        team: z.number().optional()
    });

    const { fields, newTitle, editTitle, actions } = modalContent;
    const { roles, teams, getRoles, getTeams } = useSettingsStore();
    const { createTeamMember, updateTeamMember, getUserById } =
        useContactStore();

    const {
        register,
        control,
        setValue,
        getValues,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<{
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        office: string;
        password: string;
        role: string;
        team: string;
    }>({
        resolver: zodResolver(schema),
        mode: 'onChange'
    });

    const onSubmit = handleSubmit(async (data) => {
        const passwordValidation = z
            .string()
            .min(7, 'PASSWORD_SIZE_CHECK')
            .regex(/\d/gi, 'PASSWORD_NUMERIC_CHECK')
            .regex(/(?=.*[a-z])(?=.*[A-Z])/g, 'PASSWORD_CAMELCASE_CHECK');

        if (data.password) {
            const { success } = await passwordValidation.safeParse(
                data.password
            );
            if (!success) {
                return;
            }
        }
        if (userId) {
            updateTeamMember(userId, data);
        } else {
            createTeamMember(data);
        }
        navigate(-1);
    });

    const verifyValidity = (rule: any) => {
        return !!watch('password')?.match(rule);
    };

    const recoveryUserData = useCallback(
        async (userId: number) => {
            const userData = await getUserById(userId);

            setValue('email', userData.email);
            setValue('firstName', userData.firstName);
            setValue('lastName', userData.lastName);
            setValue('office', userData.office);
            setValue('phone', userData.phone);
            setValue('role', `${userData.role.id}`);
            setValue('team', `${userData.team.id}`);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reset]
    );

    useEffect(() => {
        if (userId) {
            recoveryUserData(Number(userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        getRoles();
        getTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Container>
                <Header>
                    <Title>{userId ? editTitle : newTitle}</Title>
                    <CloseButton onClick={() => navigate(-1)}>
                        <MdClose size={24} />
                    </CloseButton>
                </Header>

                <form onSubmit={onSubmit}>
                    <Row>
                        <Input
                            name="firstName"
                            id="firstName"
                            label={fields.firstName.label}
                            placeholder={fields.firstName.placeholder}
                            register={register('firstName')}
                            error={errors.firstName?.message}
                        />
                        <Input
                            name="lastName"
                            id="lastName"
                            label={fields.lastName.label}
                            placeholder={fields.lastName.placeholder}
                            register={register('lastName')}
                            error={errors.lastName?.message}
                        />
                    </Row>
                    <Row>
                        <Input
                            name="email"
                            id="email"
                            label={fields.email.label}
                            placeholder={fields.email.placeholder}
                            register={register('email')}
                            error={errors.email?.message}
                        />
                    </Row>
                    <Row>
                        <InputPhone
                            name="phone"
                            id="phone"
                            control={control}
                            label={fields.phone.label}
                            placeholder={fields.phone.placeholder}
                            error={errors.phone?.message}
                        />
                    </Row>
                    <Row>
                        <Input
                            name="office"
                            id="office"
                            label={fields.office.label}
                            placeholder={fields.office.placeholder}
                            register={register('office')}
                            error={errors.office?.message}
                        />
                    </Row>
                    <Row>
                        <InputWrapper>
                            <Input
                                name="password"
                                id="password"
                                type="password"
                                showPassword
                                width="100%"
                                label={fields.password.label}
                                placeholder={fields.password.placeholder}
                                register={register('password')}
                                error={
                                    fields.password.validations[
                                        errors.password
                                            ?.message as keyof typeof fields.password.validations
                                    ]
                                }
                            />

                            <ul>
                                <ValidationItem
                                    checked={verifyValidity(
                                        /(?=.*[a-z])(?=.*[A-Z])/g
                                    )}
                                    label={
                                        fields.password.validations
                                            .PASSWORD_CAMELCASE_CHECK
                                    }
                                />
                                <ValidationItem
                                    checked={verifyValidity(/\d/gi)}
                                    label={
                                        fields.password.validations
                                            .PASSWORD_NUMERIC_CHECK
                                    }
                                />
                                <ValidationItem
                                    checked={verifyValidity(/^.{7,}$/)}
                                    label={
                                        fields.password.validations
                                            .PASSWORD_SIZE_CHECK
                                    }
                                />
                            </ul>
                        </InputWrapper>
                    </Row>
                    <Row>
                        <Select
                            name="role"
                            placeholder={fields.role.placeholder}
                            label={fields.role.label}
                            control={control}
                            error={errors.role?.message}
                            value={getValues('role')}
                            onChange={(e) =>
                                setValue('role', e.currentTarget.value)
                            }
                            options={roles.map((item) => ({
                                label: item.name,
                                value: item.id
                            }))}
                        />

                        <Select
                            name="team"
                            placeholder={fields.team.placeholder}
                            label={fields.team.label}
                            control={control}
                            error={errors.team?.message}
                            value={getValues('team')}
                            onChange={(e) =>
                                setValue('team', e.currentTarget.value)
                            }
                            options={teams.map((item) => ({
                                label: item.name,
                                value: item.id
                            }))}
                        />
                    </Row>

                    <Row>
                        <Button
                            outline
                            rounded
                            size={'SMALL'}
                            onClick={() => navigate(-1)}
                        >
                            {actions.cancel}
                        </Button>
                        <Button type="submit" rounded size={'SMALL'}>
                            {actions.save}
                        </Button>
                    </Row>
                </form>
            </Container>
        </Wrapper>
    );
};

export default ModalUserForm;
