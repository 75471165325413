/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutomation } from '../context';
import { DeleteButton, NodeContainer } from './styles';
import { BiTrash } from 'react-icons/bi';
import {
    Message,
    MessageButton,
    MessageQuestion,
    MessageEnd,
    MessageAttendance
} from '.';
import Select from 'Components/Atoms/Select';

export const DefaultNode = memo(({ id, data }: any) => {
    const { getValues, setValue } = useFormContext();
    const { setNodes, setEdges, nodes } = useAutomation();
    const nodeType = {
        message: <Message id={id} />,
        messageEnd: <MessageEnd id={id} />,
        messageButton: <MessageButton id={id} />,
        messageQuestion: <MessageQuestion id={id} />,
        messageAttendance: <MessageAttendance id={id} />
    };

    const handleRemoveNode = () => {
        const children = nodes
            .filter((item) => item.parentId === id)
            .map((item) => item.id);
        setNodes((old) =>
            old.filter((item) => item.id !== id && item.parentId !== id)
        );
        setEdges((old) =>
            old.filter(
                (item) =>
                    item.target !== id &&
                    item.source !== id &&
                    !children.includes(item.target) &&
                    !children.includes(item.source)
            )
        );
        setValue(`${id}.type`, undefined);
        setValue(`${id}.message`, undefined);
    };

    const handleRemoveChildren = () => {
        const children = nodes
            .filter((item) => item.parentId === id)
            .map((item) => item.id);
        setNodes((old) => old.filter((item) => item.parentId !== id));
        setEdges((old) =>
            old.filter(
                (item) =>
                    !children.includes(item.target) &&
                    !children.includes(item.source)
            )
        );
        setValue(`${id}.message`, undefined);
        setValue(`${id}.group`, undefined);
    };

    return (
        <NodeContainer>
            <DeleteButton onClick={handleRemoveNode}>
                <BiTrash size={24} />
            </DeleteButton>

            <Select
                id={`${id}.type`}
                name={`${id}.type`}
                label="Tipo"
                marginVertical="12px"
                onChange={(e) => {
                    setValue(`${id}.type`, e.currentTarget.value);
                    setNodes((old) =>
                        old.map((item) => {
                            if (item.id === id) {
                                item.type = e.currentTarget.value;
                            }
                            return item;
                        })
                    );
                    handleRemoveChildren();
                }}
                value={getValues(`${id}.type`)}
                options={[
                    { value: 'message', label: 'Mensagem' },
                    {
                        value: 'messageQuestion',
                        label: 'Mensagem com Pergunta'
                    },
                    { value: 'messageButton', label: 'Mensagem com Botões' },
                    {
                        value: 'messageAttendance',
                        label: 'Redirecionamento p/ atendimento'
                    },
                    { value: 'messageEnd', label: 'Encerramento' }
                ]}
            />
            {
                nodeType[
                    (data.type ||
                        getValues(`${id}.type`)) as keyof typeof nodeType
                ]
            }
        </NodeContainer>
    );
});
