import React, { ImgHTMLAttributes } from 'react';
import Deetz from 'assets/icons/DEETZ.svg';
import { Container } from './styles';

type AvatarProps = {
    src?: string;
    size?: string;
};

const Avatar: React.FC<AvatarProps & ImgHTMLAttributes<HTMLImageElement>> = ({
    size,
    src,
    ...props
}) => {
    return (
        <Container
            src={src || Deetz}
            size={size}
            imageDefault={!src}
            {...props}
        />
    );
};

export default Avatar;
