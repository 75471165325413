import React, { useEffect, useState } from 'react';
import { AutomationTable, Container, Header, StatusColumn } from './styles';
import LoggedLayout from 'Components/Templates/LoggedLayout';
import Button from 'Components/Atoms/Button';
import 'react-toggle/style.css';
import { useAutomationStore } from 'store';
import dayjs from 'dayjs';
import { content } from './content';
import ModalDecision from './ModalDecision';
import { useNavigate } from 'react-router-dom';

const Automation: React.FC = () => {
    const [openModal, setOpenModal] = useState(false);
    const { getAutomations, automations } = useAutomationStore();
    const navigate = useNavigate();

    const { newAutomation, table, title } = content;

    useEffect(() => {
        getAutomations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LoggedLayout>
            <Container>
                <Header>
                    <h1>
                        {title} <span>{automations.length}</span>
                    </h1>

                    <Button
                        rounded
                        size="SMALL"
                        width="16rem"
                        onClick={() => setOpenModal(true)}
                    >
                        {newAutomation}
                    </Button>
                </Header>

                <AutomationTable>
                    <thead>
                        <tr>
                            <th>{table.name}</th>
                            <th>{table.enabled}</th>
                            <th>{table.updatedAt}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {automations.map((automation) => (
                            <tr
                                key={automation.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    navigate(`board/${automation.id}`)
                                }
                            >
                                <td>{automation.name}</td>

                                <StatusColumn
                                    status={
                                        automation.isActived
                                            ? 'enabled'
                                            : 'disabled'
                                    }
                                >
                                    <span>
                                        {automation.isActived
                                            ? 'Ativo'
                                            : 'Desativado'}
                                    </span>
                                </StatusColumn>
                                <td>
                                    {dayjs(automation.updatedAt).format(
                                        'DD/MM/YYYY HH:MM'
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </AutomationTable>
                {openModal && (
                    <ModalDecision
                        isOpen={openModal}
                        handleClose={() => setOpenModal(false)}
                    />
                )}
            </Container>
        </LoggedLayout>
    );
};

export default Automation;
