import React from 'react';

import { Container, Text } from './styles';

interface DividerProps {
    text?: string;
}
const Divider: React.FC<DividerProps> = ({ text }) => {
    return <Container>{text && <Text>{text}</Text>}</Container>;
};

export default Divider;
