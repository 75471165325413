import styled from 'styled-components';

export const Container = styled.div`
    .ReactTags__tagInput {
        display: none;
    }

    .ReactTags__selected {
        padding: 2rem 0;
    }

    .ReactTags__tag {
        background-color: #ffffff;
        padding: 1rem 0.8rem;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-right: 8px;

        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: #292929;
    }

    .ReactTags__remove {
        background: none;
        border: none;
        padding-left: 1rem;
    }
`;
export const Title = styled.h3`
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
    margin-bottom: 1.6rem;
`;

export const AvatarSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5rem;
    margin-bottom: 2rem;

    & > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
`;

export const ProductsSection = styled.div`
    margin: 2rem 0;

    & > p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #292929;
    }
`;
export const ChannelsSection = styled.div`
    margin-bottom: 2rem;
`;

export const GroupsSection = styled.div`
    margin-bottom: 2rem;

    & > ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 1.5rem;
    }

    li {
        font-weight: 400;
        font-size: 14px;
        line-height: 133%;
        color: #cc6e00;
        width: fit-content;
        background: #ffe3c2;
        padding: 8px;
        border: 1px solid #ffe3c2;
        border-radius: 4px;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #ffffff;
        padding: 8px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #292929;

        svg {
            margin-right: 12px;
        }
    }
`;

export const DetailsSection = styled.div``;

export const DetailInfo = styled.p`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;

    strong {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #a3a3a3;
        padding-bottom: 8px;
    }

    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #000000;
    }
`;
