//@ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
} from 'chart.js';
import { Container, Title as TitleStyle } from './styles';
import { useDashboard } from 'store';
import { DashboardCallsPerDay } from 'services/dashboard/types';
import { BsInfoSquare } from 'react-icons/bs';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);

type CallsPerDayProps = {
    period: string;
};

const CallsPerDay: React.FC<CallsPerDayProps> = ({ period }) => {
    const { getCallsPerDay } = useDashboard();
    const [data, setData] = useState<DashboardCallsPerDay[]>([]);

    const handleAttendanceData = useCallback(async () => {
        const result = await getCallsPerDay({ period });

        setData(result);
    }, [getCallsPerDay, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <TitleStyle>
                Atendimentos por dia <BsInfoSquare />
            </TitleStyle>
            <Line
                options={{
                    responsive: true,
                    tension: 0.6,
                    scales: {
                        x: {
                            border: {
                                display: false
                            },
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            border: {
                                display: false
                            },
                            grid: {
                                display: false
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            footerColor: '#000',
                            boxPadding: 10,
                            callbacks: {
                                title: () => '',
                                label: () => '',
                                footer: (tooltipItems) =>
                                    `${tooltipItems[0].formattedValue}\nAtendimentos`
                            }
                        }
                    }
                }}
                data={{
                    labels: data.map((item) =>
                        item.day.toString().padStart(2, '0')
                    ),
                    datasets: [
                        {
                            data: data.map((item) => item.calls),
                            borderColor: 'rgba(69, 126, 245, 0.87)',
                            backgroundColor: 'rgba(69, 126, 245, 1)',
                            fill: true,
                            type: 'line'
                        }
                    ]
                }}
            />
        </Container>
    );
};

export default CallsPerDay;
