import styled from 'styled-components';

export const Container = styled.div`
    & > h3 {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2%.8rem;
        color: #292929;
        margin-bottom: 1rem;
    }
`;

export const ChannelsGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 5px;
    min-width: 10rem;
    height: 6rem;
    background: #f2f2f2;
    border-radius: 1.6rem;

    svg {
        width: 2.6rem;
        height: 2.6rem;
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 3.6rem;
        color: #1c1c1c;
    }
`;
