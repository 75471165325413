import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Container = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
`;

export const MenuDesktopItem = styled.li<{ active?: boolean }>`
    & > a {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration: none;
        color: #292929;
    }

    & > a:focus,
    & > a:active,
    & > a:hover,
    a.active {
        color: #0b57f5;
    }
`;

export const Header = styled.nav`
    width: 100%;
    height: 6rem;
    padding: 20px 28px;
    display: flex;
    background: #ffffff;
    align-items: center;
    justify-content: space-between;

    ${customMediaQuery.lessThan('md')`
        display: none;
    `}
`;
