import { zodResolver } from '@hookform/resolvers/zod';
import Input from 'Components/Atoms/Input';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RegisterRequest } from 'services/auth/types';
import { useRegisterStore } from 'store/useRegisterStore';
import * as z from 'zod';
import { content } from '../content';
import 'react-phone-input-2/lib/style.css';
import InputPhone from 'Components/Atoms/InputPhone';
import Select from 'Components/Atoms/Select';
import Divider from '../components/Divider';
import Button from 'Components/Atoms/Button';
import { FormTitle, Title } from '../styles';
import Goback from 'Components/Atoms/Goback';

const Step2: React.FC = () => {
    const { nextStep, previousStep, data } = useRegisterStore();
    const { step2 } = content;
    const schema = z.object({
        firstName: z.string().min(1, 'Campo obrigatório'),
        lastName: z.string().min(1, 'Campo obrigatório'),
        phone: z.string().min(1, 'Campo obrigatório'),
        company: z.object({
            name: z.string(),
            siteUrl: z.string(),
            numberOfCollaborators: z.string(),
            category: z.string()
        })
    });

    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        formState: { errors, isValid }
    } = useForm<RegisterRequest>({
        resolver: zodResolver(schema),
        criteriaMode: 'all',
        defaultValues: data
    });

    const onSubmit = async (formData: RegisterRequest) => {
        nextStep(formData);
    };

    return (
        <div>
            <Goback handleClick={previousStep} size={14} />
            <Title>{step2.title}</Title>

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <FormTitle>{step2.persornalForm.title}</FormTitle>
                <Input
                    id="firstName"
                    name="firstName"
                    width="100%"
                    error={errors.firstName?.message}
                    label={step2.persornalForm.firstName.label}
                    placeholder={step2.persornalForm.firstName.placeholder}
                    register={register('firstName')}
                />

                <Input
                    id="lastName"
                    name="lastName"
                    marginVertical="24px"
                    width="100%"
                    error={errors.lastName?.message}
                    label={step2.persornalForm.lastName.label}
                    placeholder={step2.persornalForm.lastName.placeholder}
                    register={register('lastName')}
                />

                <InputPhone
                    id="phone"
                    name="phone"
                    width="100%"
                    control={control}
                    error={errors.phone?.message}
                    label={step2.persornalForm.phone.label}
                    placeholder={step2.persornalForm.phone.placeholder}
                />

                <Divider />

                <FormTitle>{step2.companyForm.title}</FormTitle>
                <Input
                    id="company.name"
                    name="company.name"
                    width="100%"
                    error={errors.company?.name?.message}
                    label={step2.companyForm.companyName.label}
                    placeholder={step2.companyForm.companyName.placeholder}
                    register={register('company.name')}
                />

                <Input
                    id="company.siteUrl"
                    name="company.siteUrl"
                    width="100%"
                    marginVertical="24px"
                    error={errors.company?.siteUrl?.message}
                    label={step2.companyForm.website.label}
                    placeholder={step2.companyForm.website.placeholder}
                    register={register('company.siteUrl')}
                />

                <Select
                    id="company.numberOfCollaborators"
                    name="company.numberOfCollaborators"
                    width="100%"
                    error={errors.company?.numberOfCollaborators?.message}
                    label={step2.companyForm.companySize.label}
                    placeholder={step2.companyForm.companySize.placeholder}
                    control={control}
                    onChange={(e) =>
                        setValue(
                            'company.numberOfCollaborators',
                            e.currentTarget.value
                        )
                    }
                    value={getValues('company.numberOfCollaborators')}
                    options={step2.companyForm.companySize.options}
                />

                <Select
                    id="company.category"
                    name="company.category"
                    width="100%"
                    marginVertical="24px"
                    error={errors.company?.category?.message}
                    label={step2.companyForm.segment.label}
                    placeholder={step2.companyForm.segment.placeholder}
                    control={control}
                    value={getValues('company.category')}
                    onChange={(e) =>
                        setValue('company.category', e.currentTarget.value)
                    }
                    options={step2.companyForm.segment.options}
                />

                <Button
                    type="submit"
                    marginVertical="34px"
                    size="SMALL"
                    rounded
                    disabled={!isValid}
                >
                    {step2.submit}
                </Button>
            </form>
        </div>
    );
};

export default Step2;
