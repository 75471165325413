/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../api';
import TokenService from '../token';
import {
    ChatContactDetails,
    ChatContactMessages,
    ChatContactModel,
    ColContactResult,
    ContactChannels,
    ContactParams,
    GroupsResult,
    IChatMessage,
    ListChatContact,
    RowContactModel,
    TeamMember,
    TeamMemberModel,
    TeamMemberUser
} from './types';
import { ErrorBoundary } from 'services/errorBoundary';

class AuthService {
    async getOpenedContacts(): Promise<ListChatContact | undefined> {
        try {
            const result = await api.get<ListChatContact>(
                '/chats/opened-contacts'
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getClosedContacts(): Promise<ListChatContact | undefined> {
        try {
            const result = await api.get<ListChatContact>(
                '/chats/closed-contacts'
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getRowContacts(params?: {
        filter?: string;
        owners?: number[];
        groups?: string[];
        rowsByPage?: number;
        page?: number;
    }): Promise<RowContactModel | undefined> {
        try {
            const result = await api.get<RowContactModel>('/contacts/row', {
                params: params
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getColContacts(params?: {
        filter?: string;
        channel?: string;
        value?: string;
    }): Promise<ColContactResult | undefined> {
        try {
            const result = await api.get<ColContactResult>('/contacts/col', {
                params: params
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getContactDetails(
        contactId: number
    ): Promise<ChatContactDetails | undefined> {
        try {
            const result = await api.get<ChatContactDetails>(
                `/contacts/${contactId}`
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getContactMessages(
        contactId: number
    ): Promise<ChatContactMessages[] | undefined> {
        try {
            const { data } = await api.get<ChatContactModel>(
                `/contacts/${contactId}/messages/`
            );
            const authData = TokenService.getAuthData();

            const formated: ChatContactMessages[] =
                data?.contactChannels[0]?.chatTickets[0]?.chatMessages
                    ?.filter((item) => {
                        if (item.type === 'text' && !item.messageBody.text) {
                            return false;
                        }
                        return true;
                    })
                    .map((item) => {
                        const user = {
                            id: authData?.user?.id as number,
                            avatar: authData?.user?.avatar as string,
                            firstName: authData?.user?.firstName as string,
                            lastName: authData?.user?.lastName as string
                        };

                        const contact = {
                            avatar: data?.avatar as string,
                            firstName: data?.firstName as string,
                            id: data?.id as number,
                            lastName: data?.lastName as string
                        };
                        return {
                            createdAt: item?.createdAt,
                            id: item?.id,
                            sentBy: item.sentBy,
                            messageBody: {
                                text: item?.messageBody?.text,
                                link: item?.messageBody?.link,
                                fileKey: item?.messageBody?.fileKey,
                                contentType: item?.type
                            },
                            owner: item?.sentBy === 'user',
                            source: item?.sentBy === 'user' ? user : contact
                        };
                    });
            return formated;
        } catch (error: any) {
            console.error(error);
            ErrorBoundary(error);
        }
    }

    async deleteContact(contactId: number) {
        try {
            await api.delete(`/contacts/${contactId}`);
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async sendContactMessage(
        params: IChatMessage,
        contact: ChatContactDetails
    ): Promise<ChatContactMessages[] | undefined> {
        try {
            const { data } = await api.post(
                `/websocket/${params.contactChannelId}`,
                params.data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    baseURL: process.env.REACT_APP_API_URL?.replace('api/', '')
                }
            );

            const authData = TokenService.getAuthData();

            const user = {
                id: authData?.user?.id as number,
                avatar: authData?.user?.avatar as string,
                firstName: authData?.user?.firstName as string,
                lastName: authData?.user?.lastName as string
            };

            const contactSource = {
                avatar: contact.avatar,
                firstName: contact.firstName,
                id: contact.id,
                lastName: contact.lastName
            };

            return [
                {
                    createdAt: data[0]?.createdAt,
                    id: data[0]?.id,
                    owner: data[0]?.sentBy === 'user',
                    source: data[0]?.sentBy === 'user' ? user : contactSource,
                    messageBody: {
                        text: data[0]?.messageBody?.text,
                        link: data[0]?.messageBody?.link,
                        fileKey: data[0]?.messageBody?.fileKey,
                        contentType: data[0]?.type
                    }
                }
            ];
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getTeamMembers(params?: {
        filter: string;
        teams: number[];
        roles: number[];
    }): Promise<TeamMember | undefined> {
        try {
            const result = await api.get<TeamMember>(`/users`, {
                params: {
                    filter: params?.filter || '',
                    teams: params?.teams?.toString() || '',
                    roles: params?.roles?.toString() || '',
                    rowsByPage: 100
                }
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async patchContactOwner(
        contactId: number,
        owner: number
    ): Promise<ChatContactDetails | undefined> {
        try {
            const result = await api.put<ChatContactDetails>(
                `/contacts/${contactId}`,
                { owner }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async updateChatUsers(
        chatId: number,
        users: number[]
    ): Promise<number | undefined> {
        try {
            const result = await api.put(`/chats/messages/${chatId}`, {
                usersId: users
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async updateContact(
        contactId: number,
        data: ContactParams
    ): Promise<number | undefined> {
        try {
            const result = await api.put(`/contacts/${contactId}`, {
                ...data,
                groups: [data.groups],
                contactChannels: [
                    {
                        channelCommunication: 'whatsapp',
                        identifier: data.whatsapp || ''
                    },
                    {
                        channelCommunication: 'sms',
                        identifier: data.sms || ''
                    },
                    {
                        channelCommunication: 'messenger',
                        identifier: data.facebook || ''
                    },
                    {
                        channelCommunication: 'instagram',
                        identifier: data.instagram || ''
                    }
                ]
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async createTeamMember(
        data: TeamMemberModel
    ): Promise<TeamMemberUser | undefined> {
        try {
            const result = await api.post(`/users`, {
                ...data,
                roleId: Number(data.role),
                role: undefined
            });

            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getUserById(id: number): Promise<TeamMemberUser | undefined> {
        try {
            const result = await api.get(`/users/${id}`);

            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async updateTeamMember(
        userId: string,
        data: TeamMemberModel
    ): Promise<TeamMemberUser | undefined> {
        try {
            const result = await api.put(`/users/${userId}`, {
                ...data,
                roleId: Number(data.role),
                role: undefined
            });

            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async deleteTeamMember(memberId: number) {
        try {
            await api.delete(`/users/${memberId}`);
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async createContact(
        data: ContactParams
    ): Promise<ChatContactDetails | undefined> {
        try {
            const result = await api.post(`/contacts`, {
                ...data,
                groups: [data.groups],
                contactChannels: [
                    {
                        channelCommunication: 'whatsapp',
                        identifier: data.whatsapp || ''
                    },
                    {
                        channelCommunication: 'sms',
                        identifier: data.sms || ''
                    },
                    {
                        channelCommunication: 'messenger',
                        identifier: data.facebook || ''
                    },
                    {
                        channelCommunication: 'instagram',
                        identifier: data.instagram || ''
                    }
                ]
            });

            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getGroups(): Promise<GroupsResult[] | undefined> {
        try {
            const result = await api.get<GroupsResult[]>(`/groups`);
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async addContactGroup(data: {
        contactId: number;
        groupId: number;
    }): Promise<GroupsResult | undefined> {
        try {
            const result = await api.put<GroupsResult>(`/groups/addContact`, {
                ...data
            });
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async removeContactGroup(data: {
        contactId: number;
        groupId: number;
    }): Promise<GroupsResult | undefined> {
        try {
            const result = await api.put<GroupsResult>(
                `/groups/removeContact`,
                {
                    ...data
                }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async getContactChannelsDetails(
        channel: string
    ): Promise<ContactChannels | undefined> {
        try {
            const result = await api.get<ContactChannels>(
                `/configurations/channel/${channel}`
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }

    async endChat(chatTicket: number) {
        try {
            const result = await api.put(
                `/chats/changeStatus/conclused?chatTicket=${chatTicket}`
            );

            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
}

export default new AuthService();
