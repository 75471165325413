import React, { useCallback } from 'react';
import {
    AvatarSection,
    Container,
    DetailInfo,
    DetailTextItem,
    DetailsSection,
    GroupsSection,
    Ranking,
    Title
} from './styles';
import Divider from 'Components/Atoms/Divider';
import { content } from '../content';

import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { BsChevronLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Avatar from 'Components/Atoms/Avatar';
import { TeamsDetailsResponse } from 'services/settings/types';

const MemberDetails: React.FC<{
    memberDetails: TeamsDetailsResponse | undefined;
}> = ({ memberDetails }) => {
    const navigate = useNavigate();

    const handleGoBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <Container>
            <Title style={{ height: '5.4rem' }} onClick={() => handleGoBack()}>
                <BsChevronLeft size={16} /> {content.contactDetails.title}
            </Title>
            <AvatarSection>
                <Avatar
                    src={memberDetails?.avatar}
                    size="6.4rem"
                    style={{ marginRight: '1rem' }}
                    alt={memberDetails?.firstName}
                />

                <p>
                    {`${memberDetails?.firstName} ${memberDetails?.lastName}`}
                    <span>
                        {memberDetails?.isActived ? 'Online' : 'Offline'}
                    </span>
                </p>
            </AvatarSection>

            <Ranking>
                <StarIcon />
                <p>{`${memberDetails?.ranking}º lugar em atendimentos`}</p>
                {/* <p>{`${0}º lugar em atendimentos`}</p> */}
            </Ranking>

            <DetailTextItem>
                <strong>{content.contactDetails.statistic.convertion}</strong>{' '}
                {`${memberDetails?.conversionRate}%`}
                {/* {`${0}%`} */}
            </DetailTextItem>

            <DetailTextItem>
                <strong>{content.contactDetails.statistic.nps}</strong>{' '}
                {`${memberDetails?.nps}`}
                {/* {`${0}`} */}
            </DetailTextItem>
            <Divider />

            <GroupsSection>
                <Title style={{ marginBottom: '1.6rem' }}>
                    {content.contactDetails?.groups?.title}
                </Title>
                <ul>
                    <li> {memberDetails?.sector?.name}</li>
                </ul>
            </GroupsSection>

            <Divider />

            <DetailsSection>
                <Title style={{ marginBottom: '2.8rem' }}>
                    {content.contactDetails?.details?.title}
                </Title>

                <DetailInfo>
                    <strong>{content.contactDetails.details.firstName}</strong>
                    <span>{memberDetails?.firstName}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.lastName}</strong>
                    <span>{memberDetails?.lastName}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.email}</strong>
                    <span>{memberDetails?.email}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.phone}</strong>
                    <span>{memberDetails?.phone}</span>
                </DetailInfo>
            </DetailsSection>
        </Container>
    );
};

export default MemberDetails;
