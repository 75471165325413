import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Title = styled.h1`
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    width: 100%;
    text-align: center;
    padding: 3.5rem;
    color: #292929;

    ${customMediaQuery.greaterThan('md')`
    display: none;
  `}
`;
