import React, { useCallback, useEffect, useState } from 'react';
import { Card, Container } from './styles';
import { useDashboard } from 'store';
import { DashboardAttendantsNps } from 'services/dashboard/types';
import { useNavigate } from 'react-router-dom';

type GeneralNpsProps = {
    period: string;
};

const AttendancesNps: React.FC<GeneralNpsProps> = ({ period }) => {
    const { getAttendantsNps } = useDashboard();
    const [data, setData] = useState<DashboardAttendantsNps | null>();
    const navigate = useNavigate();

    const handleAttendanceData = useCallback(async () => {
        const result = await getAttendantsNps({ period });

        setData(result);
    }, [getAttendantsNps, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <h3>NPS dos atendentes</h3>

            {data?.response?.map((item) => (
                <Card key={item.id} onClick={() => navigate(`${item.id}`)}>
                    <img src={item.avatar} alt={item.firstName} />
                    <p>
                        {`${item.firstName} ${item.lastName}`}
                        <small>{`Conversas: ${item.countTickets}`}</small>
                    </p>
                    <span>{item.NPS}</span>
                </Card>
            ))}
        </Container>
    );
};

export default AttendancesNps;
