import React, { useCallback, useRef, useState } from 'react';
import { InputProps } from './types';
import * as S from './styles';
import { FiAlertCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { Controller } from 'react-hook-form';

const Select: React.FC<InputProps> = ({
    label,
    span,
    required,
    disabled,
    id,
    name,
    placeholder,
    options,
    error,
    value,
    marginVertical,
    marginHorizontal,
    backgroundWhite,
    control,
    ...props
}) => {
    const inputRef = useRef<HTMLSelectElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const theme = useTheme();

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        setIsFilled(!!inputRef.current?.value);
    }, []);

    return (
        <S.Container
            marginHorizontal={marginHorizontal}
            marginVertical={marginVertical}
            width={props?.width}
        >
            {label && (
                <S.Label>
                    {label}{' '}
                    {error && (
                        <S.Error title={error}>
                            <FiAlertCircle
                                color={theme.colors.high.pure}
                                size={20}
                            />
                        </S.Error>
                    )}
                </S.Label>
            )}

            <S.InputWrapper
                backgroundWhite={backgroundWhite}
                isErrored={!!error}
                isFilled={isFilled}
                isFocused={isFocused}
                width={props?.width}
            >
                <Controller
                    control={control}
                    name={name}
                    defaultValue={value}
                    render={({ field }) => (
                        <select
                            required={required}
                            id={id}
                            placeholder={placeholder}
                            disabled={disabled}
                            onFocus={(e) => {
                                handleInputFocus();
                                props?.onFocus && props.onFocus(e);
                            }}
                            {...props}
                            {...field}
                            ref={inputRef}
                            onChange={(e) => {
                                props.onChange && props.onChange(e);
                            }}
                            onBlur={(e) => {
                                handleInputBlur();
                                props.onBlur && props.onBlur(e);
                            }}
                        >
                            <option value="">
                                {placeholder ?? 'Selecione um item'}
                            </option>
                            {options.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    )}
                ></Controller>
            </S.InputWrapper>
            {span && <S.Span>{span}</S.Span>}
        </S.Container>
    );
};

export default Select;
