import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import i18n from '@emoji-mart/data/i18n/pt.json';
import { filesize } from 'filesize';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
    TextArea,
    Container,
    PickerWrapper,
    TextAreaWrapper,
    ButtonEmoji,
    ButtonAttachment,
    Actions,
    FileDecideWrapper,
    ButtonRecordAudio,
    RecordingWrapper,
    SoundIcon
} from './styles';
import { FiSmile } from 'react-icons/fi';
import { AiOutlineCheck, AiOutlineClose, AiOutlineSend } from 'react-icons/ai';
import { MdAttachFile, MdDelete } from 'react-icons/md';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { CgPlayStopO } from 'react-icons/cg';
import { useTheme } from 'styled-components';
import { useAudioRecorder } from 'hooks/useAudioRecorder';
import useTimer from 'hooks/useTimer';

type InputChatProps = {
    sendFile: (params: { text: string; attachment?: File }) => void;
    sendAudio: (params: { text: string; attachment?: Blob }) => void;
};

const InputChat: React.FC<InputChatProps> = ({ sendAudio, sendFile }) => {
    const [input, setInput] = useState('');
    const emojiRef = useRef<any>(null);
    const [showPicker, setShowPicker] = useState(false);
    const [documentAttached, setDocumentAttached] = useState<File>();
    const theme = useTheme();
    const { formatedTime, startAndStop, reset } = useTimer();
    const {
        permission,
        getMicrophonePermission,
        recordingStatus,
        audio,
        startRecording,
        stopRecording,
        discartRecording
    } = useAudioRecorder();

    const addEmoji = (e: any) => {
        const sym = e.unified.split('-');
        const codesArray: any[] = [];
        sym.forEach((el: string) => codesArray.push('0x' + el));
        const emoji = String.fromCodePoint(...codesArray);
        setInput(input + emoji);
    };

    const convertSize = useCallback(
        (value: number) => filesize(value, { base: 2, standard: 'jedec' }),
        []
    );

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setDocumentAttached(e.target.files[0]);
    };

    const handleSubmitFile = useCallback(() => {
        if (documentAttached) {
            sendFile({ text: input, attachment: documentAttached });
            setDocumentAttached(undefined);
            setInput('');
        }
    }, [documentAttached, input, sendFile]);

    const handleInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (
                (e.code === 'Enter' && !e.shiftKey) ||
                (e.code === 'NumpadEnter' && !e.shiftKey)
            ) {
                e.preventDefault();
                const audioFile =
                    audio && new File([audio], 'file', { type: 'audio/wav' });
                sendFile({
                    text: input
                    // attachment: documentAttached || audioFile || undefined
                });
                setInput('');
                reset();
                discartRecording();
                setDocumentAttached(undefined);
            }
        },
        [audio, sendFile, input, documentAttached, reset, discartRecording]
    );

    const handleSubmitAudio = useCallback(() => {
        if (audio) {
            const file = new File([audio], 'file', { type: 'audio/wav' });
            sendAudio({ text: input, attachment: file });
            reset();
            discartRecording();
            setInput('');
        }
    }, [audio, discartRecording, input, reset, sendAudio]);

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (emojiRef.current && !emojiRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        getMicrophonePermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <PickerWrapper showPicker={showPicker} ref={emojiRef}>
                <Picker
                    data={data}
                    onEmojiSelect={addEmoji}
                    previewPosition="none"
                    dynamicWidth={true}
                    width="100%"
                    i18n={i18n}
                />
            </PickerWrapper>

            {documentAttached && (
                <FileDecideWrapper>
                    <p>{`${documentAttached.name} - ${convertSize(
                        documentAttached.size
                    )}`}</p>

                    <div>
                        <button onClick={() => handleSubmitFile()}>
                            <AiOutlineCheck color={theme.colors.success.pure} />
                            Enviar arquivo
                        </button>

                        <button onClick={() => setDocumentAttached(undefined)}>
                            <AiOutlineClose color={theme.colors.error.pure} />
                            Cancelar
                        </button>
                    </div>
                </FileDecideWrapper>
            )}

            {['recording', 'inactive'].includes(recordingStatus) && (
                <RecordingWrapper>
                    <span>{formatedTime()}</span>
                    <SoundIcon stopped={recordingStatus === 'inactive'} />
                    {recordingStatus === 'recording' ? (
                        <CgPlayStopO
                            size={36}
                            color={theme.colors.error.pure}
                            onClick={() => {
                                stopRecording();
                                startAndStop();
                            }}
                        />
                    ) : (
                        <>
                            <MdDelete
                                size={36}
                                color={theme.colors.low.medium}
                                onClick={() => {
                                    discartRecording();
                                    reset();
                                }}
                            />
                            <AiOutlineSend
                                size={34}
                                color={theme.colors.success.pure}
                                onClick={() => handleSubmitAudio()}
                            />
                        </>
                    )}
                </RecordingWrapper>
            )}

            <TextAreaWrapper>
                <TextArea
                    value={input}
                    onKeyDown={handleInputKeyDown}
                    onChange={(e) => setInput(e.target.value)}
                    rows={5}
                    placeholder="Digite aqui sua resposta"
                    id="chatMessage"
                />
                <Actions>
                    <ButtonAttachment htmlFor="upload">
                        <MdAttachFile size={16} />
                        <input
                            type="file"
                            name="upload"
                            id="upload"
                            onChange={handleFileChange}
                            accept=".jpeg, .png, .jpg, application/pdf, application/vnd.rar, application/zip, application/mswordd, text/csv, text/plain"
                        />
                    </ButtonAttachment>
                    <ButtonEmoji
                        onClick={() => {
                            setShowPicker(!showPicker);
                        }}
                    >
                        {showPicker ? (
                            <AiOutlineClose size={16} />
                        ) : (
                            <FiSmile size={16} />
                        )}
                    </ButtonEmoji>
                    {permission && (
                        <ButtonRecordAudio
                            onClick={() => {
                                startRecording();
                                startAndStop();
                            }}
                        >
                            <HiOutlineMicrophone size={16} />
                        </ButtonRecordAudio>
                    )}
                </Actions>
            </TextAreaWrapper>
        </Container>
    );
};

export default InputChat;
