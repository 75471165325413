/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../api';
import {
    DashboardAttendances,
    DashboardAttendantsNps,
    DashboardAttendantsPerformance,
    DashboardCallPerChannel,
    DashboardCallsPerDay,
    DashboardConsumedWindows,
    DashboardGeneralNps,
    DashboardLeadTime,
    DashboardRequestParams,
    TeamMember
} from './types';
import { ErrorBoundary } from 'services/errorBoundary';

class DashboardService {
    async getAttendances(
        params: DashboardRequestParams
    ): Promise<DashboardAttendances | undefined> {
        try {
            const result = await api.get<DashboardAttendances>(
                '/dashboard/attendances',
                { params: params }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getLeadTime(
        params: DashboardRequestParams
    ): Promise<DashboardLeadTime | undefined> {
        try {
            const result = await api.get<DashboardLeadTime>(
                '/dashboard/leadTime',
                { params: params }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getConsumedWindows(
        params: DashboardRequestParams
    ): Promise<DashboardConsumedWindows | undefined> {
        try {
            const result = await api.get<DashboardConsumedWindows>(
                '/dashboard/consumedWindows',
                { params: params }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getCallsPerChannel(
        params: DashboardRequestParams
    ): Promise<DashboardCallPerChannel | undefined> {
        try {
            const result = await api.get<DashboardCallPerChannel>(
                '/dashboard/callPerChannel',
                { params: params }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getGeneralNps(
        params: DashboardRequestParams
    ): Promise<DashboardGeneralNps | undefined> {
        try {
            const result = await api.get<DashboardGeneralNps>(
                '/dashboard/generalNps',
                { params: params }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getAttendantsNps(
        params: DashboardRequestParams
    ): Promise<DashboardAttendantsNps | undefined> {
        try {
            const result = await api.get<DashboardAttendantsNps>(
                '/dashboard/attendantsNps',
                { params: params }
            );
            return result?.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getAttendantPerformance(
        params: DashboardRequestParams
    ): Promise<DashboardAttendantsPerformance[] | undefined> {
        try {
            const result = await api.get<DashboardAttendantsPerformance[]>(
                '/dashboard/attendantPerformance',
                { params: params }
            );
            return Array.isArray(result?.data) ? result?.data : [];
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getCallsPerDay(
        params: DashboardRequestParams
    ): Promise<DashboardCallsPerDay[] | undefined> {
        try {
            const result = await api.get<DashboardCallsPerDay[]>(
                '/dashboard/callPerDay',
                { params: params }
            );
            return Array.isArray(result?.data) ? result?.data : [];
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getCallsPerDayMember(
        params: DashboardRequestParams & { memberId: number }
    ): Promise<DashboardCallsPerDay[] | undefined> {
        try {
            const result = await api.get<DashboardCallsPerDay[]>(
                `/dashboard/callPerDay/${params.memberId}`,
                {
                    params: {
                        date: params.period
                    }
                }
            );
            return Array.isArray(result?.data) ? result?.data : [];
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
    async getTeamMember(params: {
        memberId: number;
        period: string;
    }): Promise<TeamMember | undefined> {
        try {
            const result = await api.get<TeamMember>(
                `/team/${params.memberId}`,
                {
                    params: {
                        period: params.period
                    }
                }
            );
            return result.data;
        } catch (error: any) {
            ErrorBoundary(error);
        }
    }
}

export default new DashboardService();
