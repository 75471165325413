import Loading from 'Components/Atoms/Loading';
import MessageList from 'Components/Organisms/Chat/MessageList';
import React from 'react';
import { useContactStore } from 'store/useContacts';

import { Container, EmptyChat } from './styles';

const ContactMessages: React.FC = () => {
    const { currentContactMessages, currentContactDetails, loadingMessages } =
        useContactStore();

    if (loadingMessages) {
        return <Loading />;
    }

    return (
        <Container>
            {!currentContactMessages?.length ? (
                <EmptyChat>
                    {currentContactDetails?.id
                        ? 'Sem mensagens'
                        : 'Selecione um contato'}
                </EmptyChat>
            ) : (
                <MessageList content={currentContactMessages} />
            )}
        </Container>
    );
};

export default ContactMessages;
