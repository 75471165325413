import styled from 'styled-components';

export const Container = styled.div`
    background: #f9f9f9;
    border-radius: 1rem;
    padding: 1.6rem;
    max-width: 300px;
    cursor: pointer;
`;

export const Header = styled.div`
    & > p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #292929;
    }
`;

export const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-bottom: 2rem;

    & > p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #292929;
    }
    & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #222222;
        padding: 2px 8px;
        border-radius: 6px;
        background: #d4f8d3;
    }
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        margin-right: 5px;
    }

    & > p {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 11.1985px;
        line-height: 14px;
        color: #666666;
        margin-right: 5px;
    }

    & > img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 3.2rem;
        object-fit: cover;
    }
`;
