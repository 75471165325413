export const content = {
    contactDetails: {
        title: 'Voltar para o dashboard',

        statistic: {
            nps: 'NPS médio:',
            convertion: 'Texa de conversão:'
        },

        groups: {
            title: 'Equipes que faz parte'
        },
        details: {
            title: 'Detalhes do contato',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            email: 'Email',
            phone: 'Telefone'
        }
    }
};
