import { toast } from 'react-toastify';
import SettingsService from 'services/settings';
import { create } from 'zustand';
import { Socket, io } from 'socket.io-client';
import TokenService from 'services/token';
import { ChatContactDetails, ListChatContact } from 'services/contacts/types';

const initialState = {
    socket: null,
    loadingContacts: false,
    clear: () => {
        console.log('clear');
    },
    connect: () => {
        console.log('Connecting');
    },
    chatList: {
        quantity: 0,
        contactResponse: []
    },
    chatMessages: [],
    getChatListOpen: (status: string[]) => {
        console.log('getChatList');
    },
    getChatListClose: (status: string[]) => {
        console.log('getChatList');
    }
};
export interface createWebSocketliceProps {
    socket: any;
    loadingContacts: boolean;
    connect: () => void;
    clear: () => void;
    chatList: ListChatContact;
    chatListClose: ListChatContact;
    chatMessages: [];
    getChatListOpen: (status: string[]) => void;
    getChatListClose: (status: string[]) => void;
    getChatMessages: (currentContactDetails: any) => void;
}

export const useWebSocketStore = create<createWebSocketliceProps>()(
    (set, get) => ({
        socket: null,
        loadingContacts: false,
        connect: async () => {
            const socket = io(
                `${process.env.REACT_APP_SOCKET_URL}?userId=${
                    TokenService.getAuthData()?.user.id
                }`
            );
            socket.connect();
            set({ socket });
        },
        clear: () => {
            const socket = get().socket as Socket;
            socket.removeListener('chatMessage');
            set({
                chatMessages: []
            });
        },
        chatList: {
            quantity: 0,
            contactsResponse: []
        },
        chatListClose: {
            quantity: 0,
            contactsResponse: []
        },
        chatMessages: [],
        getChatListOpen: async (status: string[]) => {
            try {
                const { socket } = get();
                if (socket) {
                    set({ loadingContacts: true });
                    socket.emit('chatList:Open', {
                        status,
                        companyId: 2,
                        userId: 2
                    });
                    console.log('use Websocket Stre emited chatList');
                    socket.on('chatList:Open', (data: any) => {
                        console.log('use Websocket Stre on chatList');
                        console.log(data);
                        set({
                            chatList: {
                                quantity: data.quantity,
                                contactsResponse: data?.contactsResponse
                            },
                            loadingContacts: false
                        });
                    });
                }
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getChatListClose: async (status: string[]) => {
            try {
                const { socket } = get();
                if (socket) {
                    set({ loadingContacts: true });
                    socket.emit('chatList:Close', {
                        status,
                        companyId: 2,
                        userId: 2
                    });
                    console.log('use Websocket Stre emited chatList');
                    socket.on('chatList:Close', (data: any) => {
                        console.log('use Websocket Stre on getChatListClose');
                        console.log(data);
                        set({
                            chatListClose: {
                                quantity: data.quantity,
                                contactsResponse: data?.contactsResponse
                            },
                            loadingContacts: false
                        });
                    });
                }
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        },
        getChatMessages: async (currentContactDetails: ChatContactDetails) => {
            try {
                const { socket } = get();
                if (socket) {
                    socket.on(
                        'chatMessage',
                        ({
                            message,
                            messages
                        }: {
                            messages?: any[];
                            message?: any;
                        }) => {
                            const authData = TokenService.getAuthData();
                            const user = {
                                id: authData?.user?.id as number,
                                avatar: authData?.user?.avatar as string,
                                firstName: authData?.user?.firstName as string,
                                lastName: authData?.user?.lastName as string
                            };

                            const contact = {
                                avatar: currentContactDetails?.avatar as string,
                                firstName:
                                    currentContactDetails?.firstName as string,
                                id: currentContactDetails?.id as number,
                                lastName:
                                    currentContactDetails?.lastName as string
                            };

                            if (messages) {
                                set({
                                    /** @ts-ignore */
                                    chatMessages: messages?.map(
                                        (messageItem: any) => {
                                            return {
                                                createdAt:
                                                    messageItem?.createdAt,
                                                id: messageItem?.id,
                                                sentBy: messageItem.sentBy,
                                                messageBody: {
                                                    text: messageItem
                                                        ?.messageBody?.text,
                                                    link: messageItem
                                                        ?.messageBody?.link,
                                                    fileKey:
                                                        messageItem?.messageBody
                                                            ?.fileKey,
                                                    contentType:
                                                        messageItem?.type
                                                },
                                                owner:
                                                    messageItem?.sentBy ===
                                                    'user',
                                                source:
                                                    messageItem?.sentBy ===
                                                    'user'
                                                        ? user
                                                        : contact
                                            };
                                        }
                                    )
                                });
                            }

                            if (message) {
                                const old = get().chatMessages;
                                set({
                                    chatMessages: [
                                        /** @ts-ignore */
                                        ...old,
                                        {
                                            createdAt:
                                                message?.createdAt ||
                                                new Date().toISOString(),
                                            id: message?.id,
                                            sentBy: message.sentBy,
                                            messageBody: {
                                                text: message?.messageBody
                                                    ?.text,
                                                link: message?.messageBody
                                                    ?.link,
                                                fileKey:
                                                    message?.messageBody
                                                        ?.fileKey,
                                                contentType: message?.type
                                            },
                                            owner: message?.sentBy === 'user',
                                            source:
                                                message?.sentBy === 'user'
                                                    ? user
                                                    : contact
                                        }
                                    ]
                                });
                            }
                            console.log('webSocket chatMessage', {
                                message,
                                messages
                            });
                        }
                    );
                    socket.emitWithAck('chatMessage', {
                        contactId: currentContactDetails?.id
                    });
                    socket
                        .off('newMessage')
                        .on(
                            'newMessage',
                            ({
                                message
                            }: {
                                messages?: any[];
                                message?: any;
                            }) => {
                                const authData = TokenService.getAuthData();
                                const user = {
                                    id: authData?.user?.id as number,
                                    avatar: authData?.user?.avatar as string,
                                    firstName: authData?.user
                                        ?.firstName as string,
                                    lastName: authData?.user?.lastName as string
                                };

                                const contact = {
                                    avatar: currentContactDetails?.avatar as string,
                                    firstName:
                                        currentContactDetails?.firstName as string,
                                    id: currentContactDetails?.id as number,
                                    lastName:
                                        currentContactDetails?.lastName as string
                                };

                                if (message) {
                                    const old = get().chatMessages;
                                    // if (!findMessage)
                                    set({
                                        chatMessages: [
                                            /** @ts-ignore */
                                            ...old,
                                            {
                                                createdAt:
                                                    message?.createdAt ||
                                                    new Date().toISOString(),
                                                id: message?.id,
                                                sentBy: message.sentBy,
                                                messageBody: {
                                                    text: message?.messageBody
                                                        ?.text,
                                                    link: message?.messageBody
                                                        ?.link,
                                                    fileKey:
                                                        message?.messageBody
                                                            ?.fileKey,
                                                    contentType: message?.type
                                                },
                                                owner:
                                                    message?.sentBy === 'user',
                                                source:
                                                    message?.sentBy === 'user'
                                                        ? user
                                                        : contact
                                            }
                                        ]
                                    });
                                }
                                get().getChatListOpen(['opened']);
                                console.log('new chatMessage', {
                                    message
                                });
                            }
                        );
                }
            } catch (error: any) {
                toast.error(error?.message || error, { toastId: 'settings' });
            }
        }
    })
);
