import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    overflow: scroll;
`;

export const Title = styled.h3`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding-bottom: 3rem;
    padding-top: 5rem;
    color: #292929;

    svg {
        margin-left: 1.2rem;
    }
`;
