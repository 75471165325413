import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;

    & > h3 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 100%;
        color: #292929;
        margin-bottom: 0.8rem;
    }

    & > p {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #666666;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    background: #f9f9f9;
    border: 1px solid #cccccc;
    border-radius: 1.6rem;
    width: 30rem;

    & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0b57f5;
        width: 4rem;
        height: 4rem;
        border-radius: 4rem;
        margin-bottom: 1.2rem;

        svg {
            color: #ffffff;
            font-size: 2.4rem;
        }
    }

    & > h4 {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1c1c1c;
        margin-bottom: 0.4rem;
    }

    & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #666666;
        margin-bottom: 1.6rem;
    }

    button {
        height: 3.6rem;
    }
`;

export const CardList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 4rem;
`;
