import styled from 'styled-components';

export const BoardWrapper = styled.div`
    width: 100%;
    height: 100%;

    .react-flow__edge-path {
        stroke-width: 3;
        stroke: #0b57f5;
    }

    .react-flow__edge-path:hover {
        stroke-width: 4;
        stroke: #0b57f5;
    }
`;
