import Goback from 'Components/Atoms/Goback';
import React, { useState } from 'react';
import * as z from 'zod';
import { content } from '../content';
import { AnySocialMedia, SocialMediaTitle, Title } from '../styles';
import { useRegisterStore } from 'store/useRegisterStore';
import { useForm } from 'react-hook-form';
import { RegisterRequest } from 'services/auth/types';
import { zodResolver } from '@hookform/resolvers/zod';
import SocialCheck from '../components/SocialCheck';
import Button from 'Components/Atoms/Button';
import { useNavigate } from 'react-router-dom';

const Step3: React.FC = () => {
    const { signUpAction, previousStep, data } = useRegisterStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { step3 } = content;
    const schema = z.object({
        channels: z.string().array().optional()
    });

    const { register, handleSubmit, watch } = useForm<RegisterRequest>({
        resolver: zodResolver(schema),
        criteriaMode: 'all',
        defaultValues: data
    });

    const onSubmit = async (formData: RegisterRequest) => {
        setLoading(true);
        await signUpAction({ ...formData }, () => {
            navigate('/login', { replace: true, preventScrollReset: true });
        });
        setLoading(false);
    };

    return (
        <div>
            <Goback handleClick={previousStep} size={14} />
            <Title>{step3.title}</Title>

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <SocialMediaTitle>{step3.subtitle}</SocialMediaTitle>
                <SocialCheck register={register} name="channels" />

                <AnySocialMedia>
                    <input
                        type="checkbox"
                        name="channels"
                        id="channels"
                        value={''}
                        checked={!watch('channels')?.length}
                        disabled
                    />
                    <label htmlFor="channels">{step3.anyOption}</label>
                </AnySocialMedia>

                <Button
                    type="submit"
                    marginVertical="34px"
                    size="SMALL"
                    rounded
                    loading={loading}
                    disabled={loading}
                >
                    {step3.submit}
                </Button>
            </form>
        </div>
    );
};

export default Step3;
