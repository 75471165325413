import React, { useCallback, useEffect, useState } from 'react';

import { Container, Card, ChannelsGroup } from './styles';
import { useDashboard } from 'store';
import { DashboardGeneralNps } from 'services/dashboard/types';

type GeneralNpsProps = {
    period: string;
};

const GeneralNps: React.FC<GeneralNpsProps> = ({ period }) => {
    const { getGeneralNps } = useDashboard();
    const [data, setData] = useState<DashboardGeneralNps>({
        generalNPS: 0,
        max: 5
    });

    const handleAttendanceData = useCallback(async () => {
        const result = await getGeneralNps({ period });

        setData({
            generalNPS: result?.generalNPS || 0,
            max: result?.max || 5
        });
    }, [getGeneralNps, period]);

    useEffect(() => {
        if (period) {
            handleAttendanceData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Container>
            <h3>NPS geral</h3>
            <ChannelsGroup>
                <Card>
                    <p>
                        {data.generalNPS}
                        <small> / {data.max}</small>
                    </p>
                </Card>
            </ChannelsGroup>
        </Container>
    );
};

export default GeneralNps;
