import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Text = styled.p`
    font-style: normal;
    font-family: ${({ theme }) => theme.fontFamily.inter};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: ${({ theme }) => theme.lineHeight.lg};
    color: ${({ theme }) => theme.colors.low.dark};
`;

export const Link = styled(RouterLink)`
    font-style: normal;
    font-family: ${({ theme }) => theme.fontFamily.inter};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: ${({ theme }) => theme.lineHeight.lg};
    color: ${({ theme }) => theme.colors.brand.pure};
`;
