import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const HeaderFixed = styled.div`
    padding: 20px 0;
    position: sticky;
    background-color: #ffffff;
    top: 0;
    left: 0;
    z-index: 9;
`;

export const FooterFixed = styled.div`
    padding: 20px 0;
    position: sticky;
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    z-index: 9;
`;

export const EmptyChat = styled.div`
    width: 100%;
    padding: 20px 0%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: 17px;
    color: ${({ theme }) => theme.colors.low.pure};
    flex: 1;
`;
