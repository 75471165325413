import { zodResolver } from '@hookform/resolvers/zod';
import Button from 'Components/Atoms/Button';
import Select from 'Components/Atoms/Select';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { useContactStore } from 'store';
import { z } from 'zod';
import { Title } from './styles';
// import { Container } from './styles';

type ModalGroupsProps = {
    isOpen: boolean;
    handleClose: () => void;
};

const ModalGroups: React.FC<ModalGroupsProps> = ({ handleClose, isOpen }) => {
    const { contactId } = useParams();
    const schema = z.object({
        group: z.string().min(1, 'Campo obrigatório')
    });

    const { addContactGroup, getGroups, groups, currentContactDetails } =
        useContactStore();
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors }
    } = useForm<{
        group: string;
    }>({
        resolver: zodResolver(schema),
        mode: 'onChange'
    });

    const onSubmit = handleSubmit(async (data) => {
        await addContactGroup({
            contactId: Number(contactId),
            groupId: Number(data.group)
        });
        handleClose();
    });

    useEffect(() => {
        getGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            shouldCloseOnEsc
            onRequestClose={handleClose}
            isOpen={isOpen}
            style={{
                content: { maxWidth: '30rem', height: '22rem', margin: 'auto' },
                overlay: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0,0,0,0.6)',
                    zIndex: 1000
                }
            }}
        >
            <form onSubmit={onSubmit}>
                <Title>Adicionar Grupo</Title>
                <Select
                    name="group"
                    placeholder="Selecione um grupo"
                    control={control}
                    value={getValues('group')}
                    onChange={(e) => setValue('group', e.currentTarget.value)}
                    options={groups
                        .filter((group) => {
                            const currentGroups =
                                currentContactDetails?.groups.map(
                                    (item) => item.id
                                );
                            return !currentGroups?.includes(group.id);
                        })
                        .map((group) => ({
                            label: group.name,
                            value: group.id
                        }))}
                    marginVertical="1rem"
                    error={errors.group?.message}
                />

                <Button
                    rounded
                    size="SMALL"
                    height="48px"
                    marginVertical="2rem"
                    disabled={!watch('group')}
                    type="submit"
                >
                    Adicionar
                </Button>
            </form>
        </Modal>
    );
};

export default ModalGroups;
