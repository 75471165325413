import { AuthResponse } from './auth/types';

class TokenService {
    setAuthData(data: AuthResponse) {
        localStorage.setItem('@deetz:auth', JSON.stringify(data));
    }

    getAuthData(): AuthResponse | undefined {
        const dataString = localStorage.getItem('@deetz:auth');
        const data = dataString && JSON.parse(dataString);
        return data;
    }

    removeAuthData() {
        localStorage.removeItem('@deetz:auth');
    }
}

export default new TokenService();
