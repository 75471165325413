/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutomation } from '../context';
import {
    DeleteButton,
    NodeContainer,
    handleStyleBottom,
    handleStyleTop
} from './styles';
import { BiTrash } from 'react-icons/bi';
import { Handle, Position } from 'reactflow';
import Input from 'Components/Atoms/Input';

export const ButtonText = memo(({ id, data }: any) => {
    const { register, setValue } = useFormContext();
    const { setNodes, setEdges } = useAutomation();

    const handleRemoveNode = () => {
        setNodes((old) => old.filter((item) => item.id !== id));
        setEdges((old) =>
            old.filter((item) => item.target !== id && item.source !== id)
        );
        setValue(`${id}.message`, undefined);
    };

    return (
        <NodeContainer>
            <DeleteButton onClick={handleRemoveNode}>
                <BiTrash size={24} />
            </DeleteButton>
            <Handle
                type="target"
                position={Position.Top}
                style={handleStyleTop}
                isConnectable={true}
                id={`${id}-1`}
            />

            <Input
                id={`${id}.message`}
                name={`${id}.message`}
                defaultValue={data.message}
                label={`Botão ${String(id).split('.')[1]}`}
                onChange={(e) => (data.message = e.currentTarget.value)}
                register={register}
            />

            <Handle
                type="source"
                position={Position.Bottom}
                style={handleStyleBottom}
                isConnectable={true}
                id={`${id}-1`}
            />
        </NodeContainer>
    );
});
