//@ts-nocheck
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
} from 'chart.js';
import { Container, Header, Title as TitleStyle } from './styles';
import { DashboardCallsPerDay } from 'services/dashboard/types';
import { BsInfoSquare } from 'react-icons/bs';
import Input from 'Components/Atoms/Input';
import dayjs from 'dayjs';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);

type CallsPerDayProps = {
    setPeriod: React.Dispatch<React.SetStateAction<string>>;
    data: DashboardCallsPerDay[];
};

const CallsPerDay: React.FC<CallsPerDayProps> = ({ setPeriod, data }) => {
    return (
        <Container>
            <Header>
                <TitleStyle>
                    Atendimentos por dia <BsInfoSquare />
                </TitleStyle>

                <Input
                    name="period"
                    id="period"
                    type="month"
                    width="20rem"
                    defaultValue={dayjs().format('YYYY-MM')}
                    max={dayjs().format('YYYY-MM')}
                    onChange={(e) => {
                        const [year, month] = e.currentTarget.value.split('-');
                        setPeriod(`${month}/${year}`);
                    }}
                />
            </Header>
            <Line
                options={{
                    responsive: true,
                    tension: 0.6,
                    scales: {
                        x: {
                            border: {
                                display: false
                            },
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            border: {
                                display: false
                            },
                            grid: {
                                display: false
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            footerColor: '#000',
                            boxPadding: 10,
                            callbacks: {
                                title: () => '',
                                label: () => '',
                                footer: (tooltipItems) =>
                                    `${tooltipItems[0].formattedValue}\nAtendimentos`
                            }
                        }
                    }
                }}
                data={{
                    labels: data.map((item) =>
                        item.day.toString().padStart(2, '0')
                    ),
                    datasets: [
                        {
                            data: data.map((item) => item.calls),
                            borderColor: 'rgba(69, 126, 245, 0.87)',
                            backgroundColor: 'rgba(69, 126, 245, 1)',
                            fill: true,
                            type: 'line'
                        }
                    ]
                }}
            />
        </Container>
    );
};

export default CallsPerDay;
