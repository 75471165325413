import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardList, Container, Header } from './styles';
import { iconsCleaned } from 'Constant';
import Button from 'Components/Atoms/Button';
import { FiTrash } from 'react-icons/fi';
import Divider from 'Components/Atoms/Divider';
import { ContactChannels } from 'services/contacts/types';
import { useContactStore } from 'store';

const defaultChannels = [
    'whatsapp',
    'instagram',
    'messenger',
    'sms',
    'telegram',
    'facebook'
];

const Channels: React.FC = () => {
    const { getContactChannelsDetails } = useContactStore();
    const [channels, setChannels] = useState<ContactChannels[]>();

    const ButtonRemove = () => (
        <Button
            outline
            rounded
            disabled
            size="SMALL"
            color="#EA1F1F"
            width="13rem"
        >
            <FiTrash /> Remover
        </Button>
    );

    const ButtonConnect = () => (
        <Button
            outline
            rounded
            disabled
            size="SMALL"
            color="#0B57F5"
            width="13rem"
        >
            Conectar
        </Button>
    );

    const handleGetChannels = useCallback(async () => {
        const channelsResult = [] as ContactChannels[];

        const requests = defaultChannels.map((item) =>
            getContactChannelsDetails(item)
        );

        await Promise.all(requests).then((result) => {
            result.forEach((item) => {
                if (item) {
                    channelsResult.push(item);
                }
            });
        });

        setChannels(channelsResult);
    }, [getContactChannelsDetails]);

    useEffect(() => {
        handleGetChannels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Header>
                <h3>Canais de comunicação</h3>
                <p>Gerencie os canais que pode comunicar com seu cliente</p>
                <Divider />
            </Header>

            <CardList>
                {channels?.map((item) => (
                    <Card key={item.id}>
                        <span>
                            {
                                iconsCleaned[
                                    item.type as keyof typeof iconsCleaned
                                ]
                            }
                        </span>
                        <h4>{item.type}</h4>

                        {item.isActived ? <ButtonRemove /> : <ButtonConnect />}
                    </Card>
                ))}
            </CardList>
        </Container>
    );
};

export default Channels;
