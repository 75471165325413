import React from 'react';

import { Container, Card, Title, CardGroup } from './styles';

import { BsInfoSquare } from 'react-icons/bs';
import { TeamsDetailsResponse } from 'services/settings/types';

type MessagesProps = {
    currentMember: TeamsDetailsResponse | undefined;
};

const Messages: React.FC<MessagesProps> = ({ currentMember }) => {
    return (
        <Container>
            <Title>
                Mensagens <BsInfoSquare />
            </Title>
            <CardGroup>
                <Card color={'#E3F5FF'}>
                    <p>Respondidas</p>
                    <span>{currentMember?.totals.messages.answered}</span>
                </Card>
                <Card color={'#E5ECF6'}>
                    <p>Criadas</p>
                    <span>{currentMember?.totals.messages.created}</span>
                </Card>
            </CardGroup>
        </Container>
    );
};

export default Messages;
