import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #f9f9f9;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 2rem 2.4rem;
    width: fit-content;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 22rem;

    h3 {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 100%;
        color: #292929;
        padding-bottom: 1rem;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #666666;
        margin-bottom: 1.2rem;
    }

    input {
        display: none;
    }
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 100%;
    border: 1px solid #0b57f5;
    border-radius: 4px;
    width: 17rem;

    height: 3.8rem;
    color: #0b57f5;

    cursor: pointer;

    svg {
        margin-right: 0.9rem;
    }
`;
