import styled from 'styled-components';

export const Container = styled.ul`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    background: #f9f9f9;
    border-radius: 12px;
    padding: 2.8rem;
    gap: 3.2rem;
`;

export const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    & > input {
        display: none;
    }

    & > label {
        cursor: pointer;
    }

    & > input:checked + label > svg {
        filter: drop-shadow(0px 0px 10px rgba(11, 87, 245, 1));
    }
`;
