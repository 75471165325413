import React, { useEffect, useState } from 'react';
import { Tag, WithContext as ReactTag } from 'react-tag-input';

import {
    AvatarSection,
    ChannelsSection,
    Container,
    DetailInfo,
    DetailsSection,
    GroupsSection,
    ProductsSection,
    Title
} from './styles';
import { useContactStore } from 'store/useContacts';
import Divider from 'Components/Atoms/Divider';
import SocialTip from 'Components/Molecules/SocialTip';
import confirmDialog from 'Components/Atoms/ConfirmDialog';
import { AiOutlinePlus } from 'react-icons/ai';
import { content } from '../content';
import Loading from 'Components/Atoms/Loading';
import Avatar from 'Components/Atoms/Avatar';
import ModalGroups from 'Pages/Contacts/Messages/ModalGroups';

const ContactDetails: React.FC = () => {
    const { currentContactDetails, loadingDetails } = useContactStore();
    const [tags, setTags] = useState<Tag[]>([]);
    const [modalGroups, setModalGroups] = useState(false);

    const handleDeleteProduct = (tagIndex: number) => {
        setTags(tags?.filter((tag, index) => index !== tagIndex));
    };

    const handleAdditionProduct = (tag: Tag) => {
        setTags([...(tags || []), tag]);
    };

    const handleConfirmDelete = async (tagIndex: number) => {
        confirmDialog({
            title: 'Remover produto',
            subtitle: 'Deseja realmente remover o produto da lista?',
            acceptLabel: 'Sim',
            denyLabel: 'Não',
            acceptFunction: () => handleDeleteProduct(tagIndex)
        });
    };

    useEffect(() => {
        if (currentContactDetails?.products) {
            const newTags: Tag[] = currentContactDetails.products.map(
                (item) => ({
                    id: item.id.toString(),
                    text: item.name
                })
            );
            setTags(newTags);
        }
    }, [currentContactDetails]);

    if (!currentContactDetails?.id && !loadingDetails) {
        return (
            <>
                <Title>{content.contactDetails.title}</Title>
                <Title style={{ color: '#A3A3A3', fontSize: '14px' }}>
                    {content.contactDetails.empty}
                </Title>
            </>
        );
    }

    if (loadingDetails) {
        return <Loading />;
    }

    return (
        <Container>
            <Title>{content.contactDetails.title}</Title>
            <AvatarSection>
                <Avatar
                    src={currentContactDetails?.avatar}
                    size="6.4rem"
                    style={{ marginRight: '1rem' }}
                    alt={currentContactDetails?.firstName}
                />

                <p>{`${currentContactDetails?.firstName} ${currentContactDetails?.lastName}`}</p>
            </AvatarSection>

            {/* <ProductsSection>
                <Title>{content.contactDetails.products.title}</Title>
                <ReactTag
                    tags={tags}
                    handleDelete={handleConfirmDelete}
                    handleAddition={handleAdditionProduct}
                />

                <p>
                    {content.contactDetails.products.priceLegend + ' '}
                    <span>{currentContactDetails?.amountProduct}</span>
                </p>
            </ProductsSection> */}

            <Divider />

            <ChannelsSection>
                <Title>{content.contactDetails.channels.title}</Title>

                {currentContactDetails?.contactChannels.map((channel) => (
                    <SocialTip socialMedia={channel.type} key={channel.id} />
                ))}
            </ChannelsSection>

            <GroupsSection>
                <Title>{content.contactDetails.groups.title}</Title>
                <ul>
                    <li>
                        {currentContactDetails?.groups
                            .map((item) => item.name)
                            .toString()}
                    </li>
                </ul>

                <button onClick={() => setModalGroups(true)}>
                    <AiOutlinePlus size={16} />
                    {content.contactDetails.groups.action.label}
                </button>
            </GroupsSection>

            <Divider />

            <DetailsSection>
                <Title>{content.contactDetails.details?.title}</Title>

                <DetailInfo>
                    <strong>
                        {content.contactDetails?.details?.firstName}
                    </strong>
                    <span>{currentContactDetails?.firstName}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.lastName}</strong>
                    <span>{currentContactDetails?.lastName}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.email}</strong>
                    <span>{currentContactDetails?.email}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details.phone}</strong>
                    <span>{currentContactDetails?.phoneNumber}</span>
                </DetailInfo>
                <DetailInfo>
                    <strong>{content.contactDetails.details?.owner}</strong>
                    <span>
                        {!currentContactDetails?.owner && 'Sistema'}
                        {currentContactDetails?.owner?.firstName}{' '}
                        {currentContactDetails?.owner?.lastName}
                    </span>
                </DetailInfo>
            </DetailsSection>

            <ModalGroups
                handleClose={() => setModalGroups(false)}
                isOpen={modalGroups}
            />
        </Container>
    );
};

export default ContactDetails;
