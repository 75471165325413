import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.64);
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 90vh;
    max-width: 568px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    background: #ffffff;
    padding: 5rem 4rem;
    border-radius: 12px;

    form {
        width: 100%;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    color: #3c4257;
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.8rem;
    margin-top: 2.4rem;
    width: 100%;
`;
