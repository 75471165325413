import React, { ReactNode } from 'react';

import { Container } from './styles';

type BoxProps = {
    children: ReactNode;
    padding?: string;
    transparent?: boolean;
    style?: React.CSSProperties;
};
const Box: React.FC<BoxProps> = ({ children, padding, transparent, style }) => {
    return (
        <Container padding={padding} transparent={transparent} style={style}>
            {children}
        </Container>
    );
};

export default Box;
