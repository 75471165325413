/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { RegisterRequest } from 'services/auth/types';
import MobileTitle from 'Components/Atoms/MobileTitle';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import GoogleButton from 'Components/Atoms/GoogleButton';
import TextLink from 'Components/Atoms/TextLink';
import SectionTitle from '../components/SectionTitle';
import ValidationItem from '../../../Components/Molecules/ValidationItem';
import Disclaimer from '../components/Disclaimer';
import Divider from '../components/Divider';
import { content as registerContent } from '../content';
import { useRegisterStore } from 'store/useRegisterStore';
import { useGoogleLogin } from '@react-oauth/google';

const Step1: React.FC = () => {
    const { nextStep, data } = useRegisterStore();
    const content = registerContent.step1;
    const schema = z.object({
        email: z.string().email('Email inválido!').min(1, 'Campo obrigatório'),
        password: z
            .string()
            .min(7, 'PASSWORD_SIZE_CHECK')
            .regex(/\d/gi, 'PASSWORD_NUMERIC_CHECK')
            .regex(/(?=.*[a-z])(?=.*[A-Z])/g, 'PASSWORD_CAMELCASE_CHECK')
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid }
    } = useForm<RegisterRequest>({
        resolver: zodResolver(schema),
        criteriaMode: 'all'
    });

    const loginWithGoogle = useGoogleLogin({
        onSuccess: ({ access_token }) => {
            nextStep({ token: access_token });
        }
    });

    const onSubmit = async (formData: RegisterRequest) => {
        nextStep(formData);
    };

    const verifyValidity = (rule: any) => {
        return !!watch('password')?.match(rule);
    };

    return (
        <>
            <MobileTitle />

            <TextLink
                text={content.register.text}
                linkText={content.register.link.text}
                linkUrl={content.register.link.url}
            />

            <SectionTitle title={content.title} rules={content.rules} />

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <Input
                    id="email"
                    name="email"
                    marginVertical="24px"
                    width="100%"
                    defaultValue={data.email}
                    error={errors.email?.message}
                    label={content.fields.email.label}
                    placeholder={content.fields.email.placeholder}
                    register={register('email')}
                />

                <Input
                    name="password"
                    id="password"
                    width="100%"
                    defaultValue={data.password}
                    label={content.fields.password.label}
                    placeholder={content.fields.password.placeholder}
                    showPassword
                    register={register('password')}
                />
                <ul>
                    <ValidationItem
                        checked={verifyValidity(/(?=.*[a-z])(?=.*[A-Z])/g)}
                        label={
                            content.fields.password.validations
                                .PASSWORD_CAMELCASE_CHECK
                        }
                    />
                    <ValidationItem
                        checked={verifyValidity(/\d/gi)}
                        label={
                            content.fields.password.validations
                                .PASSWORD_NUMERIC_CHECK
                        }
                    />
                    <ValidationItem
                        checked={verifyValidity(/^.{7,}$/)}
                        label={
                            content.fields.password.validations
                                .PASSWORD_SIZE_CHECK
                        }
                    />
                </ul>

                <Button
                    type="submit"
                    marginVertical="34px"
                    size="SMALL"
                    rounded
                    disabled={!isValid}
                >
                    {content.submit}
                </Button>
            </form>

            <Divider text={content.social.title} />

            <GoogleButton
                text={content.social.button}
                onClick={loginWithGoogle}
            />

            <Disclaimer text={content.disclaimer} />
        </>
    );
};

export default Step1;
