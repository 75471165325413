export const content = {
    contentTable: {
        header: {
            title: 'Todos os contatos',
            buttonColumns: 'Ver como colunas'
        },
        actions: {
            searchPlaceholder: 'Busque por nome, telefone e email',
            searchButton: 'Buscar',
            ownerPlaceholder: 'Dono do contato',
            groupPlaceholder: 'Grupo',
            newContactButton: 'Novo contato'
        }
    },
    contentBoard: {
        header: {
            title: 'Contatos',
            buttonRows: 'Ver como lista'
        },
        actions: {
            searchPlaceholder: 'Busque por nome ou email',
            searchLabel: 'Cliente',
            channelLabel: 'Canal de atendimento',
            valueLabel: 'Valor de negociação',
            searchButton: 'Buscar'
        },
        columns: {
            opened: 'Em aberto ',
            in_attendance: 'Em atendimento ',
            under_review: 'Em análise ',
            blocked: 'Bloqueado/Impedimento ',
            done: 'Concluído '
        }
    }
};

export const modalContent = {
    newTitle: 'Novo contato',
    editTitle: 'Edição de contato',
    platformTitle: 'Plataforma',
    actions: {
        cancel: 'Cancelar',
        save: 'Salvar'
    },
    fields: {
        firstName: {
            label: 'Primeiro nome',
            placeholder: 'Ex: Paulo'
        },
        lastName: {
            label: 'Sobrenome',
            placeholder: 'Ex: Rodrigues de Oliveira'
        },
        email: {
            label: 'Email',
            placeholder: 'Ex: Rodrigues de Oliveira'
        },
        phone: {
            label: 'Telefone',
            placeholder: '(00) 00000-0000'
        },
        group: {
            label: 'Grupo de contato'
        },
        whatsapp: {
            label: 'WhatsApp',
            placeholder: '(00) 00000-0000'
        },
        sms: {
            label: 'SMS',
            placeholder: '(00) 00000-0000'
        },
        instagram: {
            label: 'Instagram'
        },
        facebook: {
            label: 'Facebook'
        }
    }
};
