import styled from 'styled-components';

export const Container = styled.div<{ padding?: string }>`
    width: 100%;
    height: calc(100vh - 10rem);
    background-color: #ffffff;
    border-radius: 8px;
    padding: ${({ padding }) => (padding ? padding : '26px 20px')};
    overflow-y: auto;
    position: relative;

    @media (max-width: 1024px) {
        height: auto;
        max-height: 300px;
        overflow: scroll;
        margin: 10px 0;

        &:nth-child(2) {
            max-height: 100%;
            height: 80vh;
        }
    }
`;
