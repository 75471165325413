import styled from 'styled-components';
import { customMediaQuery } from 'theme/mediaQuery';

export const Container = styled.ul<{ opened: boolean }>`
    position: fixed;
    display: ${({ opened }) => (opened ? 'flex' : 'none')};
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 999;
    gap: 6rem;
    padding: 5rem 28px;
    top: 6rem;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
`;

export const MenuMobileItem = styled.li<{ active?: boolean }>`
    & > a {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-decoration: none;
        color: #292929;
    }

    & > a:focus,
    & > a:active,
    & > a:hover,
    a.active {
        color: #0b57f5;
    }

    &:last-child {
        margin-top: 3rem;
    }
`;

export const Header = styled.nav`
    width: 100%;
    height: 6rem;
    padding: 20px 28px;
    display: flex;
    background: #ffffff;
    align-items: center;
    justify-content: space-between;

    ${customMediaQuery.greaterThan('md')`
        display: none;
    `}
`;

export const ButtonLogout = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #292929;
    width: 100%;

    svg {
        margin-left: 10px;
    }

    &:hover {
        color: #0b57f5;

        svg {
            color: #0b57f5;
        }
    }
`;
